import React from "react";
import Plus from "./Plus";
import Minus from "./Minus";
import { Shift } from "../../Shifts/types";
import { useAddSelectedShift, useDecrementSelectedShift, useGetShiftCountByShiftId } from "../../Shifts/shiftHooks";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getSignedInUser } from "../../Person/personSelector";
import { useOverlappingShiftsCheck } from "./collectionSiteHooks";
import { getLocationById } from "domains/Locations/locationSelector";
import { AppStore } from "rootReducer";
import { useShowModal } from "domains/Modal/modalHooks";

interface Props {
  shift: Shift | undefined;
  handleShiftSelection: () => void;
  shiftsRemaining: number;
  shiftsAvailable: number;
}

const ShiftCounter = ({ shift, handleShiftSelection, shiftsRemaining, shiftsAvailable }: Props) => {
  const addSelectedShift = useAddSelectedShift();

  const shiftCount = useGetShiftCountByShiftId(shift?.shiftId);

  const showModal = useShowModal();

  const location = shift?.locationId
    ? useSelector((state: AppStore) => getLocationById(state, shift?.locationId))
    : null;

  const decrementSelectedShift = useDecrementSelectedShift();

  const { checkOverlapping } = useOverlappingShiftsCheck();

  const user = useSelector(getSignedInUser);

  const handleAdd = () => {
    if (!shift) {
      return;
    }
    const isOverlapping = checkOverlapping(shift);
    if (location?.cancelOnly) {
      showModal({ type: "cancel-only" });
      return;
    }
    if (isOverlapping) {
      alert("Please select shifts that do not have overlapping time frames");
      return;
    }
    if (shiftCount <= shiftsRemaining && !isDisabled) {
      addSelectedShift(shift);
      handleShiftSelection();
    }
  };

  const handleDecrement = () => {
    if (user) {
      alert("Please use the Cancel button below instead");
      return;
    }
    if (!isDisabled) {
      decrementSelectedShift(shift);
    }
  };

  const isDisabled = !shift || shiftsAvailable === 0;

  return (
    <div
      className={classNames("w-20 border rounded p-2 flex justify-between items-center", {
        "text-cloudDark": isDisabled,
      })}
    >
      <button
        className={classNames("focus:outline-none", { "cursor-default": isDisabled })}
        onClick={() => handleDecrement()}
      >
        <Minus disabled={isDisabled} />
      </button>
      <div className="px-2">{shiftCount}</div>
      <button
        className={classNames("focus:outline-none", { "cursor-default": isDisabled })}
        onClick={() => handleAdd()}
        disabled={isDisabled}
      >
        <Plus disabled={isDisabled} />
      </button>
    </div>
  );
};

export default ShiftCounter;
