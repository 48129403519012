import React from "react";
import { uniq } from "lodash";
import StepThree from "../Registration/CollectionSites/StepThree";
import { navigate } from "@reach/router";
import StandardUserThankyou from "./StandardUserThankyou";
import Button from "../Shared/Button";
import { useGetIsACRequired } from "domains/Locations/locationHooks";
import {
  cancelledRegions,
  useCheckIfShiftsAreBlacklisted,
} from "domains/Registration/CollectionSites/collectionSiteHooks";
import CovidCancellationModal from "domains/Registration/CollectionSites/CovidCancellationModal";
import { useGetOrderedSelectedShifts } from "domains/Shifts/shiftHooks";
import { useSelector } from "react-redux";
import { campaignEnded } from "domains/CampaignConfig/campaignConfigSelectors";
import AreaCoordinatorsNeeded from "./AreaCoordinatorsNeeded";

const StandardUserMyShiftsPage = () => {
  const { selectedLocations } = useGetIsACRequired();
  const campaignHasEnded = useSelector(campaignEnded);

  const orderedSelectedShifts = useGetOrderedSelectedShifts();

  const [covidModalOpen, setCovidModalOpen] = React.useState(false);

  const checkIfShiftsAreBlacklisted = useCheckIfShiftsAreBlacklisted(orderedSelectedShifts);

  const shiftsAreBlackListed = checkIfShiftsAreBlacklisted();

  React.useEffect(() => {
    if (shiftsAreBlackListed) {
      setCovidModalOpen(true);
    }
  }, [shiftsAreBlackListed]);

  const areasWhereACIsNeeded = uniq(
    selectedLocations
      ?.filter((location) => location?.acsRequired)
      .filter(
        (location) => !cancelledRegions.includes(location?.region?.toLowerCase() || location?.subRegion?.toLowerCase()),
      )
      .map((location) => location.area),
  );

  return (
    <div>
      <CovidCancellationModal open={covidModalOpen} closeModal={() => setCovidModalOpen(false)} />
      <StandardUserThankyou />
      <div className="pt-8 px-8 sm:px-0">
        <StepThree confirmation={true} />
        <Button text="ADD MORE SHIFTS" disabled={campaignHasEnded} onClick={() => navigate("/find-shifts")} />
        <AreaCoordinatorsNeeded areasWhereACIsNeeded={areasWhereACIsNeeded} />
      </div>
    </div>
  );
};

export default StandardUserMyShiftsPage;
