import React from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import RegistrationDescription from "../Common/RegistrationDescription";
import PersonalDetailsPage from "./PersonalDetailsPage";
import { useCheckEmail, useCreateUser } from "../registrationHooks";
import { CreateUserApi } from "../../API/type";
import { useSelector } from "react-redux";
import { getSelectedShifts } from "../../Shifts/shiftSelector";
import { registrationCompleted } from "../../Locations/locationSlice";
import { useAppDispatch } from "../../../hooks";
import ShiftNavigator from "../../Viewer/ShiftNavigator";
import { sumCounts } from "../CollectionSites/utils";
import { Shift } from "../../Shifts/types";
import { sha256 } from "js-sha256";
import { useIsACRegisterPage } from "domains/Account/accountHooks";
import CheckEmail from "./CheckEmail";
import SignInPage from "domains/Account/SignInPage";
import { useGetSelectedShifts } from "domains/Shifts/shiftHooks";

const shapeSelectedShifts = (selectedShifts: { [shiftId: string]: Shift }) => {
  return Object.keys(selectedShifts).reduce<Shift[]>((accum, shiftId) => {
    const shift = selectedShifts[shiftId];
    return [...accum, { ...shift, count: sumCounts([shift]) }];
  }, []);
};

const Register: React.FC<RouteComponentProps> = ({ path }) => {
  const isACRegisterPage = useIsACRegisterPage();

  const [email, setEmail] = React.useState("");

  const { checkEmail, loading: loadingEmailCheck, emailExists, checkedEmail, error } = useCheckEmail();

  const shifts = useGetSelectedShifts();
  const location = useLocation();

  const registrationPage = location?.pathname === "/register";

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useAppDispatch();
  const selectedShifts = useSelector(getSelectedShifts);
  const { createUser, loading, error: pageError } = useCreateUser();

  const handleRegister = (values: CreateUserApi) => {
    const dataLayer = window?.dataLayer;

    const { email, hearMoreFromBCFNZ, interestedInAreaCoordinator } = values;

    if (dataLayer && !isACRegisterPage) {
      dataLayer.push({
        event: "pink_ribbon_volunteer_sign_up",
        area_coordinator_interest: interestedInAreaCoordinator ? "yes" : "no", // or 'yes'
        nzbcf_newsletter_sign_up: hearMoreFromBCFNZ ? "yes" : "no",
        user_id: sha256(email), // SHA-256 hashed email address (used for cross-device tracking for logged-in users)
      });
    }
    dispatch(registrationCompleted({ registrationCompleted: true }));
    createUser(
      { ...values, ...(!isACRegisterPage && { shifts: shapeSelectedShifts(selectedShifts) }) },
      isACRegisterPage,
    );
  };

  const headerText = registrationPage ? "ENTER YOUR DETAILS" : "SET UP YOUR ACCOUNT";

  const registrationText = registrationPage ? (
    <div>Thank you! We need a bit more information from you to complete your registration.</div>
  ) : (
    <div>
      Thanks again for signing up to be an Area Coordinator this year!
      <br />
      <br />
      Please enter your details to set up an account. If you get stuck, please get in touch with our Pink Ribbon Street
      Appeal Coordinator, Joanne Martin on
      <a className="mx-1" href="mailto:joannem@bcf.org.nz">
        joannem@bcf.org.nz
      </a>
      or <a href="tel:093041220">09 3041 220</a>.
    </div>
  );
  return (
    <ShiftNavigator title={headerText} hideNav={isACRegisterPage}>
      <>
        {!checkedEmail && !isACRegisterPage ? (
          <CheckEmail checkEmail={checkEmail} loading={loadingEmailCheck} setEmail={setEmail} error={error} />
        ) : (
          <>
            <div className="mt-6" />
            {emailExists ? (
              <SignInPage
                email={email}
                showTitle={false}
                warningText={
                  "Thank you! It looks like you already have an account with us. Please enter your password to sign in and confirm your shifts."
                }
                addShifts
              />
            ) : (
              <>
                <div className="mt-6 px-2 sm:px-0">
                  <RegistrationDescription text={registrationText} />
                </div>
                <PersonalDetailsPage
                  user={{ email }}
                  pageError={pageError}
                  loading={loading}
                  saveClick={handleRegister}
                  saveButtonText="COMPLETE MY REGISTRATION"
                  backClick={() => {}}
                />
              </>
            )}
            <div className="h-20" />
          </>
        )}
      </>
    </ShiftNavigator>
  );
};

export default Register;
