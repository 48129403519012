import React from "react";
import LogoutButton from "./LogoutButton";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import { getSignedInUser } from "../../Person/personSelector";
import classNames from "classnames";

interface Props {
  mobile?: boolean;
  campaignRunning: boolean;
  handleMobileMenuClick?: (forceClose?: boolean) => void;
}

const AccountButton = ({ handleMobileMenuClick, mobile = false, campaignRunning }: Props) => {
  const user = useSelector(getSignedInUser);
  if (user) {
    return <LogoutButton />;
  }

  if (!campaignRunning) {
    return null;
  }

  const handleSignInClick = () => {
    if (handleMobileMenuClick) {
      handleMobileMenuClick(true);
    }
    navigate("/sign-in?reset=true");
  };

  return (
    <button className={classNames("cursor-pointer item link", { primary: !mobile })} onClick={handleSignInClick}>
      <a className="">Sign in</a>
    </button>
  );
};

export default AccountButton;
