import React from "react";
import RegistrationNavigatorTab, { TabData, Tabs } from "./RegistrationNavigatorTab";
import { useAppSelector } from "../../../hooks";
import { getSignedInUser } from "../../Person/personSelector";
import { useSelector } from "react-redux";
import { campaignEnded } from "domains/CampaignConfig/campaignConfigSelectors";

const RegistrationNavigator = () => {
  const user = useAppSelector(getSignedInUser);

  const campaignHasEnded = useSelector(campaignEnded);

  let tabs: TabData[];

  tabs = user?.areaCoordinator || campaignHasEnded ? [] : [{ text: "shifts", targetBlank: false }];

  tabs = [...tabs, { text: "details", targetBlank: false }, { text: "cart", targetBlank: false }];

  tabs = user ? [...tabs, { text: "shop", targetBlank: true }] : tabs;

  return (
    <div className="flex flex-1 justify-center pt-12 px-2 text-white font-light text-sm">
      {tabs.map((tab, index) => (
        <>
          <div className="bg-white" style={{ width: 1 }} />
          <RegistrationNavigatorTab
            tab={tab}
            areaCoordinator={user?.areaCoordinator}
            user={user}
            tabPosition={index === 0 ? "left" : index === tabs.length - 1 ? "right" : "center"}
          />
        </>
      ))}
    </div>
  );
};

export default RegistrationNavigator;
