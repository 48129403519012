import Title from "../Registration/Common/Title";
import RegistrationNavigator from "../Registration/Common/RegistrationNavigator";
import React from "react";

interface Props {
  children: JSX.Element[] | JSX.Element;
  title: string;
  hideNav?: boolean;
}

const ShiftNavigator = ({ title, children, hideNav = false }: Props) => {
  return (
    <div className="content-wrap flex justify-center flex-1 flex-col">
      <Title text={title} />
      <div className="flex flex-1 justify-center bg-white">
        <div className="w-full md:w-2/3">
          {!hideNav && <RegistrationNavigator />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ShiftNavigator;
