import React from "react";
import { Menu } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  displayText: string;
  handleClick: () => void;
  disabled?: boolean;
}

const DropDownItem: React.FC<Props> = ({ handleClick, displayText, disabled = false }) => (
  <Menu.Item>
    {({ active }) => (
      <div
        onClick={handleClick}
        className={classNames("block px-4 py-2 text-sm first-letter:uppercase ", {
          " bg-gray-100 text-gray-900": active && !disabled,
          " bg-gray-100 text-gray-7000": !active && !disabled,
          "text-cloudDark": disabled,
        })}
      >
        {displayText}
      </div>
    )}
  </Menu.Item>
);

export default DropDownItem;
