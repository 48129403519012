import { RouteComponentProps } from "@reach/router";
import { CMSConsumer } from "domains/Cms/cmsContext";
import { getEntryBySlugId } from "domains/Cms/cmsSelectors";
import CtaBanner from "domains/Home/CtaBanner";
import React from "react";

interface Props extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element;
  title: string;
  slugId?: string;
}

const StaticPage = ({ children, title, slugId }: Props) => {
  const { state } = CMSConsumer() || {};

  if (!state) {
    return null;
  }
  const cmsData = slugId && getEntryBySlugId(state, slugId);

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="content-wrap w-full">
      {" "}
      <div className="">
        <div className="content-heading">
          <h1>
            <span className="editable">{title}</span>
          </h1>
        </div>
        <div className="content-body-wrap">
          <div className="px-0 sm:px-16">
            <div className="">{children}</div>
          </div>
        </div>
      </div>
      <div className="my-10">{cmsData?.showCtaBanner && <CtaBanner handleRegistration={() => {}} />}</div>
    </div>
  );
};

export default StaticPage;
