import { campaignEnded, getCampaignState } from "domains/CampaignConfig/campaignConfigSelectors";
import { CMSConsumer } from "domains/Cms/cmsContext";
import { getEntryBySlugId } from "domains/Cms/cmsSelectors";
import { useHandleRegistration } from "domains/Registration/registrationHooks";
import React from "react";
import { useSelector } from "react-redux";

interface Props {
  onClick?: () => void;
}

const HomeHeader: React.FC<Props> = ({ onClick }) => {
  const { state } = CMSConsumer() || {};

  const campaignState = useSelector(getCampaignState);
  const campaignHasEnded = useSelector(campaignEnded);

  if (!state) {
    return null;
  }

  const cmsData = getEntryBySlugId && getEntryBySlugId(state, "HomePage");
  return (
    <div className="w-full md:w-140 py-4 px-6 sm:py-6 sm:px-28">
      <div className="flex justify-between flex-col h-full">
        <div className="bcf-homepage-header-sm md:bcf-homepage-header font-bold text-white mb-4">
          {cmsData[`${campaignState}HeaderTitle`]}
        </div>

        <div className="pb-8">
          <div className="text-white">
            <p>{cmsData[`${campaignState}HeaderDescription`]}</p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row">
          {!campaignHasEnded && (
            <button
              className="md:w-none justify-center whitespace-nowrap flex items-center rounded-full bg-white md:bg-flamingo px-10 py-4 font-bold focus:outline-none md:group-hover:bg-flamingo md:group-hover:text-white text-flamingo md:text-white"
              onClick={onClick}
            >
              <div className="mr-2 text-center">{cmsData[`${campaignState}RegistrationButtonText`]}</div>
            </button>
          )}
          {cmsData.donateNowToggle && (
            <a
              target="_blank"
              href={cmsData.donateUrl}
              className="w-full md:w-none text-center whitespace-nowrap text-lg underline hover:underline text-white md:pl-10 py-4 font-bold focus:outline-none hover:text-white"
            >
              {cmsData.donateNowButtonText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
