import { Formik } from "formik";
import * as Yup from "yup";
import { ShiftPerson } from "domains/API/type";

interface Props {
  onSubmit: (values: any) => void;
  isAreaCoordinator: boolean;
  shiftPerson: Partial<ShiftPerson>;
  content: (
    values: any,
    errors: any,
    handleChange: any,
    handleSubmit: any,
    show: boolean,
    resetForm?: () => void,
  ) => JSX.Element;
  show: boolean;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const generateValidationSchema = (isAreaCoordinator: boolean) => {
  return Yup.object().shape({
    email: isAreaCoordinator
      ? Yup.string().email().optional()
      : Yup.string().email().required("Please enter an email address"),
    firstName: Yup.string().required("Please enter a first name"),
    lastName: Yup.string().required("Please enter a last name"),
    mobile: Yup.string().matches(phoneRegExp, "Phone number is not valid").optional(),
    organisationName: Yup.string().optional(),
  });
};

const VolunteerDetailsForm: React.FC<Props> = ({ isAreaCoordinator, shiftPerson, content, onSubmit, show }) => {
  const { email, mobile, firstName, lastName } = shiftPerson;

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        email: email ? email : "",
        firstName: firstName ? firstName : "",
        lastName: lastName ? lastName : "",
        mobile: mobile ? mobile : "",
      }}
      validationSchema={generateValidationSchema(isAreaCoordinator)}
      onSubmit={(values: any, { setSubmitting }) => {
        onSubmit({ ...values, uuid: shiftPerson.uuid });
      }}
    >
      {({
        resetForm,
        values,
        errors,
        handleChange,
        handleSubmit,
        /* and other goodies */
      }) => <>{content(values, errors, handleChange, handleSubmit, show, resetForm)}</>}
    </Formik>
  );
};

export default VolunteerDetailsForm;
