import React from "react";

interface Props {
  text: JSX.Element;
}

const RegistrationDescription = ({ text }: Props) => (
  <p className="flex flex-1 justify-center bg-white text-center">{text}</p>
);

export default RegistrationDescription;
