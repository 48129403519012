import React from "react";
import { useAddVolunteerToShifts, useGetOrderedSelectedShifts, useGetSelectedShifts } from "../../Shifts/shiftHooks";
import { useAppSelector } from "../../../hooks";
import { navigate, useLocation } from "@reach/router";
import { getLocations, getLocationsByIds } from "../../Locations/locationSelector";
import ButtonWithLoader from "../../UtilComponents/ButtonWithLoader";
import StepThreeTimeSlot from "./StepThreeTimeSlot";
import { sumCounts } from "./utils";
import { Shift } from "domains/Shifts/types";

interface Props {
  confirmation?: boolean;
}

const StepThree = ({ confirmation = false }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const selectedShifts = useGetSelectedShifts();
  const orderedSelectedShifts = useGetOrderedSelectedShifts();
  const { locations } = useAppSelector(getLocations);
  const { addShifts, loading } = useAddVolunteerToShifts();

  const handleConfirmation = () => {
    if (pathname === "/find-shifts") {
      addShifts();
      return;
    }
    navigate("/register");
  };

  if (!selectedShifts || !Object.keys(selectedShifts).length) {
    return null;
  }

  const volunteerShifts: Shift[] = [];

  const friendShifts: Shift[] = [];

  for (const hour of orderedSelectedShifts) {
    const multiplePersonHour = sumCounts([hour]) > 1;
    if (volunteerShifts.find((shift) => shift.shiftId === hour.shiftId) || hour.friendDetails || multiplePersonHour) {
      friendShifts.push(hour);
      if (!multiplePersonHour) {
        continue;
      }
    }
    volunteerShifts.push(hour);
  }

  return (
    <div className="">
      {!confirmation ? (
        <div className="text-flamingo text-lg mb-4 font-bold flex text-center justify-center sm:justify-start">
          STEP THREE: Confirm the shifts that you’ve selected
        </div>
      ) : null}
      {volunteerShifts.map((shift, index) => {
        const location = locations[shift.locationId];
        const selectedShift = selectedShifts[shift.shiftId];
        return (
          <StepThreeTimeSlot
            key={index}
            shiftId={shift.shiftId}
            shift={shift}
            location={location}
            selectedShift={selectedShift}
          />
        );
      })}
      {friendShifts.length ? (
        <>
          <div className="h-12" />
          <div className="text-center px-2 sm:text-left sm:px-0">
            <p className="pt-6 text-body text-center px-2 sm:text-left sm:px-0">
              {!confirmation
                ? `Shifts I’ve booked for my friends`
                : `Please enter the contact details for your friends. This means we’ll be able to send them all of the
              important health & safety information and it helps our team cut down on admin. Once you’ve entered their
              details, they’ll be able to see their shifts if they choose to create an account and log in.`}
            </p>
          </div>

          {friendShifts.map((shift, index) => {
            const location = locations[shift.locationId];
            const selectedShift = selectedShifts[shift.shiftId];
            return (
              <StepThreeTimeSlot
                confirmation={confirmation}
                friend
                key={index}
                shiftId={shift.shiftId}
                shift={shift}
                location={location}
                selectedShift={selectedShift}
              />
            );
          })}
        </>
      ) : null}
      <div className="h-12" />
      {!confirmation ? (
        <div className="flex justify-center">
          <ButtonWithLoader text="CONFIRM" loading={loading} onClick={handleConfirmation} />
        </div>
      ) : null}
    </div>
  );
};

export default StepThree;
