import React from "react";
import moment from "moment";
import { formatTimeAs12Hour } from "./utils";
import { Shift } from "../../Shifts/types";
import { RawShiftData } from "./types";
import { useAddFriendsDetailsToShift, useRemoveSelectedShift } from "../../Shifts/shiftHooks";
import LoadingSpinner from "../../UtilComponents/LoadingSpinner";
import AddVolunteerModal from "domains/SignedIn/AreaCoordinator/AddVolunteerModal";
import { ShiftPerson } from "domains/API/type";
import ModifyShiftModal from "./ModifyShiftModal";
import { useCheckCollectionSiteCancelled, useCheckIfLocationBlacklisted } from "./collectionSiteHooks";
import CancelShiftModal from "./CancelShiftModal";

const calculateDuration = (timeSlotStart?: string, timeSlotEnd?: string) => {
  if (!timeSlotStart || !timeSlotEnd) {
    return "0";
  }

  const timeSlotStartMinutesString = timeSlotStart.split(":")[1];
  const timeSlotStartMinutesInt = parseInt(timeSlotStartMinutesString);

  const timeSlotEndMinutesString = timeSlotEnd.split(":")[1];
  const timeSlotEndMinutesInt = parseInt(timeSlotEndMinutesString);
  const minDiff = timeSlotEndMinutesInt - timeSlotStartMinutesInt;
  const timeSlotStartHoursString = timeSlotStart.substring(0, timeSlotStart.indexOf(":"));
  const timeSlotStartHoursInt = parseInt(timeSlotStartHoursString);

  const timeSlotEndHoursString = timeSlotEnd.substring(0, timeSlotStart.indexOf(":"));
  let timeSlotEndHoursInt = parseInt(timeSlotEndHoursString);

  const hourDiff = timeSlotEndHoursInt - timeSlotStartHoursInt;

  if (minDiff === -30) {
    return `${hourDiff - 0.5}`;
  }

  return `${hourDiff}${minDiff === 30 ? ".5" : ""}`;
};

const pluralizePeople = (count: number) => {
  return count && count > 1 ? "People" : "Person";
};

interface Props {
  friend?: boolean;
  shiftId: string;
  shift: Shift;
  location: RawShiftData;
  selectedShift: Shift;
  confirmation?: boolean;
}

const StepThreeTimeSlot = ({
  shiftId,
  shift,
  location,
  selectedShift,
  friend = false,
  confirmation = false,
}: Props) => {
  const { loading: loadingDelete, deleteShift } = useRemoveSelectedShift();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [cancelShiftModalOpen, setCancelShiftModalOpen] = React.useState(false);

  const { addFriendsDetailsToShift, loading: loadingAddFriendsDetails } = useAddFriendsDetailsToShift(setModalOpen);

  const handleAddFriendsDetails = (values: ShiftPerson) => {
    addFriendsDetailsToShift({ ...values, shiftId: shift.shiftId, uuid: shift.uuid });
  };

  const checkIfLocationBlacklisted = useCheckIfLocationBlacklisted();

  const locationCancelled = checkIfLocationBlacklisted({ location });

  const getCollectionSiteCancelled = useCheckCollectionSiteCancelled();

  const collectionSiteCancelled = getCollectionSiteCancelled(location);

  return (
    <div className="mx-2 sm:mx-0">
      <div
        key={shiftId}
        className="flex flex-col sm:flex-row justify-between1 bg-candyFloss my-2 rounded py-2 px-4 text-flamingo text-sm hover:bg-blossom hover:text-white"
      >
        <AddVolunteerModal
          isAreaCoordinator={false}
          shiftPerson={shift.friendDetails && shift.friendDetails[0] ? shift.friendDetails[0] : {}}
          setModalContent={() => {}}
          description="Please provide as many details as possible – this helps us with health & safety, as well as cuts down on admin costs. "
          closeModal={() => setModalOpen(false)}
          open={modalOpen}
          handleSubmit={handleAddFriendsDetails}
          loading={loadingAddFriendsDetails}
        />
        <CancelShiftModal
          loading={loadingDelete}
          handleConfirm={() => deleteShift(shift, () => setCancelShiftModalOpen(false))}
          closeModal={() => setCancelShiftModalOpen(false)}
          open={cancelShiftModalOpen}
          shiftId={shift.shiftId}
        />

        <div className="flex flex-1 flex-col sm:flex-row justify-center sm:justify-start">
          <div className="py-2 sm:py-0 pr-4 pr-4 flex justify-center items-center">
            {shift && moment(shift.timeSlotDate, "DD/MM/yyyy").format("DD MMM yyyy")}
          </div>
          <div className="font-bold sm:font-normal py-2 sm:py-0 pr-2 w-full sm:w-40 flex justify-center text-center sm:text-left">
            {`${location?.collectionSite}, ${location?.area}, ${location?.region}`}
          </div>
          <div className="py-2 sm:py-0 items-center justify-center flex">
            <div className="w-32 flex justify-between">
              <div className="font-bold sm:font-normal w-full sm:w-16 flex justify-center items-center">
                {" "}
                {formatTimeAs12Hour(shift?.timeSlotStart)}
              </div>
              <div className="flex justify-center items-center">-</div>
              <div className="font-bold sm:font-normal w-full sm:w-16 pr-1 flex justify-center items-center">
                {formatTimeAs12Hour(shift?.timeSlotEnd)}
              </div>
            </div>
          </div>
          <div className="py-1 sm:py-0 pr-0 sm:pr-6 w-full sm:w-20 flex justify-center items-center">{`${calculateDuration(
            shift?.timeSlotStart,
            shift?.timeSlotEnd,
          )} hours`}</div>
          {shift.friendDetails && friend && (
            <div
              className="font-bold py-1 w-full sm:w-32 flex items-center justify-center"
              onClick={() => setModalOpen(true)}
            >
              <div className="text-center">
                <div className="text-center">
                  {shift.friendDetails[0].firstName} {shift.friendDetails[0].lastName}
                </div>
              </div>
            </div>
          )}
          {!collectionSiteCancelled && !locationCancelled && confirmation && !shift.friendDetails && friend && (
            <button
              className="font-bold underline py-1 w-full sm:w-32 flex items-center justify-center"
              onClick={() => setModalOpen(true)}
            >
              Add my friends details
            </button>
          )}
        </div>
        <div
          className="py-3 sm:py-0 cursor-pointer underline flex justify-center items-center"
          onClick={() => setCancelShiftModalOpen(true)}
        >
          {!locationCancelled && !shift.friendDetails && (loadingDelete ? <LoadingSpinner /> : "Cancel")}
        </div>
      </div>
      {collectionSiteCancelled && (
        <p className="text-sm text-flamingo font-bold pb-4">
          {`Unfortunately, ${location.collectionSite} has been cancelled for 2022. Please see if there’s another available site in your area.`}
        </p>
      )}
    </div>
  );
};

export default StepThreeTimeSlot;
