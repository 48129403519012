import Checkbox from "../../../utils/Checkbox";
import React from "react";

interface Props {
  text: JSX.Element;
  onClick: (checked: boolean) => void;
  checked: boolean;
  errors: any;
  name: string;
}

const CheckField = ({ text, onClick, checked, errors, name }: Props) => {
  return (
    <div className="flex mb-3">
      <div className="mr-2">
        <Checkbox checked={checked} onClick={() => onClick(!checked)} />
      </div>

      <div className="flex flex-col mt-1">
        {text}
        <div className="text-xs text-warning">{errors[name]}</div>
      </div>
    </div>
  );
};

export default CheckField;
