import { createSelector } from "reselect";
import { AppStore } from "../../rootReducer";
import { Maybe } from "../../types";

export const getPersonByUuid = (state: AppStore, uuid: Maybe<string>) => {
  if (!uuid) {
    return null;
  }
  return state.personStore.persons[uuid];
};

export const getAllPersons = (state: AppStore) => {
  return state.personStore.persons;
};

export const getSignedInUserId = (state: AppStore) => {
  return state.personStore.signedInUserId;
};

export const getSignedInUser = (state: AppStore) => {
  const userId = state.personStore.signedInUserId;
  if (!userId) {
    return null;
  }
  return getPersonByUuid(state, userId);
};

export const isUserAdmin = (state: AppStore) => {
  const user = getSignedInUser(state);
  return user?.roles.includes("admin");
};

export const getIsLoadingSignedInUser = (state: AppStore) => {
  return state.personStore.loadingSignedInUser;
};

export const getUserIsAC = createSelector(getSignedInUser, (user) => {
  return user?.areaCoordinator;
});
