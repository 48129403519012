export const pathnameToStepMap: { [path: string]: string } = {
  "/dashboard/new-relationship/mti": "transferDetails",
  "/dashboard/new-relationship/sender-details": "senderDetails",
  "/dashboard/new-relationship/receiver-details": "receiverDetails",
};

export const stepToPathnameMap: { [step: string]: string } = {
  transferDetails: "/dashboard/new-relationship/mti",
  senderDetails: "/dashboard/new-relationship/sender-details",
  receiverDetails: "/dashboard/new-relationship/receiver-details",
  complete: "/dashboard/new-relationship/sender-details",
};

export const stripForwardSlashes = (path: string) => path.replace(/\//g, "");
