import { navigate } from "@reach/router";
import { getCampaignState } from "domains/CampaignConfig/campaignConfigSelectors";
import { CMSConsumer } from "domains/Cms/cmsContext";
import { getEntryBySlugId } from "domains/Cms/cmsSelectors";
import { useSelector } from "react-redux";

interface Props {
  ctaRef?: React.RefObject<HTMLDivElement>;
  handleRegistration: () => void;
}

const CtaBanner: React.FC<Props> = ({ ctaRef: ref, handleRegistration }) => {
  const { state } = CMSConsumer() || {};
  const cmsData = state && getEntryBySlugId(state, "HomePage");
  const campaignState = useSelector(getCampaignState);

  const ctaIframe = cmsData?.[`${campaignState}Iframe`] && cmsData?.[`${campaignState}Iframe`][0];

  return (
    <div ref={ref} id="cta-banner" className="bg-flamingo flex items-center text-center flex-col p-4">
      <div className="h-20 bcf-h1 items-end flex justify-end text-white">
        {cmsData?.[`${campaignState}CtaBannerTitle`]}
      </div>
      <div className="h-24 sm:h-16 text-white text-end items-center flex">
        {cmsData?.[`${campaignState}CtaBannerBody`]}
      </div>
      <div className="w-full">
        {campaignState === "campaignRunning" ? (
          <div className="flex h-40 items-center justify-center">
            <button onClick={handleRegistration} className="px-6 py-4 bg-white rounded-full text-flamingo font-bold">
              <div> {cmsData?.[`${campaignState}CtaBannerButtonText`]}</div>
            </button>
          </div>
        ) : (
          ctaIframe && (
            <div className="flex w-full">
              <iframe
                src={decodeURIComponent(ctaIframe.src)}
                className={ctaIframe.className}
                style={{ height: ctaIframe.height }}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default CtaBanner;
