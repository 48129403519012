import { Api } from "domains/API/API";

import React from "react";
import { useDispatch } from "react-redux";
import { addConfig } from "./campaignConfigSlice";

export const useGetCampaignConfig = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const fetchCampaignConfig = async () => {
    setLoading(true);
    const campaignConfig = await Api.getCampaignConfig();

    dispatch(addConfig(campaignConfig));
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCampaignConfig();
  }, [dispatch]);

  return {
    loading,
  };
};
