import React from "react";
import { Link, navigate, useLocation } from "@reach/router";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getHasCompletedRegistration } from "../../Locations/locationSelector";
import { useGetAccessToken, useGetSelectedShifts } from "../../Shifts/shiftHooks";
import { Nullable } from "../../../types";
import { Person } from "../../Person/types";
import { useShowModal } from "domains/Modal/modalHooks";
import CancelShiftModal from "../CollectionSites/CancelShiftModal";
import { campaignEnded } from "domains/CampaignConfig/campaignConfigSelectors";

interface Props {
  tab: TabData;
  areaCoordinator?: boolean;
  user: Nullable<Person>;
  tabPosition: TabPosition;
  targetBlank?: boolean;
}

const routeToTab = {
  "/collection-sites": "shifts",
  "/register": "details",
  "/account": "account",
};

const tabToRoute = {
  shifts: "/collection-sites",
  details: "/register",
  cart: "/account",
  shop: "https://store.pinkribbonvolunteer.org.nz",
};

const tabToTitle = {
  shifts: "Find Shifts",
  details: "Your Details",
  cart: "Your Shifts",
  shop: "Shop",
};

const authenticatedTabToRoute = {
  shifts: "/find-shifts",
  details: "/my-details",
  cart: "/my-shifts",
  shop: "https://store.pinkribbonvolunteer.org.nz",
};

const authenticatedTabToTitle = (areaCoordinator: boolean) => ({
  shifts: "Find Shifts",
  details: "Your Details",
  cart: areaCoordinator ? "Rosters" : "Your Shifts",
  shop: "Shop",
});

export type Tabs = "shifts" | "details" | "cart" | "shop";

export interface TabData {
  text: Tabs;
  targetBlank: boolean;
}

type TabPosition = "left" | "right" | "center";

const RegistrationNavigatorTab = ({ tab, areaCoordinator = false, user, tabPosition }: Props) => {
  const location = useLocation();
  const { pathname } = location;

  const isActive = pathname === (user ? authenticatedTabToRoute[tab.text] : tabToRoute[tab.text]);

  const hasCompletedRegistration = useSelector(getHasCompletedRegistration);

  const showModal = useShowModal();
  const shifts = useGetSelectedShifts();

  const handleClick = (e: React.MouseEvent) => {
    const route = user ? authenticatedTabToRoute[tab.text] : tabToRoute[tab.text];
    if (route === "/account" && !hasCompletedRegistration) {
      showModal({ type: "register-shifts-flow-warning" });
      return;
    }
    navigate(route);
  };

  const classNameObject = classNames(
    "text-white hover:text-white h-8 flex items-center justify-center px-2 w-24 cursor-pointer",
    {
      "bg-flamingo": isActive,
      "bg-blossom": !isActive,
      "rounded-l-full": tabPosition === "left",
      "rounded-r-full": tabPosition === "right",
    },
  );

  return (
    <>
      {tab.targetBlank ? (
        <a className={classNameObject} href={authenticatedTabToRoute[tab.text]} target="_blank">
          {user ? authenticatedTabToTitle(areaCoordinator)[tab.text] : tabToTitle[tab.text]}
        </a>
      ) : (
        <div className={classNameObject} onClick={handleClick}>
          {user ? authenticatedTabToTitle(areaCoordinator)[tab.text] : tabToTitle[tab.text]}
        </div>
      )}
    </>
  );
};

export default RegistrationNavigatorTab;
