import React from "react";
import { Auth0User } from "../types";

interface Props {
  user?: Auth0User;
}

const PersonalDetailsOverview: React.FC<Props> = ({ user }) => {
  return (
    <div className="w-full">
      <table className="table-auto w-full p-2">
        <tbody className="p-2">
          <tr className="bg-cloudDark px-8 py-4">
            <td className="p-2 w-32 sm:w-64">First Name:</td>
            <td className="p-2">
              <p>{user?.firstName}</p>
            </td>
          </tr>
          <tr className="px-8 py-4">
            <td className="p-2">Last Name:</td>
            <td className="p-2">{user?.lastName}</td>
          </tr>
          <tr className="bg-cloudDark p-2">
            <td className="p-2">Email:</td>
            <td className="p-2">{user?.email}</td>
          </tr>
          <tr>
            <td className="p-2">Mobile:</td>
            <td className="p-2">{user?.mobile}</td>
          </tr>
          <tr className="bg-cloudDark">
            <td className="p-2">Address Line 1:</td>
            <td className="p-2">
              <p>{user?.addressLine1}</p>
            </td>
          </tr>
          <tr>
            <td className="p-2">Suburb:</td>
            <td className="p-2">{user?.suburb}</td>
          </tr>
          <tr className="bg-cloudDark">
            <td className="p-2">City:</td>
            <td className="p-2">{user?.city}</td>
          </tr>
          <tr className="">
            <td className="p-2">Post Code:</td>
            <td className="p-2">{user?.postCode}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PersonalDetailsOverview;
