import React from "react";
import { toast } from "react-hot-toast";

import { CreateUserApi } from "../API/type";
import { Api } from "../API/API";
import { navigate, useLocation } from "@reach/router";
import Cookies from "js-cookie";
import { useGetUser } from "../Account/accountHooks";
import { sumCounts } from "./CollectionSites/utils";
import { useGetLocations } from "domains/Locations/locationHooks";
import { Nullable } from "types";
import pluralize from "pluralize";
import { CMSConsumer } from "domains/Cms/cmsContext";
import { getEntryBySlugId } from "domains/Cms/cmsSelectors";
import { useSelector } from "react-redux";
import { getSignedInUser } from "domains/Person/personSelector";
import { campaignStarted } from "domains/CampaignConfig/campaignConfigSelectors";
import { useGetSelectedShifts } from "domains/Shifts/shiftHooks";

export const useCreateUser = () => {
  const [loading, setLoading] = React.useState(false);
  const { getUser } = useGetUser();
  const { getLocationsFromApi } = useGetLocations();
  const [error, setPageError] = React.useState<Nullable<{ [key: string]: string }>>(null);
  const location = useLocation();
  const isACRegisterPage = location.pathname === "ac-register";

  const createUser = async (entity: CreateUserApi, isACRegisterPage: boolean) => {
    setLoading(true);
    try {
      const res = isACRegisterPage ? await Api.createACCustomer(entity) : await Api.createCustomer(entity);
      Cookies.set("auth-jwt", res.access_token, { expires: res.expires_in });
      await getUser();
      setPageError(null);
      getLocationsFromApi();
      navigate("/my-shifts");
    } catch (e) {
      if (e?.statusCode === 409) {
        setPageError({
          pageError: e?.message,
        });
        return;
      }

      setPageError({
        pageError: isACRegisterPage
          ? "We’re having a bit of trouble finding your information, please get in touch with the team using the details above."
          : "Failed to add your details!",
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    createUser: React.useCallback((values: CreateUserApi, isACRegisterPage: boolean) => {
      createUser(values, isACRegisterPage);
    }, []),
  };
};

export const useHandleRegistration = () => {
  const { state } = CMSConsumer();
  const campaignHasStarted = useSelector(campaignStarted);

  const user = useSelector(getSignedInUser);

  const cmsData = state && getEntryBySlugId(state, "HomePage");

  const handleRegisterClick = (ctaBannerRef?: React.MutableRefObject<Nullable<HTMLDivElement>>) => {
    if (cmsData?.registrationOverrideLink) {
      window.open(cmsData?.registrationOverrideLink, "_blank");
      return;
    }
    if (campaignHasStarted) {
      user?.areaCoordinator ? navigate("/my-shifts") : navigate("/collection-sites");
    } else {
      if (ctaBannerRef?.current) {
        ctaBannerRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    }
  };

  return React.useCallback(
    (ctaBannerRef?: React.MutableRefObject<Nullable<HTMLDivElement>>) => handleRegisterClick(ctaBannerRef),

    [cmsData],
  );
};

export const useCheckEmail = () => {
  const [loading, setLoading] = React.useState(false);
  const [checkedEmail, setCheckedEmail] = React.useState(false);
  const [emailExists, setEmailExists] = React.useState(false);
  const shifts = useGetSelectedShifts();
  const [error, setError] = React.useState<Nullable<string>>(null);
  const checkEmail = async (email: string) => {
    try {
      setLoading(true);
      setEmailExists(await Api.checkEmail(email, Object.values(shifts)));
      setCheckedEmail(true);
    } catch {
      setError("Server error");
    } finally {
      setLoading(false);
    }
  };
  return {
    emailExists,
    checkEmail,
    checkedEmail,
    loading,
    error,
  };
};
