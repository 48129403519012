import { combineReducers } from "redux";
import personSlice, { initialState as initialPersonSlice } from "./domains/Person/personSlice";
import shiftSlice, { initialState as inititalShiftSlice } from "./domains/Shifts/shiftSlice";
import locationSlice, { initialState as inititalLocationSlice } from "./domains/Locations/locationSlice";
import modalSlice, { initialState as inititalModalSlice } from "domains/Modal/modalSlice";
import campaignConfigSlice from "domains/CampaignConfig/campaignConfigSlice";

const appReducer = combineReducers({
  personStore: personSlice,
  shiftStore: shiftSlice,
  locationStore: locationSlice,
  modalStore: modalSlice,
  campaignConfigStore: campaignConfigSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    const { campaignConfigStore } = state;
    return appReducer(
      {
        personStore: initialPersonSlice,
        shiftStore: inititalShiftSlice,
        locationStore: inititalLocationSlice,
        modalStore: inititalModalSlice,
        campaignConfigStore: state.campaignConfigStore,
      },
      action,
    );
  }
  return appReducer(state, action);
};

export type AppStore = ReturnType<typeof rootReducer>;

export default rootReducer;
