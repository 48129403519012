import React from "react";
import { useAppSelector } from "../../../hooks";
import { getLocations } from "../../Locations/locationSelector";
import Loading from "./Loading";
import { Nullable } from "../../../types";

interface Props {
  locationId: Nullable<string>;
}

const LoadingShifts = ({ locationId }: Props) => {
  const { locations } = useAppSelector(getLocations);
  const locationName = locationId && locations[locationId]?.collectionSite;

  if (!locationId) {
    return null;
  }
  return <Loading text={`Loading shifts for ${locationName}...`} />;
};

export default LoadingShifts;
