import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationStore } from "./types";
import { RawShiftData } from "../Registration/CollectionSites/types";
import { LocationNotes } from "domains/API/type";

export const initialState: LocationStore = {
  locations: {},
  selectedLocationId: null,
  registrationCompleted: false,
  loadingLocations: false,
};

const locationSlice = createSlice({
  name: "locationStore",
  initialState,
  reducers: {
    updateLoadingLocations: (state, action: PayloadAction<{ loadingLocations: boolean }>) => {
      state.loadingLocations = action.payload.loadingLocations;
    },
    addNotesToLocations: (state, action: PayloadAction<LocationNotes[]>) => {
      state.locations = {
        ...state.locations,
        ...action.payload.reduce<{ [locationId: string]: RawShiftData }>((accum, location) => {
          const stateLocation = state.locations[location.sfid];
          if (!stateLocation) {
            return accum;
          }
          return {
            ...accum,
            [location.sfid]: {
              ...stateLocation,
              siteNotes: location.siteNotes,
              consentProofLink: location.consentProofLink,
            },
          };
        }, {}),
      };
    },
    addLocations: (
      state,
      action: PayloadAction<{
        [id: string]: RawShiftData;
      }>,
    ) => {
      state.locations = {
        ...state.locations,
        ...action.payload,
      };
    },
    updateAcsRequired: (
      state,
      action: PayloadAction<
        {
          sfid: string;
          acsRequired: boolean;
        }[]
      >,
    ) => {
      state.locations = {
        ...state.locations,
        ...action.payload.reduce<{ [locationId: string]: RawShiftData }>((accum, location) => {
          const stateLocation = state.locations[location.sfid];
          if (!stateLocation) {
            return accum;
          }
          return {
            ...accum,
            [location.sfid]: {
              ...stateLocation,
              acsRequired: location.acsRequired,
            },
          };
        }, {}),
      };
    },
    updateSelectedLocationId: (
      state,
      action: PayloadAction<{
        locationId: string;
      }>,
    ) => {
      state.selectedLocationId = action.payload.locationId;
    },
    registrationCompleted: (
      state,
      action: PayloadAction<{
        registrationCompleted: boolean;
      }>,
    ) => {
      state.registrationCompleted = action.payload.registrationCompleted;
    },
  },
});

export const {
  addLocations,
  updateSelectedLocationId,
  registrationCompleted,
  updateLoadingLocations,
  addNotesToLocations,
  updateAcsRequired,
} = locationSlice.actions;

export default locationSlice.reducer;
