import React from "react";

interface Props {
  areasWhereACIsNeeded: string[];
}

const formatAreaText = (areasWhereACIsNeeded: string[]) => {
  return (
    <div className="pb-2 flex justify-center flex-wrap">
      {" "}
      {areasWhereACIsNeeded.map((area) => (
        <a
          target="_blank"
          href="/becoming-an-area-coordinator"
          className="rounded p-2 m-2 bg-candyFloss text-flamingo"
          key={area}
        >
          <div className="whitespace-nowrap">{area}</div>
        </a>
      ))}
    </div>
  );
};

const FindOutMore = () => {
  return (
    <a
      className="flex justify-center"
      target="_blank"
      href="/becoming-an-area-coordinator"
    >
      Find out more
    </a>
  );
};

const AreaCoordinatorsNeeded = ({ areasWhereACIsNeeded }: Props) => {
  return (
    <>
      {areasWhereACIsNeeded.length ? (
        <div className="mt-14 flex justify-center p-4 border border-flamingo rounded">
          <p>
            <p className="flex justify-center">We still need Area Coordinators in:</p>
            {formatAreaText(areasWhereACIsNeeded)}
            <FindOutMore />
          </p>
        </div>
      ) : null}
    </>
  );
};

export default AreaCoordinatorsNeeded;
