import React from "react";
import classNames from "classnames";
import { Nullable } from "../../../types";

interface Props {
  selectedLocationId: Nullable<string>;
  locationId: string;
  text: string;
  expanded: boolean;
  onClick: (locationId: string) => void;
}

const ACSite = ({ selectedLocationId, locationId, text, expanded, onClick }: Props) => {
  if (!expanded) {
    return null;
  }
  return (
    <div className="w-full flex justify-center hover:text-coal" onClick={() => onClick(locationId)}>
      <div
        style={{ paddingLeft: 2 * 16 }}
        className={classNames("w-full p-2 py-1 cursor-pointer", {
          "bg-flamingo hover:bg-flamingo border-slate text-white": selectedLocationId === locationId,
          "hover:bg-candyFloss": selectedLocationId !== locationId,
        })}
      >
        {text}
      </div>
    </div>
  );
};

export default ACSite;
