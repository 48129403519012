import React from "react";
import { navigate } from "@reach/router";
import { useAppSelector } from "../../../hooks";
import { getSignedInUser } from "../../Person/personSelector";
import LoadingSpinner from "../../UtilComponents/LoadingSpinner";

interface Props {
  campaignRunning: boolean;
}

const StartProcessButton: React.FC<Props> = ({ campaignRunning }) => {
  const user = useAppSelector(getSignedInUser);

  if (!campaignRunning) {
    return null;
  }

  if (user) {
    return (
      <button
        className="bg-blossom text-white py-2 px-3 text-sm hover:text-candyFloss duration-200 transition-all"
        onClick={() => navigate("/my-shifts")}
      >
        <div className="w-16 flex justify-center">
          {!user ? <LoadingSpinner /> : user.areaCoordinator ? "Rosters" : "Your Shifts"}
        </div>
      </button>
    );
  }

  return (
    <button className="bg-blossom text-white py-2 px-3 text-sm hover:text-candyFloss duration-200 transition-all">
      <div className="px-3" onClick={() => navigate("/collection-sites")}>
        Find a Collection Site
      </div>
    </button>
  );
};

export default StartProcessButton;
