import React from "react";
import { RouteComponentProps } from "@reach/router";
import StaticPage from "./StaticPage";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { CMSConsumer } from "domains/Cms/cmsContext";
import { getEntryBySlugId } from "domains/Cms/cmsSelectors";

import { BLOCKS } from "@contentful/rich-text-types";
import classNames from "classnames";

interface Props extends RouteComponentProps {
  slugId: string;
}

const GenericPage: React.FC<Props> = ({ slugId, path }) => {
  const { state } = CMSConsumer() || {};

  if (!state) {
    return null;
  }

  const cmsData = getEntryBySlugId(state, slugId);

  const cmsIframeData = cmsData?.iframe && cmsData?.iframe[0];

  const options = {
    renderNode: {
      [BLOCKS.OL_LIST]: (node: any, children: any) => (
        <ol className="list-decimal pl-8 whitespace-pre-wrap bold">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node: any, children: any) => <li className="mb-4">{children}</li>,
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        return <p className={classNames({ "text-center": cmsData?.textCentered })}>{children}</p>;
      },
    },
  };

  return (
    <StaticPage title={cmsData?.title} path={path} slugId={slugId}>
      <>
        {documentToReactComponents(cmsData?.firstContent, options)}
        {cmsIframeData && (
          <div className="flex w-full">
            <iframe
              src={decodeURIComponent(cmsIframeData.src)}
              className={cmsIframeData.className}
              style={{ height: cmsIframeData.height }}
            />
          </div>
        )}
      </>
    </StaticPage>
  );
};

export default GenericPage;
