import React from "react";
import { navigate } from "@reach/router";
import BCFLogoWhite from "./bcfNZPrsaLogoWhite.svg";
import AccountButton from "./AccountButton";
import { campaignIsRunning } from "domains/CampaignConfig/campaignConfigSelectors";
import { useSelector } from "react-redux";

interface Props {
  handleMobileMenuClick: (forceClose?: boolean) => void;
}

const Header: React.FC<Props> = ({ handleMobileMenuClick }) => {
  const campaignRunning = useSelector(campaignIsRunning);
  const onHomeClick = () => {
    navigate(`/`);
  };
  return (
    <nav className="common">
      <div className="properties">
        <div className="property">
          <a className="" href="//breastcancerfoundation.org.nz/" title="Breast Cancer Foundation NZ" target="_blank">
            <i className="sprites logo-nzbcf">Breast Cancer Foundation NZ</i>
          </a>
        </div>
        <div className="property d-none d-md-flex">
          <a className="" href="//pinkribbonbreakfast.co.nz/" target="_blank" title="Pink Ribbon Breakfast">
            <i className="sprites logo-prb">Pink Ribbon Breakfast</i>
          </a>
        </div>
        <div className="property d-none d-sm-flex">
          <a className="" href="//pinkribbonwalk.co.nz/" title="Pink Ribbon Walk" target="_blank">
            <i className="sprites logo-prw">Pink Ribbon Walk</i>
          </a>
        </div>
        <div className="property d-none d-sm-flex">
          <a className="" href="//takeaction.org.nz/" title="Take Action" target="_blank">
            <i className="sprites logo-ta">Take Action</i>
          </a>
        </div>
        <div className="property d-none d-sm-flex w-28">
          <a className="active" href="/" title="Volunteer">
            <img src={BCFLogoWhite} width="45px" alt="Pink Ribbon Appeal" />
          </a>
        </div>
      </div>
      <div className="spacer" />
      <div className="social-media d-none d-lg-flex">
        <div className="social">
          <a className="" href="https://www.facebook.com/pinkribbonNZ/" title="Facebook" target="_blank">
            <i className="sprites icon-facebook">Facebook</i>
          </a>
        </div>
        <div className="social">
          <a className="" href="https://twitter.com/pinkribbonNZ" title="Twitter" target="_blank">
            <i className="sprites icon-twitter">Twitter</i>
          </a>
        </div>
        <div className="social">
          <a className="" href="https://www.instagram.com/pinkribbonnz/" title="Instagram" target="_blank">
            <i className="sprites icon-instagram">Instagram</i>
          </a>
        </div>
        <div className="social">
          <a className="" href="https://youtube.com/nzbcf" title="Youtube" target="_blank">
            <i className="sprites icon-youtube">Youtube</i>
          </a>
        </div>
        <div className="social">
          <a
            className=""
            href="https://www.pinterest.com/nzbcf/"
            target="_blank"
            title="Check out our Pinterest gallery!"
          >
            <i className="sprites icon-pinterest">Pinterest</i>
          </a>
        </div>
      </div>
      <div className="">
        <div className="w-12 hidden sm:inline-block" />
        <div className="block sm:hidden">
          {
            <AccountButton
              campaignRunning={campaignRunning}
              mobile={true}
              handleMobileMenuClick={handleMobileMenuClick}
            />
          }
        </div>
        <div className="link primary">
          <a
            className=""
            href="https://www.breastcancerfoundation.org.nz/campaign/street-appeal21"
            target="_blank"
            rel="noopener"
          >
            <i className="sprites icon-heart"></i>
            <span>Donate</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
