import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Formik } from "formik";
import * as Yup from "yup";
import Title from "../Registration/Common/Title";
import ButtonWithLoader from "../UtilComponents/ButtonWithLoader";
import FieldInput from "../Registration/PersonalDetails/FieldInput";
import { useAccount } from "./accountHooks";
import classNames from "classnames";
import { useQuery } from "domains/Shared/genericHooks";

interface Props extends RouteComponentProps {
  showTitle?: boolean;
  warningText?: string;
  addShifts?: boolean;
  email?: string;
}

const loginSchema = (forgottenPassword: boolean) => {
  if (forgottenPassword) {
    return Yup.object().shape({
      email: Yup.string().email().required("Please enter your email"),
    });
  } else {
    return Yup.object().shape({
      email: Yup.string().email().required("Please enter your email"),
      password: Yup.string().required("Please enter a password"),
    });
  }
};

const SignInPage: React.FC<Props> = ({ showTitle = true, warningText, addShifts, email }) => {
  const {
    loading: signInLoading,
    signIn,
    signInAndAddShifts,
    signInError,
    loadingResetPassword,
    resetPassword,
    displayMessage,
    setForgottenPassword,
    forgottenPassword,
    resetState,
  } = useAccount();

  const reset = useQuery("reset");

  React.useEffect(() => {
    if (reset === "true") {
      resetState();
      navigate("/sign-in");
    }
  }, [reset]);

  const loading = signInLoading || loadingResetPassword;
  const buttonText = forgottenPassword ? "RESET PASSWORD" : "SIGN IN";
  const buttonLoadingText = forgottenPassword ? "" : "SIGNING IN";

  return (
    <div className={classNames("flex items-center flex-1 flex-col", { "content-wrap": !addShifts })}>
      {showTitle && <Title text={forgottenPassword ? "FORGOTTEN PASSWORD" : "SIGN IN"} />}
      <div className="flex flex-1 bg-white flex-col w-full: sm:w-140 px-4">
        <div
          className={classNames("text-center justify-center mt-4", {
            "": warningText,
          })}
        >
          {warningText ? warningText : displayMessage}
        </div>
        <div className="w-full pb-20 px-2 sm:px-0 flex justify-center">
          <div className="flex  md:w-64 justify-center my-10 flex-col w-full px-2">
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{
                email: email ? email : "",
                password: "",
              }}
              validationSchema={loginSchema(forgottenPassword)}
              onSubmit={(values: any) => {
                if (forgottenPassword) {
                  resetPassword(values.email, setForgottenPassword);
                } else {
                  addShifts ? signInAndAddShifts(values) : signIn(values);
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <FieldInput
                      name={"email"}
                      title={"Email"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      errors={{ ...errors, ...signInError }}
                      touched={touched}
                      classes={"w-full"}
                    />
                    {!forgottenPassword && (
                      <FieldInput
                        name={"password"}
                        title={"Password"}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        errors={{ ...errors, ...signInError }}
                        touched={touched}
                        classes={"w-full"}
                        type="password"
                      />
                    )}
                    <div className="h-4" />
                    <ButtonWithLoader
                      classes="h-12 w-48"
                      loadingText={buttonLoadingText}
                      loading={loading}
                      text={buttonText}
                    />
                    <div className="text-xs flex flex-1 justify-center flex-col mt-4 pt-8">
                      <button
                        onClick={() => setForgottenPassword(!forgottenPassword)}
                        type="button"
                        className="underline text-center"
                      >
                        {!forgottenPassword ? "I've forgotten my password" : "Sign In"}
                      </button>
                      <div className="text-center">
                        <br />
                        or
                        <br />
                        <br />
                      </div>
                      <button onClick={() => navigate("/register")} type="button" className="underline text-center">
                        Register to create an account
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
