import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import StepOne, { Tree } from "./StepOne/StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {
  useCheckIfLocationBlacklisted,
  useCheckIfLocationsBlacklisted,
  useGetFilteredLocations,
  useGetRegions,
} from "./collectionSiteHooks";
import { Nullable } from "../../../types";
import { useGetShiftsForLocation } from "../../Shifts/shiftHooks";
import LoadingShifts from "./LoadingShifts";
import Loading from "./Loading";
import ShiftNavigator from "../../Viewer/ShiftNavigator";
import StepTwoMobile from "./StepTwoMobile";
import { useAppDispatch } from "../../../hooks";
import { updateSelectedLocationId } from "../../Locations/locationSlice";
import { useSelector } from "react-redux";
import { getSignedInUser } from "../../Person/personSelector";
import { getLocations } from "../../Locations/locationSelector";
import { useGetLocations } from "../../Locations/locationHooks";
import CovidCancellationModal from "./CovidCancellationModal";

const CollectionSites: React.FC<RouteComponentProps> = () => {
  const dispatch = useAppDispatch();

  const [covidModalOpen, setCovidModalOpen] = React.useState(false);

  const { getLocationsFromApi } = useGetLocations();

  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchDirty, setSearchDirty] = React.useState<boolean>(true);

  React.useEffect(() => {
    getLocationsFromApi();
  }, []);

  const [selectedLocationId, setSelectedLocationId] = React.useState<Nullable<string>>(null);

  const user = useSelector(getSignedInUser);

  const { getAndSaveShifts, loadingShifts } = useGetShiftsForLocation();

  const { loadingLocations, locations } = useSelector(getLocations);

  const filteredLocationMap = useGetFilteredLocations(searchValue);

  const { regions } = useGetRegions(filteredLocationMap, loadingLocations, searchDirty, setSearchDirty);

  const scrollToStepTwo = () => {
    if (stepTwoRef && stepTwoRef.current) {
      // @ts-ignore
      stepTwoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const checkIfLocationBlacklisted = useCheckIfLocationBlacklisted();

  const handleFolderSelection = (tree: Tree) => {
    const { regionName, subRegionName, areaName } = tree;
    if (
      checkIfLocationBlacklisted({
        regionName,
        subRegionName,
        areaName,
        handleCancelled: () => setCovidModalOpen(true),
      })
    ) {
      return;
    }
  };

  const handleCollectionSiteSelection = (locationId: string) => {
    const location = locations[locationId];

    if (
      checkIfLocationBlacklisted({
        location,
        handleCancelled: () => setCovidModalOpen(true),
      })
    ) {
      return;
    }

    getAndSaveShifts(locationId);

    dispatch(updateSelectedLocationId({ locationId }));
    setSelectedLocationId(locationId);
    scrollToStepTwo();
  };

  const handleShiftSelection = () => {
    //scrollToStepThree();
  };

  const stepTwoRef = React.useRef(null);

  const stepThreeRef = React.useRef(null);

  if (user?.areaCoordinator) {
    navigate("/my-shifts");
  }

  return (
    <ShiftNavigator title="FIND SHIFTS">
      <CovidCancellationModal open={covidModalOpen} closeModal={() => setCovidModalOpen(false)} />
      <div className="px-2 sm:px-0">
        <p className="bg-white flex flex-1 justify-center pt-6 bg-white text-center">
          Each shift is just two hours long – you can select one or more that works for you below. If you'd like to
          volunteer with a friend, select two shifts at the same time-slot and you'll be able to add their details after
          registering.
        </p>
      </div>
      <div className="w-full  pb-24 text-slate">
        <div className="pt-16 pb-20">
          {loadingLocations && !Object.keys(locations).length ? (
            <Loading text={"Loading Locations..."} />
          ) : (
            <StepOne
              onFolderClick={handleFolderSelection}
              header="STEP ONE: Pick an area to collect"
              regions={regions}
              onClick={handleCollectionSiteSelection}
              selectedLocationId={selectedLocationId}
              setSearchDirty={setSearchDirty}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          )}
        </div>
        <div className="w-full pb-10" ref={stepTwoRef}>
          {loadingShifts ? (
            <LoadingShifts locationId={selectedLocationId} />
          ) : (
            <>
              <StepTwo
                selectedLocationId={selectedLocationId}
                stepTwoRef={stepTwoRef}
                handleShiftSelection={handleShiftSelection}
              />
              <StepTwoMobile
                selectedLocationId={selectedLocationId}
                stepTwoRef={stepTwoRef}
                handleShiftSelection={handleShiftSelection}
              />
            </>
          )}
        </div>
        <div className="w-full pb-20" ref={stepThreeRef}>
          {loadingShifts ? null : <StepThree />}
        </div>
      </div>
    </ShiftNavigator>
  );
};

export default CollectionSites;
