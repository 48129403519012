import { useState } from "react";
import classNames from "classnames";
import { boolean } from "yup/lib/locale";

interface Props {
  checked: boolean;
  toggleChecked: any;
}

const Switch: React.FC<Props> = ({ checked, toggleChecked }) => {
  return (
    <>
      <div
        className={classNames("w-7 h-4 flex items-center rounded-full p-1 cursor-pointer ", {
          "bg-flamingo hover:bg-lipGloss": checked,
          "bg-blossomLight hover:bg-blossom": !checked,
        })}
        onClick={toggleChecked}
      >
        <div
          className={classNames("bg-white h-3 w-3 rounded-full shadow-md transform duration-300 ease-in-out", {
            "transform translate-x-2": checked,
          })}
        />
      </div>
    </>
  );
};

export default Switch;
