import React from "react";
import ShiftNavigator from "../Viewer/ShiftNavigator";
import { navigate, RouteComponentProps } from "@reach/router";
import Loading from "../Registration/CollectionSites/Loading";
import { useAppSelector } from "../../hooks";
import { getIsLoadingSignedInUser, getSignedInUser } from "../Person/personSelector";
import AreaCoordinatorPage from "./AreaCoordinator/AreaCoordinatorPage";
import StandardUserMyShiftsPage from "./StandardUserMyShiftsPage";
import { startCase, toLower } from "lodash";

const MyShifts: React.FC<RouteComponentProps> = ({ path }) => {
  const user = useAppSelector(getSignedInUser);
  const loading = useAppSelector(getIsLoadingSignedInUser);

  if (loading) {
    return (
      <div className="w-full  pb-24 text-slate justify-center">
        <div className="h-100 flex items-center justify-center">
          <Loading text="Loading your data..." />
        </div>
      </div>
    );
  }

  if (!user) {
    navigate("/sign-in");
  }

  return (
    <>
      <ShiftNavigator title={`Hi ${startCase(toLower(user?.firstName))}`}>
        <div className="w-full  pb-24 text-slate">
          <div className="w-full pb-20">
            <div className="flex justify-center my-10">
              {user?.areaCoordinator || user?.roles.includes("admin") ? (
                <AreaCoordinatorPage />
              ) : (
                <StandardUserMyShiftsPage />
              )}
            </div>
          </div>
        </div>
      </ShiftNavigator>
    </>
  );
};

export default MyShifts;
