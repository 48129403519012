import classNames from "classnames";
import LoadingSpinner from "domains/UtilComponents/LoadingSpinner";
import React from "react";

interface Props {
  text: string;
  onClick?: () => void;
  type?: "button" | "submit";
  isLoading?: boolean;
  disabled?: boolean;
}

const Button = ({ text, onClick, type = "submit", disabled = false, isLoading = false }: Props) => {
  return (
    <div className="flex justify-center">
      <button
        disabled={disabled}
        type={type}
        className={classNames(
          "rounded-full p-3 border w-64 focus:outline-none border-flamingo font-bold text-flamingo hover:shadow",
          { "opacity-50": disabled, "hover:bg-flamingo hover:text-white": !disabled },
        )}
        onClick={onClick}
      >
        {isLoading ? (
          <div className="flex justify-center h-6 items-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div>{text}</div>
        )}
      </button>
    </div>
  );
};

export default Button;
