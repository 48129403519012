import React, { Fragment } from "react";
import classNames from "classnames";
import { isUserAdmin } from "domains/Person/personSelector";
import { useAppSelector } from "hooks";
import { ShiftPerson } from "../../API/type";
import ModifyShiftModal from "domains/Registration/CollectionSites/ModifyShiftModal";
import { useConfirmHeldShift, useRemoveACShift, useShiftHasFriendAdded } from "domains/Shifts/shiftHooks";
import ShiftDropDown from "./ShiftDropDown";
import CancelShiftModal from "domains/Registration/CollectionSites/CancelShiftModal";

interface Props {
  shiftPerson: ShiftPerson;
  handleClick: () => void;
  regionCancelled?: boolean;
  cancelOnly: boolean;
  disabled?: boolean;
}

const RenderShiftPerson = ({
  shiftPerson,
  handleClick,
  regionCancelled = false,
  cancelOnly,
  disabled = false,
}: Props) => {
  const userIsAdmin = useAppSelector(isUserAdmin);
  const [cancelShiftModalOpen, setCancelShiftModalOpen] = React.useState(false);
  const [confirmHeldShiftModal, setConfirmHeldShiftModal] = React.useState(false);

  const { loading: loadingDelete, deleteShift } = useRemoveACShift();
  const { loading: loadingConfirm, confirmShift } = useConfirmHeldShift();

  const shiftHasFriendAdded = useShiftHasFriendAdded();
  const [shiftHasFriend, setShiftHasFriend] = React.useState(false);

  React.useEffect(() => {
    setShiftHasFriend(shiftHasFriendAdded(shiftPerson.shiftId));
  }, [open]);

  return (
    <div className="relative group">
      <CancelShiftModal
        loading={loadingDelete}
        handleConfirm={() =>
          deleteShift(shiftPerson.uuid, shiftPerson.shiftId, true, () => setCancelShiftModalOpen(false))
        }
        closeModal={() => setCancelShiftModalOpen(false)}
        open={cancelShiftModalOpen}
        shiftId={shiftPerson.shiftId}
      />

      <ModifyShiftModal
        loading={loadingConfirm}
        handleConfirm={() => confirmShift(shiftPerson.uuid, shiftPerson.shiftId, () => setConfirmHeldShiftModal(false))}
        closeModal={() => setConfirmHeldShiftModal(false)}
        open={confirmHeldShiftModal}
        text={"Are you sure you want to confirm this shift?"}
      />

      <div style={{ right: -20 }} className={classNames("absolute top-0 cursor-pointer")}>
        <ShiftDropDown
          disabled={disabled}
          regionCancelled={regionCancelled}
          shiftPerson={shiftPerson}
          cancelOnly={cancelOnly}
          setCancelShiftModalOpen={setCancelShiftModalOpen}
          setConfirmHeldShiftModal={setConfirmHeldShiftModal}
        />
      </div>
      <button
        className={classNames("text-xs w-full flex flex-row my-2  items-center sm:items-start", {
          "cursor-pointer": userIsAdmin,
          "cursor-default": !userIsAdmin,
        })}
        disabled={!userIsAdmin}
        onClick={handleClick}
      >
        {shiftPerson.held && (
          <div className="text-center sm:text-left bg-flamingo border rounded mr-1 px-1 text-xxs text-white">
            On hold
          </div>
        )}
        <div>
          <div className="flex font-bold">
            <div className="w-40 truncate text-left text-center sm:text-left pr-4">
              {`${shiftPerson.firstName} ${shiftPerson.lastName}`}{" "}
            </div>
          </div>
          <div className="text-center sm:text-left">{shiftPerson.email}</div>
          <div className="text-center sm:text-left">{shiftPerson.mobile}</div>
          <div className="text-center sm:text-left">{shiftPerson.organisationName}</div>
        </div>
      </button>
    </div>
  );
};

export default RenderShiftPerson;
