import React from "react";

const AreaCoordinatorThankyou = () => {
  return (
    <div>
      <div>
        <p className="text-center font-bold">
          Thanks again for signing up to be an Area Coordinator for this year’s Pink Ribbon Street Appeal! The appeal
          couldn’t happen without your support.
        </p>
        <p className="text-center">
          On this page, you can see your volunteers’ contact details, register your friends and whānau, and download
          your rosters.
        </p>
      </div>
    </div>
  );
};

export default AreaCoordinatorThankyou;