import { RawShiftData, Region } from "./types";
import { Shift } from "../../Shifts/types";
import { getShift } from "domains/SignedIn/AreaCoordinator/AreaCoordinatorHelpers";
import { useGetSelectedShiftById } from "domains/Shifts/shiftHooks";
import moment from "moment";
import { getSignedInUser, getUserIsAC } from "domains/Person/personSelector";
import { useSelector } from "react-redux";

interface Props {
  locations: RawShiftData[];
}

export const sumCounts = (shifts?: Shift[]) => {
  if (!shifts || !shifts.length) {
    return 0;
  }
  return shifts.reduce((accum, shift) => {
    const tempCount = shift?.countTemp ? shift.countTemp : 0;
    const syncedCount: number = shift?.countSynced ? shift.countSynced : 0;
    return accum + tempCount + syncedCount;
  }, 0);
};

export const getTempCount = (shifts: Shift[]) => {
  return shifts.reduce((accum, shift) => {
    const tempCount = shift?.countTemp ? shift.countTemp : 0;
    return accum + tempCount;
  }, 0);
};

export const checkMultiplePeopleAdded = (shifts: Shift[]) => {
  if (sumCounts(shifts) > 1) {
    return true;
  }
  return false;
};

export const getRegions = ({ locations }: Props) => {
  const regions: { [regionName: string]: Region } = {};
  for (const row of locations) {
    const regionName = row.region;
    regions[regionName] = {
      ...regions[regionName],
      regionName,
      subRegions: {
        ...regions[regionName]?.subRegions,
        [row.subRegion ? row.subRegion : "All Areas"]: {
          ...regions[regionName]?.subRegions[row.subRegion ? row.subRegion : "All Areas"],
          regionName,
          subRegionName: row.subRegion ? row.subRegion : "All Areas",
          areas: {
            ...regions[regionName]?.subRegions[row.subRegion ? row.subRegion : "All Areas"]?.areas,
            [row.area]: {
              regionName,
              areaName: row.area,
              collectionSites: {
                ...regions[regionName]?.subRegions[row.subRegion ? row.subRegion : "All Areas"]?.areas[row.area]
                  ?.collectionSites,
                [row.collectionSite]: {
                  collectionSiteName: row.collectionSite,
                  locationId: row.id,
                  status: row.status,
                  visible: row.visible,
                  cancelOnly: row.cancelOnly,
                },
              },
            },
          },
        },
      },
    };
  }
  return regions;
};

export const formatTimeAs12Hour = (time: string) => {
  const hoursString = time.substring(0, time.indexOf(":"));
  const minutesString = time.split(":")[1];
  let hoursInt = parseInt(hoursString);
  let timeType = "am";
  if (hoursInt >= 12) {
    timeType = "pm";
  }
  if (hoursInt > 12) {
    hoursInt = hoursInt - 12;
  }
  return `${hoursInt}:${minutesString}${timeType}`;
};

export const getShiftsAvailable = (
  searchDate: string,
  searchTime: string,
  selectedLocationShifts: Shift[],
  searchDuration?: number,
) => {
  if (!searchDuration) {
    return 0;
  }
  const shift = getShift(searchDate, searchTime, selectedLocationShifts, searchDuration);
  return shift?.slotsAvailable ? parseInt(shift.slotsAvailable) : 0;
};

export const getShiftsRemaining = (
  searchDate: string,
  searchTime: string,
  selectedLocationShifts: Shift[],
  searchDuration?: number,
) => {
  if (!searchDuration) {
    return 0;
  }
  const shift = getShift(searchDate, searchTime, selectedLocationShifts, searchDuration);

  if (!shift) {
    return 0;
  }

  const shiftsAvailable = shift?.slotsAvailable ? parseInt(shift.slotsAvailable) : 0;

  const getSelectedShiftById = useGetSelectedShiftById();

  const shifts = getSelectedShiftById(shift?.shiftId);

  const selectedShiftCount: number = getTempCount(shifts);

  return shiftsAvailable - selectedShiftCount;
};

export const isExpired = (timeStamp: number, userIsAC = false) => {
  const previousMidday = moment(timeStamp).subtract(1, "days").startOf("day").add(moment.duration(12, "hours"));

  if (userIsAC) {
    return moment(Date.now()).diff(moment(timeStamp), "hours") >= 2;
  }
  return moment(Date.now()).diff(previousMidday, "hours") >= 0;
};
