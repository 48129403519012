import React from "react";
import { startCase, toLower } from "lodash";
import BCFLogo from "./bcfnz-prsa-logo-fullcolour-rgb.svg";
import AccountButton from "../Viewer/Header/AccountButton";
import classNames from "classnames";
import { navigate } from "@reach/router";
import StartProcessButton from "../Viewer/Header/StartProcessButton";
import { useSelector } from "react-redux";
import { getSignedInUser } from "../Person/personSelector";
import { CMSConsumer } from "domains/Cms/cmsContext";
import {
  getEntryBySlugId,
  getFilteredSlugIds,
  getHeaders,
  hasMultiple,
  isHeader,
  removePrefix,
} from "domains/Cms/cmsSelectors";
import { campaignIsRunning } from "domains/CampaignConfig/campaignConfigSelectors";

interface Props {
  handleMobileMenuClick: (forceClose?: boolean) => void;
}

const SubHeader = ({ handleMobileMenuClick }: Props) => {
  const user = useSelector(getSignedInUser);
  const campaignRunning = useSelector(campaignIsRunning);

  const { state } = CMSConsumer() || {};

  if (!state) {
    return null;
  }

  const menuHeaders = getHeaders(state);

  return (
    <header>
      <div className="header-wrap">
        <div className="p-4">
          <a
            onClick={() => {
              navigate("/");
              handleMobileMenuClick(true);
            }}
            className="cursor-pointer"
            title="Pink Ribbon Appeal"
          >
            <img src={BCFLogo} alt="Pink Ribbon Appeal | Breast Cancer Foundation New Zealand" />
          </a>
        </div>
        <div className="spacer" />
        <div className="menu-block mr-0">
          <div
            className={classNames("mr-4", {
              "flex h-full items-center": user,
              "menu-upper": !user,
            })}
          >
            <div>
              <div className="flex h-full text-sm">
                {
                  <>
                    <StartProcessButton campaignRunning={campaignRunning} />
                    <AccountButton campaignRunning={campaignRunning} />
                  </>
                }
              </div>
            </div>
          </div>

          <div className="menu-lower mr-4">
            {menuHeaders.map((header: string, index: number) => {
              const multiple = hasMultiple(state, header);
              const slugIds = getFilteredSlugIds(state, header);

              return (
                <div key={index} className={classNames("item", { dropdown: multiple })}>
                  <a onClick={() => isHeader(state, header) && navigate(`/${header}`)} title={header}>
                    {startCase(toLower(getEntryBySlugId(state, header)?.menuText || header))}
                  </a>
                  {multiple && (
                    <ul className="dropdown-content">
                      {slugIds.map((slugId) => (
                        <li key={slugId}>
                          <a onClick={() => navigate(`/${removePrefix(slugId)}`)}>
                            {startCase(toLower(getEntryBySlugId(state, slugId)?.title))}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
            <div className="item">
              <a onClick={() => navigate("/whereyourmoneygoes")}>How you're helping</a>
            </div>
          </div>
        </div>
        <div className="menu-small cursor-pointer" onClick={() => handleMobileMenuClick()}>
          <a>
            <span>Menu </span>
            <i className="fas fa-bars fa-fw fa-2x" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default SubHeader;
