import React from "react";
import { RouteComponentProps } from "@reach/router";
import StaticPage from "./StaticPage";

const Terms: React.FC<RouteComponentProps> = ({ path }) => {
  return (
    <StaticPage title={"TERMS & CONDITIONS"}>
      <h2 className="c20">
        <span>BCFNZ Pink Ribbon Street Appeal: Friday 28 and Saturday 29 October 2022</span>
      </h2>
      <h3>
        <span>Terms and Conditions for Registered Individuals and Area Coordinators</span>
      </h3>
      <p>
        <span>
          Thank you for your interest in this year’s Breast Cancer Foundation NZ (BCFNZ) Pink Ribbon Street Appeal
          campaign (Event).
        </span>
      </p>
      <p className="c14" id="h.gjdgxs">
        <span>
          To complete your registration for the Event, you will need to create an account at
          www.pinkribbonvolunteer.org.nz (Portal). &nbsp;You can either register as a volunteer to collect funds
          (Registered Individual) or as an area coordinator to manage volunteers, sites and stock (Area Coordinator).
        </span>
      </p>
      <p>
        <span>
          Please note that BCFNZ may decline to approve or may terminate your registration at any time, entirely in its
          discretion, in order to protect the integrity of the Event.
        </span>
      </p>
      <p>
        <span>
          These Terms govern your access to and use of the Portal and your participation in the Event. &nbsp;Please read
          them carefully and contact us if you have any questions. By accessing and using the Portal, and participating
          in the Event, you acknowledge and agree to be bound by these Terms.
        </span>
      </p>
      <ol className="pt-6" start={1}>
        <li className="c5 c7 list-decimal">
          <h3>
            <span className="c9">Definitions</span>
          </h3>
        </li>
      </ol>
      <p className="c1 c5">In these Terms</p>
      <p className="c1">
        <strong>Area Coordinator</strong>
        <span>
          &nbsp;means a volunteer who applies and is approved by BCFNZ to collect donations, and to contact and help
          coordinate Registered Individuals and Event sites and stock, during the Event.
        </span>
      </p>
      <p className="c1">
        <strong>BCFNZ Privacy Policy</strong>{" "}
        <span>
          means the privacy policy available at www.breastcancerfoundation.org.nz/privacy-policy (as updated from time
          to time).
        </span>
      </p>
      <p className="c1">
        <strong>Registered Individual</strong>
        <span>
          &nbsp;means a volunteer who applies and is approved by BCFNZ to collect donations for BCFNZ during the Event.
        </span>
      </p>
      <p className="c1">
        <strong>Terms</strong>{" "}
        <span>
          means these Terms and Conditions, the BCFNZ Privacy Policy and any other policies and guidelines related to
          the Event provided or notified to you by or on behalf of BCFNZ (as updated by BCFNZ from time to time).
        </span>
      </p>
      <ol className="pt-6" start={2}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Portal Use</span>
          </h3>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              When you register to use the Portal and at all times during your use of the Portal, you must provide us
              with accurate, up to date and complete information that is not misleading.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              Any user name and password that you are provided with or use to access the Portal is unique to you and you
              agree not to transfer it to or share it with any third party. You agree to keep it confidential and secure
              at all times.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>You must only use the Portal for the purposes of the Event. &nbsp;For example:</span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>If you are a Registered Individual you may use the Portal to:</span>
          </h4>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-4">
        <li className="ml-7 list-disc">
          <p>
            <span>register yourself as a Registered Individual;</span>
          </p>
        </li>
        <li className="ml-7 list-disc">
          <p>
            <span>schedule, change or cancel your shift or collection site; and</span>
          </p>
        </li>
        <li className="ml-7 list-disc">
          <p>
            <span>
              register a friend as a Registered Individual for a shift at your collection site (or change or cancel that
              shift) (if you do so, you confirm that they have authorised you to do so on their behalf and that they
              have agreed to the Terms). &nbsp;
            </span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>If you are an Area Coordinator you may use the Portal to:</span>
          </h4>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-4">
        <li className="ml-7 list-disc">
          <p>
            <span>register yourself as an Area Coordinator (in accordance with the directions on the Portal);</span>
          </p>
        </li>
        <li className="ml-7 list-disc">
          <p>
            <span>fulfil your role as an Area Coordinator; and</span>
          </p>
        </li>
        <li className="ml-7 list-disc">
          <p>
            <span>
              access the relevant details (e.g., contact names, emails and phone numbers) of Registered Individuals in
              the collection area you selected and which BCFNZ has approved.
            </span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              You agree to collect on behalf of BCFNZ at the current collection site selected by you on the portal (and
              approved by BCFNZ). &nbsp; &nbsp;
            </span>
          </p>
        </li>
      </ol>
      <ol className="pt-6" start={3}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Portal Security</span>
          </h3>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              BCFNZ has taken all practicable steps to ensure that the information you transmit to us over the Portal is
              secure. However BCFNZ does not warrant or guarantee the security of the Portal, nor that it will function
              without error or interruption.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              We care about the security of our volunteers and you must notify us promptly about any compromise to or
              unauthorised use of your account.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p id="h.30j0zll">
            <span>
              You agree not to violate or attempt to violate the security of the Portal. If you discover any potential
              security vulnerabilities in the Portal or our website you must disclose it to us in a responsible manner.
              &nbsp;Please share the details of any suspected vulnerabilities with us promptly, by using the contact
              details set out on our website.
            </span>
          </p>
        </li>
      </ol>
      <ol className="pt-6" start={4}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Collection and Deposit of Donations</span>
          </h3>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>The Event will take place on Friday 28 and Saturday 29 October 2022, subject to any</span>
            <span className="c12">
              <a
                className="c19"
                href="https://www.charities.govt.nz/covid-19/how-can-i-fundraise-at-alert-levels-1-2-3-and-4/"
              >
                Alert Level changes
              </a>
            </span>
            <span>&nbsp;notified to you by BCFNZ.</span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              BCFNZ or your Area Coordinator will provide you with important guidelines about the collection and deposit
              of donations during the Event. &nbsp;It is essential that you read and strictly follow those guidelines.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>Without limiting the above, if you are a:</span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>Registered Individual, you must:</span>
          </h4>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-4">
        <li className="ml-7 list-disc">
          <p>
            <span>
              hand all money that you have collected during each collection shift to your Area Coordinator at the end of
              the shift; and
            </span>
          </p>
        </li>
        <li className="ml-7 list-disc">
          <p>
            <span>
              immediately report any non-compliance with BCFNZ’s guidelines about the collection and deposit of
              donations, to your Area Coordinator or to BCFNZ (see Contact Us section below);
            </span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>Area Coordinator, you must:</span>
          </h4>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-4">
        <li className="ml-7 list-disc">
          <p>
            <span>
              immediately report any non-compliance with BCFNZ’s guidelines about the collection and deposit of
              donations to BCFNZ (see Contact Us section below); and
            </span>
          </p>
        </li>
        <li className="ml-7 li-bullet-2">
          <p>
            <span>
              bank all of the money collected (by you and the Registered Individuals who report to you), to the credit
              of the BCFNZ bank account which BCFNZ provides to you, at the nearest ASB Bank on the day of the
              collections (or, if the Bank is closed, as soon as the Bank opens). You must contact BCFNZ immediately if
              any issues arise that may impact this.
            </span>
          </p>
        </li>
      </ol>
      <ol className="mt-5" start={5}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Health and Safety</span>
          </h3>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              You acknowledge and agree to comply with the Health and Safety Guidelines which BCFNZ provides for the
              Event.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>The Registered Individual or Area Coordinator (as applicable) will take reasonable care:</span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>for their own health and safety; and</span>
          </h4>
        </li>
        <li className="ml-3 list-disc">
          <h4>
            <span>that their acts or omissions do not adversely affect the health and safety of others.</span>
          </h4>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              The Registered Individual must promptly inform their Area Coordinator and/or BCFNZ representative if they
              think the work/processes/individuals/equipment are putting anyone’s health or safety at risk.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              The Registered Individual must report any hazards identified, accidents or incidents to their Area
              Coordinator and/or BCFNZ head office as soon as possible after becoming aware of the hazard, accident or
              incident. Area Coordinators must pass on the information to BCFNZ head office immediately. &nbsp;
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              Registered Individuals or Area Coordinators must be over the age of 18 or if under the age of 18, must be
              collecting with a parent/guardian who is responsible for them throughout their collection shift and while
              collecting or handling money.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              You must follow any instructions given to you by the BCFNZ representative or Area Coordinator responsible
              for the collection site and comply with any instruction given to you by them. BCFNZ, alongside our
              volunteer Area Coordinators, will seek out safe working environments and manage any hazards in the
              volunteer collection sites through liaison with the any relevant collection site management team, for
              example, if you are stationed at a managed collection site located in a mall or shopping district.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              If you have registered another individual (as a Registered Individual) for a collection shift, you must
              advise BCFNZ via the Portal so that BCFNZ can share the relevant health and safety information and other
              details with that individual prior to the Event. &nbsp;
            </span>
          </p>
        </li>
      </ol>
      <ol className="pt-6" start={6}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Privacy policy and personal information</span>
          </h3>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              You agree that BCFNZ may collect, hold, use and disclose your personal information in accordance with the
              Privacy Act 2020, this clause 6 and our privacy policy available at
              www.breastcancerfoundation.org.nz/privacy-policy (
            </span>
            <span className="c10">BCFNZ Privacy Policy</span>
            <span>
              ). &nbsp;We may update our Privacy Policy from time to time and any changes will automatically apply to
              all personal information that we hold. &nbsp;We will notify you by email of any material changes.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              BCFNZ may use your personal information for the purpose of administering the Event, including to contact
              you with relevant information regarding the Event, for example, health and safety information.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              When you register, you may also ‘opt in’ to receive ongoing communications from BCFNZ. If you opt in, you
              can unsubscribe by clicking on the link in any future email you receive from BCFNZ. If you do not opt-in
              to ongoing communications from BCFNZ, you will only receive communications related to the Event.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              BCFNZ may disclose your personal information to the extent necessary to carry out the Event. This may
              include sharing your contact details (such as your name, email, phone number and selected collection site)
              with:
            </span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>other Registered Individuals;</span>
          </h4>
        </li>
        <li className="ml-3 list-disc">
          <h4>
            <span>BCFNZ representatives; and</span>
          </h4>
        </li>
        <li className="ml-3 list-disc">
          <h4>
            <span>your Area Coordinator. &nbsp;</span>
          </h4>
        </li>
      </ol>
      <ol className="">
        <li className="c0 list-disc">
          <p>
            <span>
              As an Area Coordinator and Registered Individual you may access personal information of other Registered
              Individuals for the purposes of the Event. &nbsp;You must:
            </span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>
              only use that personal information to fulfil your role as an Area Coordinator or Registered Individual (as
              applicable) &nbsp;and not for any other purpose;
            </span>
          </h4>
        </li>
        <li className="ml-3 list-disc">
          <h4>
            <span>
              only &nbsp;share the personal information of Registered Individuals with other Registered Individuals as
              necessary to facilitate the Event (for example where they are stationed at the same collection point) and
              you must keep it secure; and
            </span>
          </h4>
        </li>
        <li className="ml-3 list-disc">
          <h4>
            <span>
              delete any personal information of Registered Individuals that you hold when the Event is complete.
            </span>
          </h4>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              You are entitled to access and request correction of your personal information that BCFNZ holds in
              accordance with the Privacy Act 2020 (please refer to the BCFNZ Privacy Policy).
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>If you register another person as a volunteer to collect for the Event, you confirm that:</span>
          </p>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-3">
        <li className="ml-3 list-disc">
          <h4>
            <span>
              they have authorised you to provide us with their personal information for this purpose and you have
              informed them of their rights to access and correct their personal information in accordance with the
              BCFNZ Privacy Policy; and
            </span>
          </h4>
        </li>
        <li className="ml-3 list-disc">
          <h4>
            <span>
              you have informed them that BCFNZ will contact them for the purposes of the Event, including to confirm
              their collection shift and share relevant health and safety information.
            </span>
          </h4>
        </li>
      </ol>
      <ol className="pt-6" start={7}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Area Coordinator - Criminal Conviction check</span>
          </h3>
        </li>
      </ol>
      <p className="c1">
        <span>
          You must pass a Ministry of Justice Criminal Conviction check before you participate in the Event as an Area
          Coordinator. &nbsp;Accordingly, if you apply to be an Area Coordinator, you agree to take any steps reasonably
          requested by BCFNZ to enable BCFNZ to complete a Ministry of Justice Criminal Conviction check about you prior
          to the Event (including, if requested, by completing a ‘
        </span>
        <span className="c12">
          <a className="c19" href="https://www.justice.govt.nz/assets/Documents/Forms/CRC-request-by-third-party.pdf">
            Request for Criminal Conviction History
          </a>
        </span>
        <span>
          ’). &nbsp;BCFNZ will not share this information with any other person, unless we are required to do so in
          accordance with applicable laws.
        </span>
      </p>
      <ol className="pt-6" start={8}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">BCFNZ Property</span>
          </h3>
        </li>
      </ol>
      <ul>
        <li>
          <p className="c1 c5">
            <span>
              You agree to return all BCFNZ stock (including collection equipment) provided to you by BCFNZ to your Area
              Coordinator or BCFNZ representative (as applicable) at the end of the Event and before you leave.
            </span>
          </p>
        </li>
      </ul>
      <ol className="pt-6" start={9}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Intellectual Property</span>
          </h3>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_3-2">
        <li className="c0 list-disc">
          <p>
            <span>
              You will not use any BCFNZ logos (including the Pink Ribbon Street Appeal logo) to create advertising
              materials, notices or social media posts about BCFNZ or the Event without express written permission which
              BCFNZ either directly provides to you or permits and sets out on its social media channels.
            </span>
          </p>
        </li>
        <li className="c0 list-disc">
          <p>
            <span>
              You may not represent BCFNZ other than for collecting at your selected Event collection time and
              collection site without express written permission from BCFNZ.
            </span>
          </p>
        </li>
      </ol>
      <ol className="pt-6" start={10}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Liability</span>
          </h3>
        </li>
      </ol>
      <p className="c1">
        <span>
          To the extent permitted by law, you release BCFNZ from any liability or claim you may have against BCFNZ,
          including for direct, indirect, special, incidental or consequential loss, cost or damage of any kind that you
          may incur as a result of your participation in the Event.
        </span>
      </p>
      <ol className="pt-6" start={11}>
        <li className="c7 c5 list-decimal">
          <h3 id="h.1fob9te">
            <span className="c9">Termination and suspension</span>
          </h3>
        </li>
      </ol>
      <ul>
        <li>
          <p className="c1 c5" id="h.3znysh7">
            <span>
              BCFNZ may terminate or suspend your use of the Portal, any functionality provided through the Portal, or
              your specific account, at its discretion (with or without cause or notice). &nbsp;BCFNZ may also terminate
              or suspend your registration at any time, at its discretion, on notice to you. &nbsp;Following termination
              (or during any period of suspension) you must immediately cease representing and collecting donations on
              behalf of BCFNZ and return all BCFNZ stock and other property, as well as all donations collected, to your
              Area Coordinator or BCFNZ.
            </span>
            <span className="c10">&nbsp;</span>{" "}
            <span>
              Upon termination, all provisions of these terms that by their nature should survive termination will
              survive termination.
            </span>
          </p>
        </li>
      </ul>
      <ol className="pt-6" start={12}>
        <li className="c7 c5 list-decimal">
          <h3>
            <span className="c9">Contact Us</span>
          </h3>
        </li>
      </ol>
      <p className="c1">
        <span>If you have any questions about these Terms or the Event, please contact us at the</span>{" "}
        <span className="c12">
          <a className="c19" href="https://pinkribbonvolunteer.org.nz/contact">
            www.pinkribbonvolunteer.org.nz/contact
          </a>
        </span>
        <span>.</span>
      </p>
    </StaticPage>
  );
};

export default Terms;
