import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";

interface Props {
  bgImageUrl: string;
  amount: string;
  header: string;
  body: any;
}

const WhereYourMoneyGoesCard: React.FC<Props> = ({ bgImageUrl, amount, header, body }) => (
  <div className="panel-wrap">
    <div className="panel editable mb-11">
      <div className="flex justify-center w-full">
        <div
          className="mb-8 w-45 h-45 rounded-full flex items-center justify-center "
          style={{
            backgroundImage: `linear-gradient(to left, rgba(232,84,148,.7), rgba(232,84,148,.7)),
      url("${bgImageUrl}")`,
            backgroundSize: "cover",
          }}
        >
          <p className="text-5xl text-white font-extrabold">{amount}</p>
        </div>
      </div>
      <h3>{header}</h3>
      <p>{documentToReactComponents(body)}</p>
    </div>
  </div>
);

export default WhereYourMoneyGoesCard;
