import React from "react";

export const useHandleMobileMenuClick = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return {
    mobileMenuOpen,
    handleMobileMenuClick: React.useCallback(
      (forceClose = false) => {
        if (!mobileMenuOpen && !forceClose) {
          document.body.classList.add("menu-small-visible");
        } else {
          document.body.classList.remove("menu-small-visible");
        }
        setMobileMenuOpen(!mobileMenuOpen);
      },
      [mobileMenuOpen],
    ),
  };
};
