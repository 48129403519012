import React from "react";
import classNames from "classnames";

interface Props {
  closeModal: () => void;
  open: boolean;
  handleSubmit: (values: any) => void;
  loading: boolean;
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  small?: boolean;
  disabled: boolean;
}

const AddVolunteerButton = ({ setModalOpen, small = true, disabled }: Props) => {
  return (
    <div
      className={classNames("w-full flex items-center h-8 justify-center sm:justify-start", {
        "text-cloudDark cursor-default": disabled,
        "text-flamingo cursor-pointer": !disabled,
      })}
    >
      <div
        onClick={() => !disabled && setModalOpen(true)}
        className={classNames("border-none  border py-2  underline", {
          "text-sm": small,
        })}
      >
        {"Add Volunteer"}
      </div>
    </div>
  );
};

export default AddVolunteerButton;
