import React from "react";
import { FormikValues } from "formik";
import classNames from "classnames";
import CloseIconSmall from "assets/Icons/CloseIconSmall";

interface Props {
  title: string;
  name: string;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FormEvent<HTMLInputElement>) => void;
  values: FormikValues;
  errors: any;
  touched: any;
  classes?: string;
  placeholder?: string;
  prefix?: React.ReactElement;
  type?: string;
  disabled?: boolean;
  requiredField?: boolean;
  height?: string;
  clearFilterButton?: boolean;
  clearFilter?: () => void;
}

const FieldInput = ({
  title,
  name,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  classes = "w-56",
  placeholder,
  prefix,
  type = "text",
  disabled = false,
  requiredField = false,
  height = "h-19",
  clearFilterButton = false,
  clearFilter,
}: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <div className={height}>
      <div className="mb-1">
        {title}
        {requiredField && "*"}
      </div>
      <span
        className={classNames(
          "flex items-center bg-white hover:bg-snow border rounded h-8 text-slate border-stone text-sm",
          classes,
          { "pl-1": prefix },
        )}
      >
        {prefix}
        <input
          style={{ boxShadow: "none" }}
          disabled={disabled}
          placeholder={placeholder}
          type={type !== "password" || showPassword ? "text" : "password"}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          className={classNames(
            "bg-white hover:bg-snow focus:outline-none border-0 outline-none align-middle font-thin h-full w-full rounded",
            { "pl-1": !prefix },
          )}
        />
        {type === "password" ? (
          <div onClick={() => setShowPassword(!showPassword)} className="mx-1 text-xxs cursor-pointer">
            {showPassword ? "Hide" : "Show"}
          </div>
        ) : null}
        {clearFilterButton && (
          <button className="mr-1" onClick={clearFilter}>
            <CloseIconSmall />
          </button>
        )}
      </span>
      <div className="text-xs text-warning w-full sm:w-64">{errors[name]}</div>
    </div>
  );
};

export default FieldInput;
