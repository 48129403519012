import React from "react";

export const useDocumentEvent = (type: any, onEvent: (e: any) => void, options?: AddEventListenerOptions | boolean) => {
  const onEventRef = React.useRef(onEvent);
  onEventRef.current = onEvent;

  React.useEffect(() => {
    const listener = (e: DocumentEventMap[]) => onEventRef.current(e);
    document.addEventListener(type, listener, options);
    return () => document.removeEventListener(type, listener, options);
  }, [type, options]);
};
