import React from "react";
import { flatten } from "lodash";
import classNames from "classnames";
import { RouteComponentProps } from "@reach/router";
import AreaCoordinatorThankyou from "./AreaCoordinatorThankyou";
import ACArea from "./ACArea";
import Roster from "./Roster";
import { useGetShiftsForLocation, useGetACShifts } from "../../Shifts/shiftHooks";
import Loading from "../../Registration/CollectionSites/Loading";
import { updateSelectedLocationId } from "../../Locations/locationSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ACPdfGenerator from "../PDF/ACPdfGenerator";
import { isUserAdmin } from "domains/Person/personSelector";
import StepOne from "domains/Registration/CollectionSites/StepOne/StepOne";
import {
  useCheckIfLocationsAreBlackListed,
  useGetFilteredLocations,
  useGetRegions,
} from "domains/Registration/CollectionSites/collectionSiteHooks";
import { getLocations, getSelectedLocationId } from "domains/Locations/locationSelector";

import CovidCancellationModal from "domains/Registration/CollectionSites/CovidCancellationModal";
import { MultipleHoldProvider } from "./multipleHoldContext";

const AreaCoordinatorPage: React.FC<RouteComponentProps> = ({ path }) => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchDirty, setSearchDirty] = React.useState<boolean>(false);
  const userIsAdmin = useAppSelector(isUserAdmin);

  const { getAndSaveShifts, loadingShifts } = useGetShiftsForLocation();
  //on page load get all allocated area coordinator shift ids. These are irrespective of locations.
  //acAreas are derived from the areaCoordinatorAllocatedShifts
  const { loadingAcAreas, acAreas } = useGetACShifts();

  const [covidModalOpen, setCovidModalOpen] = React.useState(false);

  const acAreaValues = flatten(Object.values(acAreas));

  const checkIfShiftsAreBlacklisted = useCheckIfLocationsAreBlackListed();

  const shiftsAreBlackListed = checkIfShiftsAreBlacklisted({ locations: acAreaValues });

  React.useEffect(() => {
    if (shiftsAreBlackListed) {
      setCovidModalOpen(true);
    }
  }, [shiftsAreBlackListed]);

  const selectedLocationId = useAppSelector(getSelectedLocationId);

  const dispatch = useAppDispatch();

  const handleClick = (locationId: string) => {
    //when the user clicks a location, get all of the shifts associated with that location
    dispatch(updateSelectedLocationId({ locationId }));
    getAndSaveShifts(locationId);
  };

  const collectionSitesRef = React.useRef(null);

  const scrollToCollectionSites = () => {
    if (collectionSitesRef && collectionSitesRef.current) {
      // @ts-ignore
      collectionSitesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const firstAcAreaName = Object.keys(acAreas)[0];

  const firstAcArea = acAreas[firstAcAreaName];

  //automatically open first area
  React.useEffect(() => {
    if (firstAcArea && firstAcArea && !userIsAdmin) {
      dispatch(updateSelectedLocationId({ locationId: firstAcArea[0].id }));
      getAndSaveShifts(firstAcArea[0].id);
    }
  }, [firstAcAreaName]);

  const { locations, loadingLocations } = useAppSelector(getLocations);

  const filteredLocationMap = useGetFilteredLocations(searchValue);

  const { regions } = useGetRegions(filteredLocationMap, loadingLocations, searchDirty, setSearchDirty);

  const handleCollectionSiteSelection = (locationId: string) => {
    getAndSaveShifts(locationId);
    dispatch(updateSelectedLocationId({ locationId }));
    scrollToCollectionSites();
  };

  return (
    <div className="px-2 w-full">
      <CovidCancellationModal open={covidModalOpen} closeModal={() => setCovidModalOpen(false)} />
      {!userIsAdmin && <AreaCoordinatorThankyou />}
      {loadingAcAreas ? (
        <div className="h-100 flex items-center justify-center w-full">
          <Loading text={userIsAdmin ? "Loading shifts..." : "Loading your shifts..."} />
        </div>
      ) : (
        <div>
          <div
            className={classNames("text-flamingo font-bold w-full mb-1 text-center sm:text-left", {
              "mt-0": userIsAdmin,
              "mt-20": !userIsAdmin,
            })}
          >
            {userIsAdmin ? "ALL AREAS" : "YOUR AREAS"}
          </div>
          <div className="flex justify-between items-center relative">
            <div className="w-full">
              {userIsAdmin ? (
                <div className="py-4">
                  {loadingLocations && !Object.keys(locations).length ? (
                    <Loading text={"Loading Locations..."} />
                  ) : (
                    <StepOne
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                      setSearchDirty={setSearchDirty}
                      regions={regions}
                      onClick={handleCollectionSiteSelection}
                      selectedLocationId={selectedLocationId}
                    />
                  )}
                </div>
              ) : (
                Object.keys(acAreas).map((areaName, index) => {
                  const area = acAreas[areaName];
                  return <ACArea key={index} index={index} areaName={areaName} area={area} onClick={handleClick} />;
                })
              )}
            </div>
            <div className="absolute top-0 right-0">{/*<Button text="DOWNLOAD ALL ROSTERS" />*/}</div>
          </div>
          <div ref={collectionSitesRef} className="flex flex-1 items-center mt-20 relative">
            {selectedLocationId && (
              <div className="flex flex-1 justify-between flex-col text-center sm:text-left">
                <div className="w-full flex flex-col sm:flex-row justify-between">
                  <div className="w-full text-flamingo font-bold text-center sm:text-left">ROSTER</div>
                  <div className="sm:w-64">
                    <ACPdfGenerator loadingData={loadingAcAreas || loadingShifts} />
                    {locations[selectedLocationId]?.consentProofLink && (
                      <div className="text-right">
                        <a href={`${locations[selectedLocationId]?.consentProofLink}`} target="_blank" download>
                          Download Consent Proof
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <MultipleHoldProvider>
                  <Roster loadingShifts={loadingShifts} />
                </MultipleHoldProvider>
              </div>
            )}
            <div className="absolute top-0 right-0">{/*<Button text="DOWNLOAD ROSTER" />*/}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AreaCoordinatorPage;
