import React from "react";
import { useLogout } from "../../Account/accountHooks";

const LogoutButton = () => {
  const logout = useLogout();

  return (
    <button
      className="cursor-pointer item link bg-none sm:bg-flamingo text-white py-2 px-3 mr-0 sm:mr-4 text-sm hover:text-candyFloss duration-200 transition-all"
      onClick={logout}
    >
      <div className="">Sign out</div>
    </button>
  );
};

export default LogoutButton;
