import React from "react";
import Cookies from "js-cookie";
import { Api } from "../API/API";
import { useAppDispatch } from "../../hooks";
import { useAddVolunteerToShifts } from "../Shifts/shiftHooks";
import { setSignedInUserId, updatePerson, setLoadingSignedInUser } from "../Person/personSlice";
import { useGetAccessToken, useGetShiftsByUser } from "../Shifts/shiftHooks";
import { ClientCredentials } from "../API/type";
import { toast } from "react-hot-toast";
import { navigate, useLocation } from "@reach/router";
import { useGetLocations } from "../Locations/locationHooks";
import { sha256 } from "js-sha256";
import { Nullable } from "types";
import { stripForwardSlashes } from "domains/utils";
import { AC_REGISTER_PAGE } from "../../constants";

export const useGetUser = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const { getShiftsByUser } = useGetShiftsByUser();

  const { getAuthToken } = useGetAccessToken();

  const getUser = async () => {
    setLoading(true);
    dispatch(setLoadingSignedInUser({ isLoading: true }));
    try {
      const token = await getAuthToken();

      if (!token) {
        return;
      }

      const user = await Api.getCustomer(token);

      if (!user.areaCoordinator) {
        await getShiftsByUser();
      }

      dispatch(updatePerson(user));
      dispatch(setSignedInUserId({ signedInUserId: user.authId }));
    } catch (err) {
      console.log(err);
      if (err === "Network Error") {
        toast.error("Cannot contact server");
      }
    } finally {
      dispatch(setLoadingSignedInUser({ isLoading: false }));
    }
  };

  return {
    getUser: React.useCallback(() => {
      getUser();
    }, []),
    loading,
  };
};

export const useLogout = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(() => {
    Cookies.remove("auth-jwt");
    dispatch({ type: "USER_LOGOUT" });
    navigate("/");
  }, []);
};

export const useAccount = () => {
  const { addShifts, loading: loadingShifts } = useAddVolunteerToShifts();
  const [loading, setLoading] = React.useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState<Nullable<JSX.Element | string>>(null);
  const { getUser } = useGetUser();
  const { getLocationsFromApi } = useGetLocations();
  const [error, setError] = React.useState<Nullable<{ [key: string]: string }>>(null);
  const [forgottenPassword, setForgottenPassword] = React.useState(false);

  useGetUser();

  const resetState = () => {
    setForgottenPassword(false);
    setDisplayMessage("");
  };

  const resetPassword = async (email: string, setForgottenPassword: (forgottenPassword: boolean) => void) => {
    try {
      setLoadingResetPassword(true);
      await Api.resetPassword(email);
      // setForgottenPassword(false);
      setDisplayMessage(
        <div>
          Thanks – we’ve sent you an email, please click on the link to reset your password. If you get stuck, please
          get in touch with the team on{" "}
          <a className="mx-1" href="mailto:pinkribbon@bcf.org.nz">
            pinkribbon@bcf.org.nz
          </a>
          or <a href="tel:0800 902 732">0800&nbsp;902&nbsp;732</a>.
        </div>,
      );
      setError(null);
    } catch (e) {
      setError({ email: "Failed to send email" });
    } finally {
      setLoadingResetPassword(false);
    }
  };

  const dispatch = useAppDispatch();

  const signIn = async (clientCredentials: ClientCredentials) => {
    try {
      setLoading(true);
      const res = await Api.signIn(clientCredentials);

      const { email } = clientCredentials;

      const dataLayer = window?.dataLayer;

      if (dataLayer) {
        dataLayer.push({
          event: "login",
          user_id: sha256(email), // SHA-256 hashed email address (used for cross-device tracking for logged-in users)
        });
      }

      Cookies.set("auth-jwt", res.access_token, { expires: res.expires_in });
      await getUser();
      getLocationsFromApi();

      navigate("/my-shifts");
    } catch (e) {
      console.log(e);
      dispatch(setSignedInUserId({ signedInUserId: null }));
      setDisplayMessage(null);
      setError({ password: "Incorrect email or password" });
    } finally {
      setLoading(false);
    }
  };

  const signInAndAddShifts = async (clientCredentials: ClientCredentials) => {
    await signIn(clientCredentials);
    await addShifts();
  };

  return {
    loading: loading || loadingShifts,
    signInError: error,
    loadingResetPassword,
    displayMessage,
    signIn: React.useCallback((clientCredentials: ClientCredentials) => {
      signIn(clientCredentials);
    }, []),
    signInAndAddShifts,
    resetPassword: React.useCallback((email: string, setForgottenPassword: (forgottenPassword: boolean) => void) => {
      resetPassword(email, setForgottenPassword);
    }, []),
    forgottenPassword,
    setForgottenPassword,
    resetState,
  };
};

export const useIsACRegisterPage = () => {
  const location = useLocation();
  const { pathname } = location;

  return pathname.includes(stripForwardSlashes(AC_REGISTER_PAGE));
};
