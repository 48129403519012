import React from "react";
import LoadingSpinner from "../../UtilComponents/LoadingSpinner";

interface Props {
  text: string;
}

const Loading = ({ text }: Props) => {
  return (
    <div>
      <div className="flex justify-center mb-4">
        <LoadingSpinner />
      </div>
      <div className="flex justify-center text-xs">{text}</div>
    </div>
  );
};

export default Loading;
