import React from "react";
import { orderBy } from "lodash";
import { uniq } from "lodash";

import { RawShiftData } from "../Registration/CollectionSites/types";
import { useAppDispatch } from "../../hooks";
import { Api } from "../API/API";
import { addLocations, updateAcsRequired, updateLoadingLocations } from "./locationSlice";
import { useGetAccessToken, useGetSelectedShifts } from "domains/Shifts/shiftHooks";
import { useSelector } from "react-redux";
import { getLocationsByIds } from "./locationSelector";
import { AppStore } from "rootReducer";
import { Nullable } from "types";

export const useGetLocations = () => {
  const dispatch = useAppDispatch();
  const updateLoadingLocations = useUpdateLoadingLocations();

  const getLocationsFromApi = async () => {
    try {
      useUpdateLoadingLocations;
      updateLoadingLocations(true);
      const locationData = await Api.getLocationsFromApi();
      const orderedLocations = orderBy(
        locationData,
        ["region", "subRegion", "area", "collectionSite"],
        ["asc", "asc", "asc", "asc"],
      );

      let shapedLocationData: { [id: string]: RawShiftData } = {};

      for (const location of orderedLocations) {
        if (location.id) {
          shapedLocationData[location.id] = location;
        }
      }
      dispatch(addLocations(shapedLocationData));
    } catch (err) {
      console.log(err);
    } finally {
      updateLoadingLocations(false);
    }
  };

  return {
    getLocationsFromApi,
  };
};

export const useUpdateLoadingLocations = () => {
  const dispatch = useAppDispatch();

  return React.useCallback((loadingLocations: boolean) => {
    dispatch(updateLoadingLocations({ loadingLocations }));
  }, []);
};

export const useGetIsACRequired = () => {
  const dispatch = useAppDispatch();

  const selectedShifts = useGetSelectedShifts();

  const [isLoading, setIsLoading] = React.useState(false);

  const locationIds = uniq(Object.values(selectedShifts).map((shift) => shift.locationId));

  const [selectedLocations, setSelectedLocations] = React.useState<Nullable<RawShiftData[]>>(null);

  const state = useSelector((state: AppStore) => state);

  const { getAuthToken } = useGetAccessToken();

  const getACsRequired = async (locationIds: string[]) => {
    setIsLoading(true);
    const token = await getAuthToken();

    if (!token) {
      return;
    }
    const data = await Api.getACsRequired(token, locationIds);
    dispatch(updateAcsRequired(data));
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (locationIds.length) {
      getACsRequired(locationIds);
    }
  }, [locationIds.length]);

  React.useEffect(() => {
    if (!isLoading) {
      setSelectedLocations(getLocationsByIds(state, locationIds));
    }
  }, [isLoading]);

  return {
    selectedLocations,
  };
};
