import React from "react";
import { Nullable } from "../../../../types";
import classNames from "classnames";

interface Props {
  title: string;
  onClick: (collectionSiteName: string) => void;
  selectedLocationId: Nullable<string>;
  setDirty: (dirty: boolean) => void;
  dirty: boolean;
  level: number;
  locationId: string;
}

const CollectionSiteButton = ({ title, onClick, selectedLocationId, setDirty, dirty, level, locationId }: Props) => {
  return (
    <div className={"w-full flex justify-center hover:text-coal"}>
      <div
        style={{ paddingLeft: level * 16 }}
        className={classNames("w-full p-2 py-1 cursor-pointer", {
          "bg-flamingo hover:bg-flamingo border-slate text-white": selectedLocationId === locationId,
          "hover:bg-candyFloss": selectedLocationId !== locationId,
        })}
        onClick={() => {
          setDirty(!dirty);
          onClick(locationId);
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default CollectionSiteButton;
