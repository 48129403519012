import React from "react";
import classNames from "classnames";
import { cancelledRegions } from "domains/Registration/CollectionSites/collectionSiteHooks";

interface Props {
  bold?: boolean;
}

const formatCancelledRegions = () => {
  if (cancelledRegions.length < 2) {
    return cancelledRegions.join();
  }
  return cancelledRegions.slice(0, cancelledRegions.length - 1).join(", ") + ", and " + cancelledRegions.slice(-1);
};

const CovidCancellationSection: React.FC<Props> = ({ bold = false }) => (
  <>
    <h2 className="text-flamingo my-6 text-center">
        THE PINK RIBBON STREET APPEAL HAS BEEN CANCELLED IN <span className="underline">AUCKLAND &amp; PARTS OF WAIKATO</span>
    </h2>
    <p className={classNames({ "font-bold": bold })}>
        We’re incredibly disappointed to let you know that the Pink Ribbon Street Appeal has been cancelled in Auckland & parts of the Waikato, due to ongoing covid restrictions.
        For the health and safety of our volunteers and the community, we’re unable to hold the appeal in Level 3.
    </p>
    <p className={classNames({ "font-bold": bold })}>
      This is a devastating blow for Breast Cancer Foundation NZ – in a normal year, the Pink Ribbon Street Appeal helps
      raise around $600,000 for breast cancer education, research and patient support, Auckland and the Waikato make up a big chunk
      of that.
    </p>
    <p className={classNames({ "font-bold": bold })}>
        The appeal will still go ahead in all of the other regions. If you’re in an area that’s been cancelled and still want to help – you can encourage people you know to volunteer by{" "}
      <a
        href="https://www.breastcancerfoundation.org.nz/images/assets/cc79/1/bcf-prsa21-cfv-social-tile-1080px.jpg"
        target="_blank"
      >
        sharing this tile
      </a>{" "}
      on social media, you can{" "}
      <a href="https://www.breastcancerfoundation.org.nz/campaign/street-appeal21" target="_blank">
        donate
      </a>{" "}
      to help, or join us for our{" "}
      <a href="https://pinkribbonwalk.co.nz/virtual" target="_blank">
        virtual Pink Ribbon Walk
      </a>{" "}
      on Sunday 31 October.
    </p>
  </>
);

export default CovidCancellationSection;
