import React from "react";
import { motion } from "framer-motion";
import pluralize from "pluralize";
import VolunteerModalInput from "./VolunteerModalInput";
import CloseIcon from "assets/Icons/CloseIcon";
import classNames from "classnames";
import VolunteerDetailsForm from "./VolunteerDetailsForm";
import { MultipleHoldConsumer } from "./multipleHoldContext";
import { useSelector } from "react-redux";
import { getSignedInUser } from "domains/Person/personSelector";

const volunteerDetailsContent = (
  values: any,
  errors: any,
  handleChange: any,
  onSubmit: (values: any) => void,
  show: boolean,
  resetForm?: () => void,
) => {
  const { selectedShifts, resetState: resetMultipleHoldState, loading } = MultipleHoldConsumer() || {};

  React.useEffect(() => {
    resetForm && resetForm();
  }, [show]);

  return (
    <form className="flex h-full flex-1 justify-center" onSubmit={onSubmit}>
      <button className="absolute top-0 left-0 m-4 group" type="button" onClick={resetMultipleHoldState}>
        <CloseIcon />
      </button>
      <div className="flex justify-center flex-row flex-1 items-start sm:items-center w-2/3 overflow-y-scroll overflow-x-hidden">
        <div className="flex items-center flex-col sm:flex-row">
          <div className="flex flex-1 justify-center">
            <div className="flex flex-col justify-center items-center flex-1 space-x-4 relative ">
              <div className="text-white my-4 text-lg">Hold selected shifts for:</div>
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 px-1 flex flex-col items-center">
                  <VolunteerModalInput
                    name={"firstName"}
                    title="First Name"
                    values={values}
                    errors={errors}
                    disabled={false}
                    handleChange={handleChange}
                    theme="secondary"
                    tabIndex={1}
                  />
                  <div className="w-8" />
                  <VolunteerModalInput
                    name={"email"}
                    title="Email"
                    values={values}
                    errors={errors}
                    disabled={false}
                    handleChange={handleChange}
                    theme="secondary"
                    tabIndex={3}
                  />
                </div>
                <div className="w-64 px-1 flex flex-col items-center">
                  <VolunteerModalInput
                    name={"lastName"}
                    title="Last Name"
                    values={values}
                    errors={errors}
                    disabled={false}
                    handleChange={handleChange}
                    theme="secondary"
                    tabIndex={2}
                  />

                  <VolunteerModalInput
                    name={"mobile"}
                    title="Mobile"
                    values={values}
                    errors={errors}
                    disabled={false}
                    handleChange={handleChange}
                    theme="secondary"
                    tabIndex={4}
                  />
                </div>
                <div className="w-64 px-1 flex flex-col items-center">
                  <VolunteerModalInput
                    name={"organisationName"}
                    title="Organisation"
                    values={values}
                    errors={errors}
                    disabled={false}
                    handleChange={handleChange}
                    theme="secondary"
                    tabIndex={5}
                  />
                  <div className="mt-7">
                    <button
                      className={classNames(
                        "bg-white rounded-full p-2 border w-52 focus:outline-none border-white font-bold text-flamingo hover:shadow mb-4 sm:mb-0",
                        { "opacity-50": false, "hover:bg-flamingo hover:text-white": false },
                      )}
                      type="submit"
                      disabled={loading}
                    >
                      {loading
                        ? "Saving..."
                        : `Confirm hold for ${selectedShifts?.length} ${pluralize("shift", selectedShifts?.length)}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

interface Props {
  show: boolean;
}

const HoldSlotsBanner = ({ show }: Props) => {
  const { selectedShifts, submitMultipleHolds } = MultipleHoldConsumer() || {};

  const user = useSelector(getSignedInUser);

  const handleSubmit = (holdData: {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    organisationName: string;
  }) => {
    submitMultipleHolds && submitMultipleHolds(holdData);
  };

  return (
    <motion.div
      className="fixed bottom-0 left-0 right-0 bg-flamingo"
      initial={{ height: 0 }}
      animate={{ height: show ? 256 : 0 }}
    >
      <VolunteerDetailsForm
        shiftPerson={{}}
        show={show}
        content={volunteerDetailsContent}
        isAreaCoordinator={user?.areaCoordinator || false}
        onSubmit={handleSubmit}
      />
    </motion.div>
  );
};

export default HoldSlotsBanner;
