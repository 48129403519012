// @ts-nocheck
import React from "react";
import AddressInput from "./AddressInput";
import { FormikValues } from "formik";

interface Props {
  setFieldValue: any;
  errors: any;
  editable: boolean;
  values: FormikValues;
}

class AddressDefault extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.widget = null;
    this.addressLine1 = React.createRef();
    this.addressLine2 = React.createRef();
    this.suburb = React.createRef();
    this.city = React.createRef();
    this.postCode = React.createRef();
  }

  loadWidget = () => {
    this.widget = new window.AddressFinder.Widget(
      document.getElementById("addressLine1"),
      "N8CG64DTQFEURP3X9BH7",
      "NZ",
    );
    this?.widget?.on("result:select", (fullAddress, metaData) => {
      const selected = new window.AddressFinder.NZSelectedAddress(fullAddress, metaData);
      this.props.setFieldValue("addressLine1", metaData.address_line_1 ? metaData.address_line_1 : "");
      this.props.setFieldValue("addressLine2", metaData.address_line_2 ? metaData.address_line_2 : "");
      this.props.setFieldValue("suburb", metaData.suburb ? metaData.suburb : "");
      this.props.setFieldValue("city", metaData.city ? metaData.city : "");
      this.props.setFieldValue("postCode", metaData.postcode ? metaData.postcode : "");
      this.addressLine1.current.value = metaData.address_line_1;
      this.addressLine2.current.value = metaData.address_line_2 || "";
      this.suburb.current.value = metaData.selected_suburb;
      this.city.current.value = metaData.selected_city;
      this.postCode.current.value = metaData.postcode;
    });
  };

  componentDidMount() {
    if (this.props.editable) {
      var script = document.createElement("script");
      script.src = "https://api.addressfinder.io/assets/v3/widget.js";
      script.async = true;
      script.onload = this.loadWidget;
      document.body.appendChild(script);
    }
  }

  componentWillUnmount() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = null;
    }
  }

  handleAddressLine1Change = (e) => {
    this.props.setFieldValue("addressLine1", e.target.value);
  };

  handleAddressLine2Change = (e) => {
    this.props.setFieldValue("addressLine2", e.target.value);
  };

  handleSuburbChange = (e) => {
    this.props.setFieldValue("suburb", e.target.value);
  };

  handleCityChange = (e) => {
    this.props.setFieldValue("city", e.target.value);
  };

  handlePostcodeChange = (e) => {
    this.props.setFieldValue("postCode", e.target.value);
  };

  render() {
    return (
      <div className="w-full sm:w-64" method="get">
        <AddressInput
          requiredField={true}
          disabled={!this.props.editable}
          values={this.props.values}
          placeholder="Search address here..."
          errors={this.props.errors}
          name="addressLine1"
          title="Address Line 1"
          inputRef={this.addressLine1}
          onChange={this.handleAddressLine1Change}
        />
        <AddressInput
          disabled={!this.props.editable}
          values={this.props.values}
          errors={this.props.errors}
          name="addressLine2"
          title="Address Line 2"
          inputRef={this.addressLine2}
          onChange={this.handleAddressLine2Change}
        />
        <AddressInput
          disabled={!this.props.editable}
          values={this.props.values}
          errors={this.props.errors}
          name="suburb"
          title="Suburb"
          inputRef={this.suburb}
          onChange={this.handleSuburbChange}
        />
        <AddressInput
          disabled={!this.props.editable}
          values={this.props.values}
          errors={this.props.errors}
          name="city"
          title="City"
          inputRef={this.city}
          onChange={this.handleCityChange}
        />
        <AddressInput
          disabled={!this.props.editable}
          values={this.props.values}
          errors={this.props.errors}
          name="postCode"
          title="Post Code"
          inputRef={this.postCode}
          onChange={this.handlePostcodeChange}
        />
      </div>
    );
  }
}

export default AddressDefault;
