export const domain = "reduxnz.au.auth0.com";
export const clientId = "w4i5n31qDlXhKSkyVuQKNqh3ccFDJsVr";

export const redirectProd = "https://dev.pinkribbonvolunteer.org.nz/my-shifts";
export const redirectLocal = "http://localhost:3000/my-shifts";

export const logoutRedirectProd = "https://dev.pinkribbonvolunteer.org.nz";
export const logoutRedirectLocal = "http://localhost:3000";

export const redirectUri = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    return redirectLocal;
  } else {
    // production code
    return redirectProd;
  }
};

export const logoutRedirectUri = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    return logoutRedirectLocal;
  } else {
    // production code
    return logoutRedirectProd;
  }
};

export const AC_REGISTER_PAGE = "ac-register";
export const REGISTER_PAGE = "register";
export const COLLECTION_SITES_PAGE = "collection-sites";
