import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CampaignConfig, CampaignConfigStore } from "./types";

export const initialState: CampaignConfigStore = {};

const campaignConfigSlice = createSlice({
  name: "campaignConfig",
  initialState,
  reducers: {
    addConfig: (state, action: PayloadAction<CampaignConfig>) => {
      state.config = action.payload;
    },
  },
});

export const { addConfig } = campaignConfigSlice.actions;

export default campaignConfigSlice.reducer;
