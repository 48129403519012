import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { FriendDetails, RemoveAcShift, Shift, ShiftStore } from "./types";
import { AreaCoordinatorDay, ShiftPerson } from "../API/type";
import { sumCounts } from "../Registration/CollectionSites/utils";

export const initialState: ShiftStore = {
  shifts: {},
  selectedShifts: {},
  areaCoordinatorsShiftIds: [],
  areaCoordinatorsShiftDays: {},
  loadingShiftPeople: false,
};

const shiftSlice = createSlice({
  name: "shiftStore",
  initialState,
  reducers: {
    addShifts: (
      state,
      action: PayloadAction<{
        [id: string]: Shift;
      }>,
    ) => {
      state.shifts = {
        ...state.shifts,
        ...Object.keys(action.payload).reduce<{ [shiftId: string]: Shift }>((accum, shiftId) => {
          const newShift = action.payload[shiftId];
          return {
            ...accum,
            [shiftId]: {
              ...state.shifts[shiftId],
              ...accum[shiftId],
              ...newShift,
              people: {
                ...(state.shifts[shiftId]?.people ? state.shifts[shiftId].people : {}),
                ...(accum[shiftId]?.people ? accum[shiftId].people : {}),
                ...newShift.people,
              },
            },
          };
        }, {}),
      };
    },
    updateFriendDetails: (state, action: PayloadAction<{ shiftUuid: string; friendDetails: FriendDetails }>) => {
      const selectedShift = state.selectedShifts[action.payload.shiftUuid];
      state.selectedShifts = {
        ...state.selectedShifts,
        [action.payload.shiftUuid]: {
          ...state.selectedShifts[action.payload.shiftUuid],
          friendDetails: [action.payload.friendDetails],
        },
      };
    },
    updateLoadingShiftPeople: (state, action: PayloadAction<{ loadingShiftPeople: boolean }>) => {
      state.loadingShiftPeople = action.payload.loadingShiftPeople;
    },
    addSelectedShift: (state, action: PayloadAction<Shift>) => {
      const selectedShift = state.selectedShifts[action.payload.uuid];
      state.selectedShifts = {
        ...state.selectedShifts,
        [action.payload.uuid]: { ...action.payload },
      };
    },
    incrementSelectedShift: (state, action: PayloadAction<Shift>) => {
      const selectedShift = state.selectedShifts[action.payload.uuid];
      const currentCountTemp = selectedShift?.countTemp ? selectedShift?.countTemp : 0;

      state.selectedShifts = {
        ...state.selectedShifts,
        [action.payload.uuid]: {
          ...action.payload,
        },
      };
    },
    decrementSelectedShift: (state, action: PayloadAction<Shift>) => {
      const shiftToRemoveByShiftId = Object.values(state.selectedShifts).find(
        (shift) => shift.shiftId === action.payload.shiftId,
      );
      const selectedShift = action.payload.uuid ? state.selectedShifts[action.payload.uuid] : shiftToRemoveByShiftId;

      if (!selectedShift) {
        return;
      }
      if (selectedShift && sumCounts([selectedShift]) === 1) {
        state.selectedShifts = produce(state.selectedShifts, (draft) => {
          delete draft[selectedShift.uuid];
        });
        return;
      }
      state.selectedShifts = {
        ...state.selectedShifts,
        [action.payload.uuid]: {
          ...action.payload,
          countTemp: selectedShift.countTemp ? selectedShift.countTemp - 1 : -1,
        },
      };
    },
    removeSelectedShift: (state, action: PayloadAction<{ uuid: string }>) => {
      const selectedShift = state.selectedShifts[action.payload.uuid];
      state.selectedShifts = produce(state.selectedShifts, (draft) => {
        delete draft[action.payload.uuid];
      });
    },
    removeACShift: (state, action: PayloadAction<{ hourUuid: string; shiftId: string }>) => {
      state.shifts = produce(state.shifts, (draft) => {
        const people = draft[action.payload.shiftId].people;

        const updatedPeople = Object.keys(people).reduce((accum, shiftPersonId) => {
          const shiftPerson = people[shiftPersonId];
          if (shiftPerson.uuid === action.payload.hourUuid) {
            return accum;
          }
          return { ...accum, [shiftPerson.uuid]: shiftPerson };
        }, {});

        draft[action.payload.shiftId].people = updatedPeople;
      });
    },
    removeHeldFromShift: (state, action: PayloadAction<{ shiftId: string; hourUuid: string }>) => {
      state.shifts = {
        ...state.shifts,
        [action.payload.shiftId]: {
          ...state.shifts[action.payload.shiftId],
          people: {
            ...state.shifts[action.payload.shiftId].people,
            [action.payload.hourUuid]: {
              ...state.shifts[action.payload.shiftId].people[action.payload.hourUuid],
              held: false,
            },
          },
        },
      };
    },
    addPersonToShift: (state, action: PayloadAction<ShiftPerson>) => {
      state.shifts = {
        ...state.shifts,
        [action.payload.shiftId]: {
          ...state.shifts[action.payload.shiftId],
          people: { ...state.shifts[action.payload.shiftId].people, [action.payload.uuid]: action.payload },
        },
      };
    },
    updateAreaCoordinatorShiftDays: (state, action: PayloadAction<{ [shiftId: string]: AreaCoordinatorDay[] }>) => {
      state.areaCoordinatorsShiftDays = action.payload;
    },
    updateAreaCoordinatorsShiftIds: (state, action: PayloadAction<string[]>) => {
      state.areaCoordinatorsShiftIds = action.payload;
    },
    addPeopleToShift: (state, action: PayloadAction<ShiftPerson[]>) => {
      const shifts = action.payload.reduce<{ [id: string]: Shift }>((accum, shiftPerson) => {
        const existingShift = state.shifts[shiftPerson.shiftId];
        return {
          ...accum,
          [shiftPerson.shiftId]: {
            ...existingShift,
            people: {
              ...(existingShift?.people ? existingShift.people : {}),
              ...(accum[shiftPerson.shiftId]?.people ? accum[shiftPerson.shiftId]?.people : {}),
              [shiftPerson.uuid]: shiftPerson,
            },
          },
        };
      }, {});

      state.shifts = {
        ...state.shifts,
        ...shifts,
      };
    },
    toggleMultipleHoldChecked: (state, action: PayloadAction<{ shiftUuid: string }>) => {
      state.shifts = {
        ...state.shifts,
        [action.payload.shiftUuid]: {
          ...state.shifts[action.payload.shiftUuid],
          multipleHoldChecked: !state.shifts[action.payload.shiftUuid].multipleHoldChecked,
        },
      };
    },
  },
});

export const {
  addShifts,
  incrementSelectedShift,
  addSelectedShift,
  decrementSelectedShift,
  removeSelectedShift,
  removeACShift,
  addPersonToShift,
  addPeopleToShift,
  updateAreaCoordinatorsShiftIds,
  updateAreaCoordinatorShiftDays,
  updateLoadingShiftPeople,
  updateFriendDetails,
  toggleMultipleHoldChecked,
  removeHeldFromShift,
} = shiftSlice.actions;

export default shiftSlice.reducer;
