import React, { Fragment } from "react";
import classNames from "classnames";
import { ShiftPerson } from "domains/API/type";
import { Menu, Transition } from "@headlessui/react";
import DropDownItem from "domains/Shared/DropDownItem";

interface Props {
  shiftPerson: ShiftPerson;
  cancelOnly: boolean;
  setCancelShiftModalOpen: (open: boolean) => void;
  setConfirmHeldShiftModal: (open: boolean) => void;
  regionCancelled: boolean;
  disabled?: boolean;
}

const ShiftDropDown: React.FC<Props> = ({
  shiftPerson,
  cancelOnly,
  setCancelShiftModalOpen,
  setConfirmHeldShiftModal,
  regionCancelled,
  disabled = false,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full py-1 text-sm font-medium text-pebble hover:text-slate rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            />
          </svg>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {!regionCancelled && (
            <div className={classNames("py-1", { "text-cloudDark": disabled })}>
              <DropDownItem handleClick={() => !disabled && setCancelShiftModalOpen(true)} displayText={"Remove"} />
            </div>
          )}
          {shiftPerson.held && (
            <div className={classNames("py-1", { "text-cloudDark": disabled })}>
              <DropDownItem handleClick={() => !disabled && setConfirmHeldShiftModal(true)} displayText={"Confirm"} />
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ShiftDropDown;
