import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="">
      <div className="links">
        <div className="link">
          <a href="/contact">Contact Us</a>
        </div>

        <div className="link">
          <a href="https://www.breastcancerfoundation.org.nz/privacy-policy" target="_blank" rel="noopener">
            Privacy policy
          </a>
        </div>

        <div className="link">
          <a href="/terms">Terms &amp; Conditions</a>
        </div>
      </div>

      <div className="spacer"></div>

      <div className="copyright">
        <div className="platforms">
          <span>&copy; 2022. </span>

          <a href="https://www.breastcancerfoundation.org.nz/" target="_blank" rel="noopener">
            Breast Cancer Foundation New Zealand
          </a>
          <br />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
