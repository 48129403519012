import { getUserIsAC } from "domains/Person/personSelector";
import { useShiftHasFriendAdded } from "domains/Shifts/shiftHooks";
import React from "react";
import { useSelector } from "react-redux";
import ModifyShiftModal from "./ModifyShiftModal";

interface Props {
  closeModal: () => void;
  open: boolean;
  handleConfirm: () => void;
  loading: boolean;
  shiftId: string;
}

const CancelShiftModal: React.FC<Props> = ({ closeModal, open, handleConfirm, loading, shiftId }) => {
  const shiftHasFriendAdded = useShiftHasFriendAdded();
  const [shiftHasFriend, setShiftHasFriend] = React.useState(false);

  const userIsAC = useSelector(getUserIsAC);

  React.useEffect(() => {
    setShiftHasFriend(shiftHasFriendAdded(shiftId));
  }, [open]);

  const additionalText = () => {
    if (userIsAC) {
      return "If you haven’t spoken with this volunteer, please get in touch to let them know that you’re cancelling their collection shift before you remove them from the system. Do you want to proceed?";
    }
    if (shiftHasFriend) {
      return "This will cancel your shift, but not your friends. If they want to cancel their shift, they’ll need to log in to their account to make changes. Are you sure you want to cancel your shift?";
    }
    return "";
  };

  return (
    <ModifyShiftModal
      loading={loading}
      handleConfirm={handleConfirm}
      closeModal={closeModal}
      open={open}
      text={
        userIsAC ? "Are you sure you want to remove this volunteer?" : "Are you sure you want to cancel this shift?"
      }
      additionalText={additionalText()}
    />
  );
};

export default CancelShiftModal;
