import React, { Dispatch, SetStateAction } from "react";
import { CollectionTimeSlot, RawShiftData, Region } from "./types";
import { Nullable } from "../../../types";
import { getRegions } from "./utils";
import { generateTimestamp, useGetOrderedSelectedShifts } from "../../Shifts/shiftHooks";
import moment from "moment";
import { Shift } from "../../Shifts/types";
import { AppStore } from "rootReducer";
import { useSelector } from "react-redux";
import { getLocations, getLocationsByIds } from "domains/Locations/locationSelector";
import { isUserAdmin } from "domains/Person/personSelector";

interface Props {
  timeSlots: { [collectionSiteName: string]: CollectionTimeSlot };
}

export const useGetRegions = (
  locations: Nullable<RawShiftData[]>,
  loadingLocations: boolean,
  searchDirty: boolean,
  setSearchDirty?: Dispatch<SetStateAction<boolean>>,
) => {
  const [regions, setRegions] = React.useState<{ [regionName: string]: Region }>({});

  React.useEffect(() => {
    if (locations) {
      const regionData = getRegions({ locations });
      setRegions(regionData);
      setSearchDirty && setSearchDirty(false);
    }
  }, [loadingLocations, searchDirty, setSearchDirty]);

  return {
    regions,
  };
};

export const useGetFilteredLocations = (searchValue: string) => {
  const { locations } = useSelector(getLocations);

  const locationMap = Object.values(locations);

  const filteredLocations = React.useCallback(() => {
    return locationMap.filter(
      (location) =>
        location.area?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        location.region?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        location.subRegion?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        location.collectionSite?.toLowerCase()?.includes(searchValue?.toLowerCase()),
    );
  }, [locationMap.length, searchValue]);

  return filteredLocations();
};

export const useOverlappingShiftsCheck = () => {
  const orderedSelectedShifts = useGetOrderedSelectedShifts();

  return {
    checkOverlapping: React.useCallback(
      (shiftToCheck: Shift) => {
        let overlapping = false;
        const timestamp = generateTimestamp(shiftToCheck);
        orderedSelectedShifts.reduce<number[]>((accum, shift) => {
          if (!shift.duration) {
            return accum;
          }
          const currentTimestamp = generateTimestamp(shift);
          const overlap = moment(timestamp)
            .clone()
            .isBetween(
              moment(currentTimestamp).clone().subtract(1, "second"),
              moment(currentTimestamp).clone().add(shift.duration, "hours"),
            );
          if (overlap) {
            if (shiftToCheck.shiftId !== shift.shiftId) {
              overlapping = true;
            }
          }
          return accum;
        }, []);
        return overlapping;
      },
      [orderedSelectedShifts],
    ),
  };
};

export const useCheckCollectionSiteCancelled = () => {
  const userIsAdmin = useSelector(isUserAdmin);

  return React.useCallback(
    (location: Nullable<RawShiftData>) => {
      if (userIsAdmin) {
        return false;
      }
      return location?.cancelOnly === true;
    },
    [location, userIsAdmin],
  );
};

export const cancelledRegions: Array<string | undefined> = [""].map((region) => region.toLowerCase());
export const cancelledSubRegions: Array<string | undefined> = [""].map((subRegion) => subRegion.toLowerCase());
export const cancelledAreas: Array<string | undefined> = [""].map((area) => area.toLowerCase());

interface CheckCovidRegionalCancellationProps {
  location?: Nullable<RawShiftData>;
  regionName?: string;
  subRegionName?: string;
  areaName?: string;
}

export const useCheckIfLocationBlacklisted = () => {
  const userIsAdmin = useSelector(isUserAdmin);
  return React.useCallback(
    ({
      location,
      handleCancelled,
      regionName,
      subRegionName,
      areaName,
    }: CheckCovidRegionalCancellationProps & { handleCancelled?: () => void }) => {
      if (userIsAdmin) {
        return false;
      }
      if (location) {
        if (
          cancelledRegions.includes(location?.region?.toLowerCase()) ||
          cancelledSubRegions.includes(location?.subRegion?.toLowerCase()) ||
          cancelledAreas.includes(location?.area?.toLowerCase())
        ) {
          handleCancelled && handleCancelled();
          return true;
        }
      }
      if (regionName) {
        if (cancelledRegions.includes(regionName.toLowerCase())) {
          handleCancelled && handleCancelled();
          return true;
        }
      }
      if (subRegionName) {
        if (cancelledSubRegions.includes(subRegionName.toLowerCase())) {
          handleCancelled && handleCancelled();
          return true;
        }
      }
      if (areaName) {
        if (cancelledAreas.includes(areaName.toLowerCase())) {
          handleCancelled && handleCancelled();
          return true;
        }
      }
    },
    [cancelledRegions, userIsAdmin],
  );
};

export const useCheckIfLocationsBlacklisted = () => {
  const checkCovidCancellation = useCheckIfLocationBlacklisted();
  return React.useCallback(
    (locationsData: CheckCovidRegionalCancellationProps[], handleCancelled?: () => void) => {
      let containsBlacklistedName = false;

      for (const locationData of locationsData) {
        if (checkCovidCancellation(locationData)) {
          containsBlacklistedName = true;
        }
      }
      if (containsBlacklistedName) {
        handleCancelled && handleCancelled();
      }
      return containsBlacklistedName;
    },
    [checkCovidCancellation],
  );
};

interface checkIfLocationsAreBlackListed {
  locations: RawShiftData[];
}

export const useCheckIfLocationsAreBlackListed = () => {
  const checkCovidCancellation = useCheckIfLocationBlacklisted();
  return React.useCallback(
    ({ locations }: checkIfLocationsAreBlackListed) => {
      let containsBlacklistedShift = false;
      for (const location of locations) {
        if (checkCovidCancellation({ location })) {
          containsBlacklistedShift = true;
        }
      }
      return containsBlacklistedShift;
    },
    [checkCovidCancellation],
  );
};

export const useCheckIfShiftsAreBlacklisted = (orderedSelectedShifts: Shift[]) => {
  const checkIfLocationsAreBlackListed = useCheckIfLocationsAreBlackListed();

  const locations = useSelector((state: AppStore) =>
    getLocationsByIds(
      state,
      orderedSelectedShifts.map((shift) => shift.locationId),
    ),
  );

  return React.useCallback(() => {
    return checkIfLocationsAreBlackListed({ locations });
  }, [locations, checkIfLocationsAreBlackListed]);
};
