import { AppStore } from "../../rootReducer";
import { Shift } from "./types";

export const getSelectedShifts = (state: AppStore) => {
  return state.shiftStore.selectedShifts;
};

export const getShiftById = (state: AppStore, shiftId: string) => {
  const shifts = state.shiftStore.shifts;
  const shift = shifts[shiftId];
  const shiftDays = state.shiftStore.areaCoordinatorsShiftDays[shiftId];
  return { ...shift, days: shiftDays };
};

export const getLoadingShiftPeople = (state: AppStore) => {
  return state.shiftStore.loadingShiftPeople;
};

export const getShiftsByIds = (state: AppStore, shiftIds: string[]) => {
  const shifts = state.shiftStore.shifts;
  return shiftIds.reduce<Shift[]>((accum, shiftId) => {
    if (!shifts[shiftId]) {
      return accum;
    }
    return [...accum, shifts[shiftId]];
  }, []);
};

export const getShifts = (state: AppStore) => {
  return state.shiftStore.shifts;
};

export const getAreaCoordinatorsShiftIds = (state: AppStore) => {
  const selectedLocationId = state.locationStore.selectedLocationId;
  const shifts = state.shiftStore.shifts;
  return state.shiftStore.areaCoordinatorsShiftIds.filter((shiftId) => {
    const shift = shifts[shiftId];
    if (!shift || shift.locationId !== selectedLocationId) {
      return false;
    }
    return true;
  }, []);
};
