import axios from "axios";
import {
  ACShiftsApi,
  AddShiftPersonApi,
  ApiShift,
  ClientCredentials,
  CreateUserApi,
  GetShiftPersonAPI,
  LocationNotes,
  LoggedInUser,
  LoginResponse,
  ReturnedShiftPersonApi,
  ShiftPerson,
} from "./type";
import { RawShiftData } from "../Registration/CollectionSites/types";
import { Shift } from "../Shifts/types";
import { navigate } from "@reach/router";
import Cookies from "js-cookie";
import { CampaignConfig } from "domains/CampaignConfig/types";

const baseUrl = () => {
  const envMode = process.env["REACT_APP_ENV_MODE"];
  switch (envMode) {
    case "PROD":
      return "https://api.pinkribbonvolunteer.org.nz";
    case "DEV":
      return "https://api-dev.pinkribbonvolunteer.org.nz";
    default:
      return "http://localhost:9000";
  }
};

const baseCmsUrl = "https://cdn.contentful.com";

const integromatTimeSlots =
  process.env["REACT_APP_ENV_MODE"] === "PROD"
    ? "https://hook.integromat.com/okvhsmzcgyddmkmihqi2t2b4cbd8gdj5"
    : "https://hook.integromat.com/oizqkc4ik0xdaqjtz3h2upytqtiywmnl";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error?.response?.data?.statusCode === 401) {
        Cookies.remove("auth-jwt");
        navigate("/sign-in");
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  },
);

export const Api = {
  createCustomer: async (user: CreateUserApi): Promise<any> => {
    const response = await axios.post(`${baseUrl()}/user`, user);
    return response.data;
  },
  createACCustomer: async (user: CreateUserApi): Promise<any> => {
    const response = await axios.post(`${baseUrl()}/user/ac`, user);
    return response.data;
  },
  getCustomer: async (token: string): Promise<LoggedInUser> => {
    const response = await axios.get(`${baseUrl()}/user`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  getLocationsFromApi: async (): Promise<RawShiftData[]> => {
    const response = await axios.get(`${baseUrl()}/locations`);
    return response.data;
  },

  checkEmail: async (email: string, shifts: Shift[]): Promise<boolean> => {
    const response = await axios.post(`${baseUrl()}/user/check`, { email, shifts });
    return response.data;
  },

  getTimeslots: async (locationId: string): Promise<Shift[]> => {
    const response = await axios.get(`${baseUrl()}/shifts/locations/${locationId}`);
    // const response = await axios.get(`${integromatTimeSlots}?locationId=${locationId}`);
    return response.data;
  },

  getACsRequired: async (token: string, locationIds: string[]): Promise<{ sfid: string; acsRequired: boolean }[]> => {
    const response = await axios.post(
      `${baseUrl()}/jobs/acneeded`,
      { locationIds },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  getShiftsByUser: async (token: string): Promise<ApiShift[]> => {
    const response = await axios.get(`${baseUrl()}/shifts`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  getACShiftsByUser: async (token: string): Promise<ACShiftsApi[]> => {
    const response = await axios.get(`${baseUrl()}/shifts/ac-shifts`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  getLocationsByIds: async (token: string, locationIds: string[]): Promise<LocationNotes[]> => {
    const response = await axios.post(
      `${baseUrl()}/locations/notes`,
      { locationIds },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  getShiftPeople: async (token: string, shiftIds: string[]): Promise<GetShiftPersonAPI[]> => {
    const response = await axios.post(
      `${baseUrl()}/shifts/shift-people`,
      { shiftIds },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  areaCoordinatorAddPersonToShift: async (
    token: string,
    shiftPeople: AddShiftPersonApi,
  ): Promise<ReturnedShiftPersonApi[]> => {
    const response = await axios.post(`${baseUrl()}/shifts/add`, shiftPeople, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  addVolunteerToShift: async (token: string, shifts: Shift[]): Promise<void> => {
    const response = await axios.post(`${baseUrl()}/shifts/volunteers/add`, shifts, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  deleteShift: async (token: string, shiftUuid: string): Promise<void> => {
    const response = await axios.delete(`${baseUrl()}/shifts/${shiftUuid}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  confirmHeldShift: async (token: string, shiftUuid: string): Promise<void> => {
    const response = await axios.put(
      `${baseUrl()}/shifts/${shiftUuid}/held/confirm`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },
  signIn: async (clientCredentials: ClientCredentials): Promise<LoginResponse> => {
    const response = await axios.post(`${baseUrl()}/login/`, clientCredentials);
    return response.data;
  },

  resetPassword: async (email: string): Promise<void> => {
    const response = await axios.post(`${baseUrl()}/reset-password`, { email });
    return response.data;
  },

  addFriendsDetailsToShift: async (token: string, shiftPerson: ShiftPerson): Promise<void> => {
    const response = await axios.put(
      `${baseUrl()}/shifts/${shiftPerson.uuid}/friend`,
      {
        mobile: shiftPerson.mobile,
        firstName: shiftPerson.firstName,
        lastName: shiftPerson.lastName,
        email: shiftPerson.email,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  },

  getCmsData: async (): Promise<void> => {
    const response = await axios.get(
      `${baseCmsUrl}/spaces/m3mohq960ejq/environments/master/entries?access_token=${process.env["REACT_APP_CMS_ACCESS_TOKEN"]}`,
    );
    return response.data;
  },

  getCampaignConfig: async (): Promise<CampaignConfig> => {
    const response = await axios.get(`${baseUrl()}/app-config`);
    return response.data;
  },
};
