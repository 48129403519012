import moment from "moment";
import { useSelector } from "react-redux";
import { AppStore } from "rootReducer";

export const getCampaignConfig = (state: AppStore) => state.campaignConfigStore.config;

export const campaignEnded = (state: AppStore) => {
  const { campaignEndDate } = getCampaignConfig(state) || {};
  const timeStamp = campaignEndDate && new Date(campaignEndDate).valueOf();
  return moment(Date.now()).diff(timeStamp, "hours") >= 0;
};

export const campaignStarted = (state: AppStore) => {
  const { campaignStartDate } = getCampaignConfig(state) || {};
  const timeStamp = campaignStartDate && new Date(campaignStartDate).valueOf();
  return moment(Date.now()).diff(timeStamp, "hours") >= 0;
};

export const getCampaignState = (state: AppStore) => {
  if (campaignEnded(state)) {
    return "campaignEnded";
  } else if (campaignStarted(state) && !campaignEnded(state)) {
    return "campaignRunning";
  }
  return "campaignLeadup";
};

export const campaignIsRunning = (state: AppStore) => getCampaignState(state) === "campaignRunning";
