import React from "react";
import { Nullable } from "types";
import { navigate, RouteComponentProps } from "@reach/router";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { CMSConsumer } from "../Cms/cmsContext";
import research1 from "../../assets/Images/how-helping.jpg";
import getInvolved from "../../assets/Images/become-ac.jpg";
import prbWebTitle from "../../assets/Images/volunteer-faq.jpg";

import { getEntryBySlugId } from "domains/Cms/cmsSelectors";
import CtaBanner from "./CtaBanner";
import { useHandleRegistration } from "domains/Registration/registrationHooks";
import HomeHeader from "./HomeHeader";
import { getCampaignState } from "domains/CampaignConfig/campaignConfigSelectors";
import { useSelector } from "react-redux";

const Home: React.FC<RouteComponentProps> = () => {
  const { state } = CMSConsumer() || {};

  const ctaBannerRef = React.useRef<Nullable<HTMLDivElement>>(null);

  const campaignState = useSelector(getCampaignState);

  const handleRegisterClick = useHandleRegistration();

  if (!state) {
    return null;
  }

  const cmsData = getEntryBySlugId && getEntryBySlugId(state, "HomePage");

  React.useEffect(() => {
    /* curator-feed-pinkribbonnz */
    const scriptTag = document.createElement("script");

    scriptTag.src = "https://cdn.curator.io/published/ab0d5802-96dd-47c9-a8eb-c2a52cb923ba.js";
    scriptTag.async = true;

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div className="content-wrap">
      <div className="h-160 flex flex-col justify-between">
        <div
          className="h-64 sm:h-100 md:h-160 bg-no-repeat w-full p-x0 p-y0 sm:pb-28 flex items-end"
          //linear-gradient(77.02deg, rgba(26, 8, 8, 0.9) 0%, rgba(26, 8, 8, 0) 66.67%
          style={{
            backgroundImage: `url("${cmsData[`${campaignState}HeroImage`]?.fields?.file?.url}")`,
            backgroundSize: "cover",
          }}
        >
          <div className="hidden md:flex">
            <HomeHeader onClick={() => handleRegisterClick(ctaBannerRef)} />
          </div>
        </div>
        <div className="bg-flamingo flex flex-grow md:hidden">
          <HomeHeader onClick={() => handleRegisterClick(ctaBannerRef)} />
        </div>
      </div>

      <section className="content-home-wrap">
        <div className="intro">
          <div className="row flex justify-center">
            <div className="px-4">
              <div className="content-center">
                <>{documentToReactComponents(cmsData[`${campaignState}FirstContent`])}</>
              </div>
            </div>
          </div>
          <section className="pt-16">
            <div className="col-12 col-md-12 flex justify-center">
              <div className="flex items-center max-w-4xl">
                <h1 className="text-center" />
                <div className="call-actions flex justify-center">
                  <div className="action-wrap">
                    <a className="action" onClick={() => navigate("/becoming-an-area-coordinator")}>
                      <div className="image">
                        <img src={getInvolved} />
                      </div>
                      <div className="content">
                        <h2>Become an Area Coordinator</h2>
                        <p>
                          Area Coordinators manage a group of volunteers and are vital to the success of the appeal – in
                          fact we couldn’t run it without them!
                        </p>
                      </div>
                      <div className="link">
                        <span>
                          Find out more <i className="fas fa-chevron-right fa-fw" />
                        </span>
                      </div>
                    </a>
                  </div>

                  <div className="action-wrap">
                    <a className="action" onClick={() => navigate("/whereyourmoneygoes")}>
                      <div className="image">
                        <img src={research1} />
                      </div>
                      <div className="content">
                        <h2>How you're helping</h2>
                        <p>
                          Find out more about the incredible research projects you’re helping fund, the support services
                          available, and our work in awareness and education.
                        </p>
                      </div>
                      <div className="link">
                        <span>
                          Check it out <i className="fas fa-chevron-right fa-fw"></i>
                        </span>
                      </div>
                    </a>
                  </div>

                  <div className="action-wrap">
                    <a className="action" onClick={() => navigate("/faq")}>
                      <div className="image">
                        <img src={prbWebTitle} />
                      </div>
                      <div className="content">
                        <h2>Volunteer FAQ</h2>
                        <p>
                          Are you wondering how it works on the day of the appeal? Not sure what to bring? Check out our
                          most frequently asked questions.
                        </p>
                      </div>
                      <div className="link">
                        <span>
                          Go on then <i className="fas fa-chevron-right fa-fw"></i>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <CtaBanner ctaRef={ctaBannerRef} handleRegistration={handleRegisterClick} />

      <div id="instagram-feed">
        <div className="heading text-center">
          <h2>#pinkribbonNZ</h2>
          <p>
            Be a part of our community on Instagram. Use the hashtag above if you would like your photo featured below.
          </p>
        </div>
      </div>
      <div id="curator-feed-pinkribbonnz" />
    </div>
  );
};

export default Home;
