import React from "react";
import classNames from "classnames";

interface Props {
  checked: boolean;
  className?: string;
  onClick: any;
  children?: JSX.Element | string;
  disabled?: boolean;
}

const Checkbox = ({ children, checked, onClick, className, disabled = false }: Props) => {
  return (
    <label className="flex items-center h-8">
      <input
        type="checkbox"
        disabled={disabled}
        className={classNames(
          "form-checkbox h-4 w-4 text-slate focus:ring-transparent border border-stone",
          className,
          { "cursor-pointer": !disabled, "cursor-default": disabled },
        )}
        checked={checked}
        onChange={onClick}
      />
      {children}
    </label>
  );
};

export default Checkbox;
