import React from "react";
import { formatTimeAs12Hour } from "../../Registration/CollectionSites/utils";
import RenderShiftPeople from "./RenderShiftPeople";
import { columnOneDate, columnTwoDate, dateToDay, getShift } from "./AreaCoordinatorHelpers";
import { Shift } from "../../Shifts/types";

interface Props {
  shift: Shift;
  allocatedShiftsFromStore: Shift[];
  loadingShiftPeople: boolean;
}

const TimeSlotRow = ({ shift, allocatedShiftsFromStore, loadingShiftPeople }: Props) => {
  return (
    <tr className="h-32 border-b hidden sm:table-row">
      <td className="w-40">
        <div className="mr-2">{`${formatTimeAs12Hour(shift.timeSlotStart)} - ${formatTimeAs12Hour(
          shift.timeSlotEnd,
        )}`}</div>
      </td>
      <td>
        <div className="flex items-center">
          <RenderShiftPeople
            columnDay={dateToDay[columnOneDate]}
            shift={getShift(columnOneDate, shift.timeSlotStart, allocatedShiftsFromStore, shift.duration)}
            loadingShiftPeople={loadingShiftPeople}
          />
        </div>
      </td>
      <td>
        <div className="flex items-center">
          <RenderShiftPeople
            columnDay={dateToDay[columnTwoDate]}
            shift={getShift(columnTwoDate, shift.timeSlotStart, allocatedShiftsFromStore, shift.duration)}
            loadingShiftPeople={loadingShiftPeople}
          />
        </div>
      </td>
    </tr>
  );
};

export default TimeSlotRow;
