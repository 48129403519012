import React from "react";

interface Props {
  direction: "up" | "left" | "right" | "down";
}

const ArrowIcon = ({ direction = "up" }: Props) => {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      transform={`rotate(${direction === "up" ? 0 : direction === "down" ? 180 : direction === "right" ? 90 : 270})`}
    >
      <path d="M4.5 0L8.39711 6.75L0.602886 6.75L4.5 0Z" className="text-slate fill-current" />
    </svg>
  );
};

export default ArrowIcon;
