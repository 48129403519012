import React from "react";
import { RouteComponentProps } from "@reach/router";
import Title from "../Registration/Common/Title";
import RegistrationNavigator from "../Registration/Common/RegistrationNavigator";
import StepThree from "../Registration/CollectionSites/StepThree";
import { redirectUri } from "../../constants";
import { useGetAccessToken } from "../Shifts/shiftHooks";

const Account: React.FC<RouteComponentProps> = ({ path }) => {
  const { getAuthToken, token } = useGetAccessToken();

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="flex justify-center flex-1 flex-col">
      <Title text="YOUR SHIFTS" />
      <div className="flex flex-1 justify-center bg-white">
        <div className="w-full sm:w-2/3">
          <RegistrationNavigator />
          <div className="w-full  px-2 pb-24 text-slate">
            <div className="w-full pb-20">
              <div className="flex justify-center my-10">
                <p className="text-center">Thanks for signing up.</p>
              </div>
              <StepThree confirmation={true} />
            </div>

            <div className="flex justify-center my-2">
              <p>Sign in to edit your shifts</p>
            </div>
            <div className="flex justify-center">
              <button
                onClick={getAuthToken}
                className="rounded-full p-4 border w-64 focus:outline-none border-flamingo font-bold text-flamingo hover:bg-snow hover:shadow"
              >
                ADD MORE SHIFTS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
