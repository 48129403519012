import React from "react";

interface Props {
  text: string;
}

const Title = ({ text }: Props) => {
  return (
    <div className="w-full h-25 flex text-3xl justify-center items-center bg-lightPink text-flamingo font-bold uppercase">
      <div>{text}</div>
    </div>
  );
};

export default Title;
