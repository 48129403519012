import React from "react";
import { Nullable } from "../../../types";
import ShiftCounter from "./ShiftCounter";
import { useGetSelectedShiftById, useGetSelectedShifts, useGetShiftsByLocation } from "../../Shifts/shiftHooks";
import { formatTimeAs12Hour, getTempCount, sumCounts } from "./utils";
import { useSelector } from "react-redux";
import { getSelectedLocation } from "../../Locations/locationSelector";
import { createTimeSlotEndTimeMap, getShift } from "../../SignedIn/AreaCoordinator/AreaCoordinatorHelpers";
import { Shift } from "domains/Shifts/types";
import VolunteerTimeSlotRow from "./VolunteerTimeSlotRow";
import { collectionDayOne, collectionDayTwo } from "config";

interface Props {
  selectedLocationId: Nullable<string>;
  stepTwoRef: React.RefObject<HTMLInputElement>;
  handleShiftSelection: () => void;
}

const columnOneDate = collectionDayOne;
const columnTwoDate = collectionDayTwo;

const StepTwo = ({ selectedLocationId, stepTwoRef, handleShiftSelection }: Props) => {
  const getShiftsByLocation = useGetShiftsByLocation();
  const selectedLocationShifts = getShiftsByLocation(selectedLocationId);

  const selectedShifts = useGetSelectedShifts();

  if (!selectedLocationId) {
    return null;
  }

  const selectedLocation = useSelector(getSelectedLocation);

  const { uniqueTimeSlots, timeSlotMap } = createTimeSlotEndTimeMap(selectedLocationShifts);

  return (
    <div className="text-sm hidden sm:block">
      <div className="text-flamingo text-lg mb-4 font-bold">STEP TWO: Pick a date and time that suits</div>
      <div className="flex">
        <div className="font-bold pr-1 pb-3">Location:</div>
        <div>{selectedLocation?.collectionSite}</div>
      </div>
      <table className="w-full">
        <tbody>
          <tr>
            <th className="w-40">Time</th>
            <th className="px-1">{`Fri 28 October 2022 Shifts`}</th>
            <th className="px-1">{`Sat 29 October 2022 Shifts`}</th>
          </tr>
          {uniqueTimeSlots.map((timeSlotWithDuration, index) => {
            return (
              <tr key={index} className="h-16">
                <td className="w-64">
                  <div className="mr-2">{`${formatTimeAs12Hour(
                    timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
                  )} – ${formatTimeAs12Hour(timeSlotMap[timeSlotWithDuration]?.timeSlotEnd)}`}</div>
                </td>
                <VolunteerTimeSlotRow
                  date={columnOneDate}
                  timeSlotWithDuration={timeSlotWithDuration}
                  selectedLocationShifts={selectedLocationShifts}
                  timeSlotMap={timeSlotMap}
                  handleShiftSelection={handleShiftSelection}
                />
                <VolunteerTimeSlotRow
                  date={columnTwoDate}
                  timeSlotWithDuration={timeSlotWithDuration}
                  selectedLocationShifts={selectedLocationShifts}
                  timeSlotMap={timeSlotMap}
                  handleShiftSelection={handleShiftSelection}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StepTwo;
