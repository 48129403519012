import React from "react";
import * as contentful from "contentful";
import { getSlugIds } from "./cmsSelectors";

interface CmsContextInterface {
  slugIds: string[];
  loading: boolean;
  cmsEntries: () => void;
  state?: CmsState;
}

const initialState: CmsContextInterface = {
  loading: true,
  cmsEntries: () => {},
  slugIds: [],
};

const CmsContext = React.createContext<CmsContextInterface>(initialState);

export interface CmsState {
  loading: boolean;
  cmsEntries: any;
  slugIds: string[];
}

interface CMSEntry {
  slug: "string";
}

interface CMSEntries {
  slugId: CMSEntry;
}

export const useCMS = () => {
  const [state, setState] = React.useState<CmsState>(initialState);

  const { loading, cmsEntries, slugIds } = state;

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const client = contentful.createClient({
      space: `${process.env["REACT_APP_SPACE_ID"]}`,
      accessToken: `${process.env["REACT_APP_CMS_ACCESS_TOKEN"]}`,
      environment: `${process.env["REACT_APP_CMS_ENVIRONMENT"]}`,
    });

    client.getEntries().then((entries: any) => {
      const rawCMSEntries = entries.items.reduce((accum: CMSEntries, item: any) => {
        return { ...accum, [item.fields.slug]: item.fields };
      }, {});
      setState((prevState) => ({
        ...prevState,
        loading: false,
        cmsEntries: rawCMSEntries,
        slugIds: Object.keys(rawCMSEntries),
      }));
    });
  }, []);

  return {
    slugIds: getSlugIds(state),
    loading,
    cmsEntries,
    state,
  };
};

export const CMSProvider = ({ children }: any) => {
  const { loading, slugIds, cmsEntries, state } = useCMS();

  return (
    <CmsContext.Provider
      value={{
        loading,
        slugIds,
        cmsEntries,
        state,
      }}
    >
      {children}
    </CmsContext.Provider>
  );
};

export const CMSConsumer = () => {
  return React.useContext(CmsContext);
};
