import React from "react";
import Modal from "react-modal";
import VolunteerModalInput from "./VolunteerModalInput";
import ButtonWithLoader from "../../UtilComponents/ButtonWithLoader";

import CloseIcon from "../../../assets/Icons/CloseIcon";

import { useRemoveACShift } from "domains/Shifts/shiftHooks";
import { ShiftPerson } from "domains/API/type";
import { customStyles } from "domains/Modal/config";
import VolunteerDetailsForm from "./VolunteerDetailsForm";
import { useAppSelector } from "hooks";
import { isUserAdmin } from "domains/Person/personSelector";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface Props {
  closeModal: () => void;
  open: boolean;
  handleSubmit: (values: any) => void;
  loading: boolean;
  shiftPerson: Partial<ShiftPerson>;
  setModalContent: (modalContent: Partial<ShiftPerson>) => void;
  description: string;
  isAreaCoordinator: boolean;
}

const AddVolunteerModal = ({
  isAreaCoordinator,
  loading,
  closeModal,
  open,
  handleSubmit,
  shiftPerson,
  setModalContent,
  description,
}: Props) => {
  function afterOpenModal() {}

  const { loading: loadingDelete, deleteShift } = useRemoveACShift(closeModal, setModalContent);

  const disabled = !shiftPerson || (shiftPerson && !!Object.keys(shiftPerson).length);

  const userIsAdmin = useAppSelector(isUserAdmin);

  const handleDeleteClick = () => {
    if (shiftPerson && shiftPerson.uuid && shiftPerson.shiftId) {
      deleteShift(shiftPerson.uuid, shiftPerson.shiftId, !!shiftPerson.synced);
    } else {
      console.log("No shift person");
    }
  };

  const volunteerDetailsContent = (values: any, errors: any, handleChange: any, onSubmit: (values: any) => void) => (
    <form className="flex h-full flex-1 justify-center" onSubmit={onSubmit}>
      <div className="w-full px-1">
        <VolunteerModalInput
          name={"firstName"}
          title="First Name"
          values={values}
          errors={errors}
          disabled={disabled}
          handleChange={handleChange}
        />
        <div className="w-8" />
        <VolunteerModalInput
          name={"lastName"}
          title="Last Name"
          values={values}
          errors={errors}
          disabled={disabled}
          handleChange={handleChange}
        />
        <VolunteerModalInput
          name={"email"}
          title="Email"
          values={values}
          errors={errors}
          disabled={disabled}
          handleChange={handleChange}
        />
        <VolunteerModalInput
          name={"mobile"}
          title="Mobile"
          values={values}
          errors={errors}
          disabled={disabled}
          handleChange={handleChange}
        />
        {!disabled && (
          <div className="mt-4 flex justify-center w-full">
            <div className="w-32">
              <ButtonWithLoader classes="h-10 w-32" loading={loading} type="submit" text="SAVE" />
            </div>
          </div>
        )}
        {userIsAdmin && disabled && (
          <button
            disabled={loadingDelete}
            onClick={handleDeleteClick}
            type="button"
            className="bg-danger hover:bg-dangerDark mt-4 h-8 w-full text-white flex justify-center items-center font-bold rounded"
          >
            <div>{loadingDelete ? "Removing Volunteer from Shift..." : "Remove Volunteer from Shift"}</div>
          </button>
        )}
      </div>
    </form>
  );

  return (
    <div>
      <Modal
        isOpen={open}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Volunteers Modal"
      >
        <div className="w-84 sm:w-90 p-2">
          <div className="w-full h-10 relative">
            <h2 className="w-full text-flamingo my-2 text-center">
              {disabled ? "Volunteer Details" : "Add a Volunteer"}
            </h2>
            <button className="absolute top-0 right-0 group" onClick={closeModal}>
              <CloseIcon />
            </button>
          </div>
          {description && <div className="my-2 text-center">{description}</div>}
          <div>
            <VolunteerDetailsForm
              show={true}
              shiftPerson={shiftPerson}
              isAreaCoordinator={isAreaCoordinator}
              onSubmit={handleSubmit}
              content={volunteerDetailsContent}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddVolunteerModal;
