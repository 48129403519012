import React from "react";
import Footer from "./Footer";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import LoadingSpinner from "domains/UtilComponents/LoadingSpinner";
import { pagesToRedirect } from "config";
import { stripForwardSlashes } from "domains/utils";
import { getCampaignState } from "domains/CampaignConfig/campaignConfigSelectors";
import { useSelector } from "react-redux";
import { useGetCampaignConfig } from "domains/CampaignConfig/campaignConfigHooks";

interface Props extends RouteComponentProps {
  children: any; //JSX.Element[] | JSX.Element | null;
  backPath?: string;
  loading: boolean;
}

const ViewerWindow: React.FC<Props> = ({ children, loading }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const campaignState = useSelector(getCampaignState);

  const { loading: configLoading } = useGetCampaignConfig();

  React.useEffect(() => {
    if (
      !configLoading &&
      campaignState !== "campaignRunning" &&
      pagesToRedirect.includes(stripForwardSlashes(pathname))
    ) {
      navigate("/");
    }
  }, [pathname, pagesToRedirect, configLoading]);

  if (loading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-1 flex-col justify-between">
      <div className="container flex-1">
        <div className="flex flex-1 flex-col footer-space">
          {children && React.Children.map(children, (child: any) => React.cloneElement(child))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ViewerWindow;
