import React from "react";
import classNames from "classnames";

interface Props {
  disabled?: boolean;
}

const Plus = ({ disabled = false }: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2232 2.76151C10.3309 -0.100028 5.65394 -0.100028 2.76163 2.76151C-0.0999061 5.65382 -0.0999061 10.3307 2.76163 13.223C5.65394 16.1154 10.3309 16.1154 13.1924 13.223C16.0847 10.3307 16.0847 5.65382 13.2232 2.76151ZM12.9155 8.60766C12.9155 8.79228 12.7924 8.91536 12.6078 8.91536H9.22317C9.06933 8.91536 8.91548 9.0692 8.91548 9.22305V12.6077C8.91548 12.7615 8.76163 12.9154 8.60779 12.9154H7.37702C7.1924 12.9154 7.06932 12.7923 7.06932 12.6077V9.22305C7.06932 9.03843 6.94625 8.91536 6.76163 8.91536H3.37702C3.1924 8.91536 3.06932 8.79228 3.06932 8.60766V7.3769C3.06932 7.22305 3.22317 7.0692 3.37702 7.0692H6.76163C6.94625 7.0692 7.06932 6.94613 7.06932 6.76151V3.37689C7.06932 3.22305 7.22317 3.0692 7.37702 3.0692H8.60779C8.76163 3.0692 8.91548 3.19228 8.91548 3.37689V6.76151C8.91548 6.94613 9.03856 7.0692 9.22317 7.0692H12.6078C12.7616 7.0692 12.9155 7.22305 12.9155 7.3769V8.60766Z"
        className={classNames("fill-current", { "text-cloudDark": disabled, "text-jumbo": !disabled })}
      />
    </svg>
  );
};

export default Plus;
