import React, { Dispatch, SetStateAction } from "react";
import { useGenerateAccordion } from "./stepOneHooks";
import { Region } from "../types";
import { Nullable } from "../../../../types";
import FieldInput from "domains/Registration/PersonalDetails/FieldInput";
import { getLocations } from "domains/Locations/locationSelector";
import { useSelector } from "react-redux";

interface Props {
  regions: { [regionName: string]: Region };
  onClick: (locationId: string) => void;
  selectedLocationId: Nullable<string>;
  header?: string;
  onFolderClick?: (tree: Tree) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setSearchDirty: Dispatch<SetStateAction<boolean>>;
}

export interface Tree {
  expanded: boolean;
  key: string;
  title: string;
  content: Tree[];
  regionName: string;
  subRegionName?: string;
  areaName?: string;
  locationId?: string;
}

const StepOne = ({
  regions,
  onClick,
  selectedLocationId,
  header,
  onFolderClick,
  searchValue,
  setSearchValue,
  setSearchDirty,
}: Props) => {
  const [tree, setTree] = React.useState<Tree[]>([]);

  const regionNames = Object.keys(regions);

  const { loadingLocations } = useSelector(getLocations);

  React.useEffect(() => {
    const originalTree = regionNames.map((regionName) => {
      const region = regions[regionName];

      return {
        expanded: false,
        key: regionName,
        title: regionName,
        regionName,
        content: Object.keys(region.subRegions).map((subRegionName) => {
          const subRegion = region.subRegions[subRegionName];
          return {
            expanded: false,
            key: subRegionName,
            title: subRegionName,
            regionName,
            subRegionName,
            content: Object.keys(subRegion.areas).map((areaName) => {
              const area = subRegion.areas[areaName];
              return {
                expanded: false,
                key: areaName,
                title: areaName,
                regionName,
                subRegionName,
                areaName,
                content: Object.keys(area.collectionSites).map((collectionSiteName) => {
                  return {
                    expanded: false,
                    key: collectionSiteName,
                    title: collectionSiteName,
                    subRegionName,
                    regionName,
                    areaName,
                    locationId: area.collectionSites[collectionSiteName].locationId,
                    visible: area.collectionSites[collectionSiteName].visible,
                    status: area.collectionSites[collectionSiteName].status,
                    cancelOnly: area.collectionSites[collectionSiteName].cancelOnly,
                    content: [],
                  };
                }),
              };
            }),
          };
        }),
      };
    });
    setTree(originalTree);
  }, [regions]);

  const generateAccordion = useGenerateAccordion({ tree, onClick, selectedLocationId, onFolderClick });

  const accordion = generateAccordion();

  if (!tree) {
    return null;
  }

  return (
    <div>
      {header && (
        <div className="text-flamingo text-lg mb-4 font-bold mb-8 w-full flex justify-center sm:justify-start">
          {header}
        </div>
      )}
      <div className="px-2 sm:px-0 w-full sm:w-64">
        <FieldInput
          placeholder="Search for an area"
          name="search"
          title={""}
          errors={{}}
          handleBlur={() => {}}
          touched={() => {}}
          height="h-12"
          handleChange={(e: any) => {
            setSearchDirty(true);
            setSearchValue(e.target.value);
          }}
          values={{ search: searchValue }}
          classes="w-full sm:w-56"
          clearFilterButton={searchValue !== ""}
          clearFilter={() => {
            setSearchValue("");
            setSearchDirty(true);
          }}
        />
      </div>
      {!loadingLocations && !accordion.length && (
        <p className="sm:px-0 px-2 text-warning text-sm">No results. Please try another filter.</p>
      )}
      <div className="flex flex-col text-slate">{accordion}</div>
    </div>
  );
};

export default StepOne;
