import React from "react";
import HoldSlotsBanner from "./HoldSlotsBanner";
import { useSelector } from "react-redux";
import { getLocationById, getSelectedLocationId } from "../../Locations/locationSelector";
import RosterShifts from "./RosterShifts";
import { AppStore } from "../../../rootReducer";
import { getAreaCoordinatorsShiftIds, getShiftsByIds } from "../../Shifts/shiftSelector";
import Loading from "../../Registration/CollectionSites/Loading";
import { useCheckCollectionSiteCancelled } from "domains/Registration/CollectionSites/collectionSiteHooks";
import { MultipleHoldConsumer, MultipleHoldProvider } from "./multipleHoldContext";
import Switch from "domains/Shared/Switch";
import { getSignedInUser } from "domains/Person/personSelector";

interface Props {
  loadingShifts: boolean;
}

const Roster = ({ loadingShifts }: Props) => {
  const selectedLocationId = useSelector(getSelectedLocationId);
  const selectedLocation = useSelector((state: AppStore) => getLocationById(state, selectedLocationId));
  const {
    multipleHoldChecked,
    selectedShifts,
    toggleMultipleHoldChecked,
    resetState: resetMultipleHoldState,
  } = MultipleHoldConsumer() || {};

  const areaCoordinatorShiftIds = useSelector(getAreaCoordinatorsShiftIds);

  const getCheckCollectionSiteCancelled = useCheckCollectionSiteCancelled();

  const user = useSelector(getSignedInUser);

  const cancelOnly = getCheckCollectionSiteCancelled(selectedLocation);

  React.useEffect(() => {
    resetMultipleHoldState && resetMultipleHoldState();
  }, [selectedLocationId]);

  if (loadingShifts && areaCoordinatorShiftIds.length === 0) {
    return (
      <div className="mt-32 items-center justify-center flex flex-1">
        <div>
          <Loading text="Loading your shifts..." />
        </div>
      </div>
    );
  }

  const showMultipleHoldSwitch = user?.roles.includes("admin") || user?.areaCoordinator;

  return (
    <div className="text-sm flex flex-1 flex-col">
      <div className="flex flex-1 justify-center sm:justify-start flex-col sm:flex-row pt-3 pb-3 sm:pb-1">
        <div className="font-bold pr-1">Location:</div>
        <div>{selectedLocation?.collectionSite}</div>
      </div>
      <div className="flex flex-1 justify-center sm:justify-start flex-col sm:flex-row pb-3 sm:pb-1">
        <div className="font-bold pr-1 whitespace-normal sm:whitespace-nowrap">Location notes:</div>
        <div>{selectedLocation?.siteNotes}</div>
      </div>
      {showMultipleHoldSwitch && (
        <div className="flex justify-center sm:justify-start flex-col sm:flex-row pb-0 sm:pb-3">
          <div className="font-bold pr-1 whitespace-normal sm:whitespace-nowrap flex items-center">
            <div className="mr-2">Reserve Shifts:</div>
            <Switch checked={multipleHoldChecked || false} toggleChecked={toggleMultipleHoldChecked} />
          </div>
        </div>
      )}

      <div className="text-flamingo">
        {cancelOnly && (
          <>
            <span className="font-bold">Alert:</span> Unfortunately, this site has been cancelled for 2022. Let us know
            if we can help with anything!
          </>
        )}
      </div>
      <div className="flex flex-1">
        <table className="w-full mt-2 sm:mt-10">
          <tbody>
            <tr className="hidden sm:table-row">
              <th className="w-40">Time</th>
              <th className="w-40">{`Fri 28 October 2022 Shifts`}</th>
              <th className="w-40">{`Sat 29 October 2022 Shifts`}</th>
            </tr>
            {selectedLocationId ? (
              <RosterShifts allocatedShiftsIds={areaCoordinatorShiftIds} loadingShifts={loadingShifts} />
            ) : null}
          </tbody>
        </table>
      </div>
      {<HoldSlotsBanner show={(multipleHoldChecked && !!selectedShifts?.length) || false} />}
    </div>
  );
};

export default Roster;
