import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Person, PersonStore } from "./types";
import { Nullable } from "../../types";

export const initialState: PersonStore = {
  persons: {},
  signedInUserId: null,
  loadingSignedInUser: true,
};

const personSlice = createSlice({
  name: "personStore",
  initialState,
  reducers: {
    setLoadingSignedInUser: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.loadingSignedInUser = action.payload.isLoading;
    },
    updatePerson: (state, action: PayloadAction<Person>) => {
      state.persons = {
        ...state.persons,
        [action.payload.authId]: { ...state.persons[action.payload.authId], ...action.payload },
      };
    },
    setSignedInUserId: (state, action: PayloadAction<{ signedInUserId: Nullable<string> }>) => {
      state.signedInUserId = action.payload.signedInUserId;
    },
  },
});

export const { updatePerson, setSignedInUserId, setLoadingSignedInUser } = personSlice.actions;

export default personSlice.reducer;
