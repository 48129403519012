import React from "react";
import classNames from "classnames";
import { Shift } from "domains/Shifts/types";
import { getShift } from "domains/SignedIn/AreaCoordinator/AreaCoordinatorHelpers";
import ShiftCounter from "./ShiftCounter";
import { getShiftsAvailable, getShiftsRemaining, isExpired } from "./utils";
import moment from "moment";

interface Props {
  date: string;
  timeSlotWithDuration: string;
  selectedLocationShifts: Shift[];
  timeSlotMap: { [timeslotWithDuration: string]: Shift };
  handleShiftSelection: () => void;
}

const VolunteerTimeSlotRow: React.FC<Props> = ({
  date,
  timeSlotWithDuration,
  selectedLocationShifts,
  timeSlotMap,
  handleShiftSelection,
}) => {
  const shift = getShift(
    date,
    timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
    selectedLocationShifts,
    timeSlotMap[timeSlotWithDuration].duration,
  );

  const columnTimeStamp = moment(`${date}`, "DD/MM/yyyy").clone().valueOf();

  const expired = isExpired(columnTimeStamp);
  return (
    <td>
      <div className={classNames("flex mx-1")}>
        <div className={classNames("flex items-center mr-2 text-center", { "w-32": expired, "w-16": !expired })}>
          {expired ? (
            <div>Registration Closed</div>
          ) : (
            `${getShiftsRemaining(
              date,
              timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
              selectedLocationShifts,
              timeSlotMap[timeSlotWithDuration].duration,
            )} available`
          )}
        </div>
        {!expired && (
          <ShiftCounter
            shiftsAvailable={getShiftsAvailable(
              date,
              timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
              selectedLocationShifts,
              timeSlotMap[timeSlotWithDuration].duration,
            )}
            shiftsRemaining={getShiftsRemaining(
              date,
              timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
              selectedLocationShifts,
              timeSlotMap[timeSlotWithDuration].duration,
            )}
            shift={shift}
            handleShiftSelection={handleShiftSelection}
          />
        )}
      </div>
    </td>
  );
};

export default VolunteerTimeSlotRow;
