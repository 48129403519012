import React from "react";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "./modalSlice";
import { Modal } from "./modalTypes";

export const useShowModal = () => {
  const dispatch = useDispatch();
  return React.useCallback(
    (modal: Modal) => {
      console.log({ modal });
      dispatch(showModal(modal));
    },
    [dispatch],
  );
};

export const useHideModal = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);
};
