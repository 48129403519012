import React from "react";
import classNames from "classnames";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";
import { getSignedInUser } from "../Person/personSelector";

interface Props {
  handleMobileMenuClick: (mobileMenuOpen?: boolean) => void;
  mobileMenuOpen: boolean;
}

const MobileMenu = ({ mobileMenuOpen, handleMobileMenuClick }: Props) => {
  const navigateToPage = (nextPage: string) => {
    navigate(nextPage);
    handleMobileMenuClick();
  };
  const user = useSelector(getSignedInUser);
  return (
    <div className={classNames("site-nav-slide-out", { "fadeslide-right-show": mobileMenuOpen })}>
      <ul className="site-nav-list">
        {user && (
          <li className="item">
            <a onClick={() => navigateToPage("/my-shifts")} title="About">
              My Account
            </a>
          </li>
        )}
        <li className="item">
          <a onClick={() => navigateToPage("/about")} title="About">
            About
          </a>
        </li>
        <li className="item">
          <a onClick={() => navigateToPage("/about-volunteering")}>About Volunteering</a>
        </li>
        <li className="item">
          <a onClick={() => navigateToPage("/becoming-an-area-coordinator")}>Becoming an Area Coordinator</a>
        </li>
        <li className="item separator">
          <a onClick={() => navigateToPage("/faq")}>FAQ</a>
        </li>
        <li className="item">
          <a onClick={() => navigateToPage("/whereyourmoneygoes")}>How you're helping</a>
        </li>
        <li className="item">
          <a onClick={() => navigateToPage("/contact")}>Contact Us</a>
        </li>
      </ul>
      <div className="spacer" />
    </div>
  );
};

export default MobileMenu;
