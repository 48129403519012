import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";

interface Props {
  title: string;
  subTitle: string;
  body: any;
  srcUrl: string;
  linkUrl: string;
  additionalClasses: string;
}

const WhereYourMoneyGoesBanner: React.FC<Props> = ({ title, subTitle, body, srcUrl, additionalClasses, linkUrl }) => {
  return (
    <section className={`content-body-wrap ${additionalClasses}`}>
      <div className="row px-2 sm:px-28 justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="project-spotlight sm-flex-rev  editable">
            <div className="spotlight-content">
              <h5>{title}</h5>
              <h2>{subTitle}</h2>
              <p>{documentToReactComponents(body)}</p>
              <button onClick={() => window.open(linkUrl, "_blank")} className="btn btn-white">
                Learn More
              </button>
            </div>
            <div className="spotlight-asset rounded flex justify-center sm:inline-block">
              <img src={srcUrl} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhereYourMoneyGoesBanner;
