import React from "react";
import { RouteComponentProps } from "@reach/router";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import whereMoneyGoes from "../../assets/Images/where-money-goes-figure.svg";
import badgeEducation from "../../assets/Images/badge_education.svg";
import badgeSupport from "../../assets/Images/badge_support.svg";
import badgeResearch from "../../assets/Images/badge_research.svg";
import { getEntryBySlugId } from "domains/Cms/cmsSelectors";
import { CMSConsumer } from "domains/Cms/cmsContext";
import WhereYourMoneyGoesBanner from "./WhereYourMoneyGoesBanner";
import WhereYourMoneyGoesCard from "./WhereYourMoneyGoesCard";

const WhereYourMoneyGoes: React.FC<RouteComponentProps> = ({ path }) => {
  const { state } = CMSConsumer() || {};

  if (!state) {
    return null;
  }
  const cmsData = getEntryBySlugId && getEntryBySlugId(state, "where_your_money_goes");

  console.log(cmsData.howYourVolunteeringHelpsCol3BgImage);

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="content-wrap">
      <div className="content-heading">
        <h1>
          <span className="editable">{"WHERE YOUR MONEY GOES"}</span>
        </h1>
      </div>
      <section className="content-body-wrap pb-3">
        <div className="row px-2 sm:px-28 justify-content-center">
          <div className="col-12 col-lg-10 editable py-10">
            <p className="mb-20">{documentToReactComponents(cmsData?.intro)}</p>
            <h2 className="text-center">{cmsData?.subTitle}</h2>
            <p className="px-16">
              <img src={whereMoneyGoes} />
            </p>
            <p>
              <br />
            </p>
            <p className="">{documentToReactComponents(cmsData?.bodyText)}</p>
          </div>
        </div>
        <div className="row px-2 sm:px-28 justify-content-center">
          <div className="col-12 col-lg-10">
            <h2 className="editable text-center pt-8">{cmsData?.threeColHeader}</h2>
            <div className="panel-3-list">
              <div className="panel-wrap">
                <div className="panel icon editable">
                  <p>
                    <img src={badgeResearch} title="Research" />
                  </p>
                  <h3>{cmsData?.column1Header}</h3>
                  <p>{documentToReactComponents(cmsData?.column1Text)}</p>
                </div>
              </div>
              <div className="panel-wrap">
                <div className="panel icon editable">
                  <p>
                    <img src={badgeEducation} title="Education" />
                  </p>
                  <h3>{cmsData?.column2Header}</h3>
                  <p>{documentToReactComponents(cmsData?.column2Text)}</p>
                </div>
              </div>
              <div className="panel-wrap">
                <div className="panel icon editable">
                  <p>
                    <img src={badgeSupport} title="Support" />
                  </p>
                  <h3>{cmsData?.column3Header}</h3>
                  <p>{documentToReactComponents(cmsData?.column3Text)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhereYourMoneyGoesBanner
        title={cmsData?.block1Title}
        subTitle={cmsData?.block1SubTitle}
        body={cmsData?.block1Body}
        linkUrl={cmsData?.block1Url}
        srcUrl={cmsData?.block1Image?.fields?.file?.url}
        additionalClasses={"panel-light notched"}
      />

      <WhereYourMoneyGoesBanner
        title={cmsData?.block2Title}
        subTitle={cmsData?.block2SubTitle}
        body={cmsData?.block2Body}
        linkUrl={cmsData?.block2Url}
        srcUrl={cmsData?.block2Image?.fields?.file?.url}
        additionalClasses={"panel-pink"}
      />

      <WhereYourMoneyGoesBanner
        title={cmsData?.block3Title}
        subTitle={cmsData?.block3SubTitle}
        body={cmsData?.block3Body}
        linkUrl={cmsData?.block3Url}
        srcUrl={cmsData?.block3Image?.fields?.file?.url}
        additionalClasses={"panel-dark"}
      />

      <section className="content-body-wrap">
        <div className="row px-2 sm:px-none justify-content-center mb-4">
          <div className="w-full editable">
            <h2 className="text-center">{cmsData?.howYourVolunteeringHelpsHeader}</h2>
            <p className="text-center">{cmsData?.howYourVolunteeringHelpsDescription}</p>
          </div>
        </div>
        <div className="row px-2 sm:px-28 justify-content-center">
          <div className="col-12 col-lg-10 editable">
            <div className="panel-3-list donations">
              <WhereYourMoneyGoesCard
                bgImageUrl={cmsData.howYourVolunteeringHelpsCol1BgImage?.fields?.file?.url}
                amount={cmsData?.howYourVolunteeringHelpsCol1Amount}
                header={cmsData?.howYourVolunteeringHelpsCol1Header}
                body={cmsData?.howYourVolunteeringHelpsCol1Text}
              />
              <WhereYourMoneyGoesCard
                bgImageUrl={cmsData.howYourVolunteeringHelpsCol2BgImage?.fields?.file?.url}
                amount={cmsData?.howYourVolunteeringHelpsCol2Amount}
                header={cmsData?.howYourVolunteeringHelpsCol2Header}
                body={cmsData?.howYourVolunteeringHelpsCol2Text}
              />
              <WhereYourMoneyGoesCard
                bgImageUrl={cmsData.howYourVolunteeringHelpsCol3BgImage?.fields?.file?.url}
                amount={cmsData?.howYourVolunteeringHelpsCol3Amount}
                header={cmsData?.howYourVolunteeringHelpsCol3Header}
                body={cmsData?.howYourVolunteeringHelpsCol3Text}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="content-body-wrap panel-light">
        <div className="row px-2 sm:px-28 justify-content-center">
          <div className="w-full editable">
            <h2 className="text-center">
              <br></br>Make a difference
            </h2>
            <p className="text-center">
              The funds you raise will help us continue our vital work and bring us one step closer to our vision of
              zero deaths from breast cancer.
              <br />
            </p>
            <p className="text-center pt-4">
              <a
                className="btn btn-pink"
                title="Donate"
                href="https://www.breastcancerfoundation.org.nz/campaign/street-appeal22"
                target="_blank"
              >
                Donate
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhereYourMoneyGoes;
