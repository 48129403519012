import React from "react";
import "tailwindcss/tailwind.css";
import { Router } from "@reach/router";
import "./assets/App.css";
import ViewerWindow from "./domains/Viewer/ViewerWindow";
import CollectionSites from "./domains/Registration/CollectionSites/CollectionSites";
import "react-datepicker/dist/react-datepicker.css";
import Register from "./domains/Registration/PersonalDetails/Register";
import Header from "./domains/Viewer/Header/Header";
import SubHeader from "./domains/SubHeader/SubHeader";
import WhereYourMoneyGoes from "./domains/StaticPages/WhereYourMoneyGoes";
import Contact from "./domains/StaticPages/Contact";
import Terms from "./domains/StaticPages/Terms";
import Home from "./domains/Home/Home";
import MyDetails from "./domains/SignedIn/MyDetails";
import { Toaster } from "react-hot-toast";
import Account from "./domains/Account/Account";
import MyShifts from "./domains/SignedIn/MyShifts";
import { useGetUser } from "./domains/Account/accountHooks";
import { useGetLocations } from "./domains/Locations/locationHooks";
import SignInPage from "./domains/Account/SignInPage";
import MobileMenu from "./domains/SubHeader/MobileMenu";
import { useHandleMobileMenuClick } from "./domains/SubHeader/mobileMenuHooks";
import { CMSConsumer, CMSProvider } from "domains/Cms/cmsContext";
import GenericPage from "domains/StaticPages/GenericPage";
import { removePrefix } from "domains/Cms/cmsSelectors";
import ModalContainer from "domains/Modal/ModalContainer";
import { useGetCampaignConfig } from "domains/CampaignConfig/campaignConfigHooks";

const App: React.FC = () => {
  const { getLocationsFromApi } = useGetLocations();
  const { getUser } = useGetUser();
  const { slugIds, loading: cmsLoading } = CMSConsumer() || {};

  const { loading: configLoading } = useGetCampaignConfig();

  React.useEffect(() => {
    getLocationsFromApi();
    getUser();
  }, []);

  const { handleMobileMenuClick, mobileMenuOpen } = useHandleMobileMenuClick();

  return (
    <>
      <ModalContainer />
      <Toaster />
      <Header handleMobileMenuClick={handleMobileMenuClick} />
      <SubHeader handleMobileMenuClick={handleMobileMenuClick} />
      <MobileMenu handleMobileMenuClick={handleMobileMenuClick} mobileMenuOpen={mobileMenuOpen} />

      <Router className="bg-candyFloss flex flex-1" primary={false}>
        <ViewerWindow path="/" loading={cmsLoading || configLoading}>
          {slugIds
            ? slugIds.map((slugId) => <GenericPage key={slugId} slugId={slugId} path={removePrefix(slugId)} />)
            : null}
          <SignInPage path="/sign-in" showTitle={true} />
          <Home path="/" />
          <CollectionSites path="/collection-sites" />
          <Register path="/register" />
          <Register path="/ac-register/" />
          <Account path="/account" />
          <WhereYourMoneyGoes path="/whereyourmoneygoes" />
          <Terms path="/terms" />
          <CollectionSites path="/find-shifts" />
          <MyDetails path="/my-details" />
          <MyShifts path="/my-shifts" />
        </ViewerWindow>
      </Router>
    </>
  );
};

export default App;
