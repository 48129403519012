import { countBy, uniq } from "lodash";
import { Api } from "domains/API/API";
import { useGetAccessToken } from "domains/Shifts/shiftHooks";
import { getShifts } from "domains/Shifts/shiftSelector";
import React from "react";
import { useSelector } from "react-redux";
import { useGetShiftPeople } from "./ShiftPeopleHooks";
import toast from "react-hot-toast";

interface MultipleHoldContextInterface {
  selectedShifts: { uuid: string; index: number }[];
  multipleHoldChecked: boolean;
  toggleMultipleHoldChecked: () => void;
  addSelectedShiftUuid: (shiftUuid: string, index: number) => void;
  removeSelectedShiftUuid: (shiftUuid: string, index: number) => void;
  isChecked: (shiftUuid: string, index: number) => boolean;
  resetState: () => void;
  submitMultipleHolds: (values: any) => void;
  shiftUuidCount: (shiftUuid: string) => number;
  loading: boolean;
}

const MultipleHoldContext = React.createContext<MultipleHoldContextInterface | null>(null);

const initialState = {
  selectedShifts: [],
  multipleHoldChecked: false,
  loading: false,
};

interface multipleHoldState {
  selectedShifts: { uuid: string; index: number }[];
  multipleHoldChecked: boolean;
  loading: boolean;
}

export const useMultipleHold = () => {
  const [{ selectedShifts, multipleHoldChecked, loading }, setState] = React.useState<multipleHoldState>(initialState);

  const { getShiftPeopleFromApi } = useGetShiftPeople();
  const shifts = useSelector(getShifts);

  const { getAuthToken } = useGetAccessToken();

  const resetState = () => {
    setState(initialState);
  };

  const shiftUuidCount = (selectedShiftUuid: string) => {
    const counts = countBy(selectedShifts);
    return counts[selectedShiftUuid] || 0;
  };

  const removeSelectedShiftUuid = (selectedShiftUuid: string, index: number) => {
    //remove shift
    const indexToRemove = selectedShifts.reduce<undefined | number>((accum, shift, currentIndex) => {
      if (shift.uuid === selectedShiftUuid && shift.index === index) {
        return currentIndex;
      }
      return accum;
    }, undefined);

    indexToRemove !== undefined && selectedShifts.splice(indexToRemove, 1),
      setState((prevState) => ({
        ...prevState,
        selectedShifts,
      }));
  };

  const addSelectedShiftUuid = (selectedShiftUuid: string, index: number) => {
    //add shift
    setState((prevState) => ({
      ...prevState,
      selectedShifts: [...selectedShifts, { uuid: selectedShiftUuid, index }],
    }));
  };

  const isChecked = (shiftUuid: string, index: number) => {
    const shiftPosition = selectedShifts.reduce<undefined | number>((accum, shift, currentIndex) => {
      if (shift.uuid === shiftUuid && shift.index === index) {
        return currentIndex;
      }
      return accum;
    }, undefined);

    return shiftPosition !== undefined;
  };

  const toggleMultipleHoldChecked = () => {
    setState((prevState) => ({
      ...prevState,
      multipleHoldChecked: !multipleHoldChecked,
    }));
  };

  const submitMultipleHolds = async (holdData: {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    organisationName: string;
  }) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const token = await getAuthToken();

      if (!token) {
        return;
      }

      const counts = countBy(selectedShifts.map((selectedShift) => selectedShift.uuid));

      const uniqSelectedShifts = uniq(selectedShifts.map((selectedShift) => selectedShift.uuid));

      for (const selectedShiftUuid of uniqSelectedShifts) {
        const shift = shifts[selectedShiftUuid];

        const qty = counts[selectedShiftUuid];

        const extraValues = {
          locationId: shift.locationId,
          shiftId: shift.shiftId,
          timeSlotDate: shift.timeSlotDate,
          held: true,
          qty,
        };
        await Api.areaCoordinatorAddPersonToShift(token, {
          ...holdData,
          ...extraValues,
          uuid: "",
        });
      }

      await getShiftPeopleFromApi(selectedShifts.map((shift) => shift.uuid));
      resetState();
    } catch (e) {
      toast.error("Failed to save shifts. Try again.");
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return {
    selectedShifts,
    multipleHoldChecked,
    toggleMultipleHoldChecked,
    isChecked,
    resetState,
    submitMultipleHolds,
    addSelectedShiftUuid,
    removeSelectedShiftUuid,
    loading,
    shiftUuidCount,
  };
};

export const MultipleHoldProvider = ({ children }: any) => {
  const {
    selectedShifts,
    toggleMultipleHoldChecked,
    multipleHoldChecked,
    shiftUuidCount,
    isChecked,
    addSelectedShiftUuid,
    removeSelectedShiftUuid,
    resetState,
    submitMultipleHolds,
    loading,
  } = useMultipleHold();

  return (
    <MultipleHoldContext.Provider
      value={{
        selectedShifts,
        toggleMultipleHoldChecked,
        multipleHoldChecked,
        addSelectedShiftUuid,
        removeSelectedShiftUuid,
        isChecked,
        resetState,
        shiftUuidCount,
        submitMultipleHolds,
        loading,
      }}
    >
      {children}
    </MultipleHoldContext.Provider>
  );
};

export const MultipleHoldConsumer = () => {
  return React.useContext(MultipleHoldContext);
};
