import React from "react";

const StandardUserThankyou = () => {
  return (
    <div className="px-2">
      <p className="text-center">Thanks so much for volunteering for the Pink Ribbon Street Appeal!</p>
      <p className="text-center">
        Your support will help fund breast cancer education, innovative projects by some of our country’s top
        researchers, and support patients and their families as they navigate through their breast cancer journeys.
      </p>
      <p className="text-center">On this page, you can view or cancel your volunteer shifts, and add more shifts.</p>
    </div>
  );
};

export default StandardUserThankyou;
