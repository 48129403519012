import { useHideModal } from "domains/Modal/modalHooks";
import ButtonWithLoader from "domains/UtilComponents/ButtonWithLoader";
import React from "react";
import CancelShiftModal from "../CollectionSites/CancelShiftModal";

interface Props {
  text: string;
}

export const RegistrationFlowModal: React.FC<Props> = ({ text }) => {
  const hideModal = useHideModal();

  return (
    <div className="bg-white rounded p-8 border m-2">
      <div className="text-flamingo text-center">{text}</div>
      <div className="h-8" />
      <div className="flex justify-center items-center">
        <ButtonWithLoader
          classes="h-10 w-24"
          invert={true}
          loading={false}
          type="button"
          text="OK"
          onClick={hideModal}
        />
      </div>
    </div>
  );
};
