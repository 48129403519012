import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import classnames from "classnames";
import classNames from "classnames";

interface Props {
  loading: boolean;
  text: string;
  type?: "submit" | "button";
  onClick?: (values: any) => void;
  classes?: string;
  loadingText?: string;
  disabled?: boolean;
  invert?: boolean;
  highlightBg?: boolean;
}

const ButtonWithLoader = ({
  loading,
  text,
  type = "submit",
  onClick,
  loadingText,
  classes = "h-16 w-64",
  disabled = false,
  invert = false,
  highlightBg = false,
}: Props) => (
  <div className="w-full flex justify-center">
    <button
      disabled={loading || disabled}
      onClick={onClick}
      type={type}
      className={classnames(
        "rounded-full border focus:outline-none font-bold flex items-center justify-center",
        classes,
        {
          "text-flamingo  hover:text-white border-flamingo": !disabled && !invert,
          "hover:bg-flamingo": !disabled && !invert && !highlightBg,
          "hover:bg-lipGloss": !disabled && !invert && highlightBg,
          "bg-flamingo text-white hover:bg-lipGloss border-flamingo": !disabled && invert,
          "text-slate bg-jumbo hover:bg-jumbo hover:text-slate border-pebble": disabled,
        },
      )}
    >
      <div className="flex justify-center items-center">
        <div>{loading ? loadingText : text}</div>
        {loading ? (
          <div className={classNames("flex justify-center", { "ml-3": loadingText })}>
            <LoadingSpinner />
          </div>
        ) : null}
      </div>
    </button>
  </div>
);

export default ButtonWithLoader;
