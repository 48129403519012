import React from "react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

const defaultModalMotionProps: HTMLMotionProps<"div"> = {
  initial: { scale: 0.96, opacity: 0 },
  animate: { scale: 1, opacity: 1, transition: { type: "tween", ease: "easeInOut", duration: 0.2 } },
};

const defaultOverlayMotionProps: HTMLMotionProps<"div"> = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { type: "tween", ease: "easeInOut", duration: 0.2 } },
};

type Props = {
  show: boolean;
  style?: React.CSSProperties;
  rootStyle?: React.CSSProperties;
  modalMotionProps?: HTMLMotionProps<"div">;
  overlayMotionProps?: HTMLMotionProps<"div">;
  onHide?: () => void;
};

const Modal: React.FC<Props> = ({
  show,
  style,
  rootStyle,
  modalMotionProps = defaultModalMotionProps,
  overlayMotionProps = defaultOverlayMotionProps,
  children,
  onHide,
}) => (
  <AnimatePresence>
    {show && (
      <div
        className="fixed inset-0 flex items-center justify-center overflow-hidden"
        style={{ zIndex: 1000, ...rootStyle }}
      >
        <motion.div className="fixed inset-0 bg-[rgba(0,0,0,0.75)]" {...overlayMotionProps} onClick={onHide} />
        <motion.div
          className="relative flex flex-col items-stretch overflow-hidden rounded-sm bg-white"
          style={{
            boxShadow: "0 12px 15px 0 rgb(0 0 0 / 25%)",
            maxWidth: "calc(100vw - 70px)",
            maxHeight: "calc(100vh - 70px)",
            ...style,
          }}
          {...modalMotionProps}
        >
          {children}
        </motion.div>
      </div>
    )}
  </AnimatePresence>
);

export default Modal;
