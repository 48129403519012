import React from "react";
import { v4 as uuidv4 } from "uuid";

import DropDown from "./DropDown";
import CollectionSiteButton from "./CollectionSiteButton";
import { Tree } from "./StepOne";
import { Nullable } from "../../../../types";

interface Props {
  tree: Tree[];
  onClick: (locationId: string) => void;
  selectedLocationId: Nullable<string>;
  onFolderClick?: (tree: Tree) => void;
}

export const useGenerateAccordion = ({ tree, onClick, selectedLocationId, onFolderClick }: Props) => {
  const tempAccordion: React.ReactElement[] = [];
  const [accordion, setAccordion] = React.useState<React.ReactElement[]>([]);
  const [dirty, setDirty] = React.useState(false);

  const recurr = (tree: any, level: number, expanded: boolean) => {
    if (tree.length === 0 || !expanded) {
      return;
    } else {
      for (let i = 0; i < tree.length; i++) {
        const ignoreRow = tree[i]["title"] === "All Areas";
        if (tree[i]["content"].length !== 0) {
          //don't render the row if it is one that we want to ignore.
          //When we call recurr again, we just need to make sure we
          //don't increment the level, so that the indentation is correct.

          //we need to expand the row, as otherwise there will be no way for
          //the row to get into an expanded state.
          if (ignoreRow) {
            tree[i]["expanded"] = true;
          }
          if (!ignoreRow) {
            tempAccordion.push(
              <DropDown
                key={uuidv4()}
                tree={tree}
                dirty={dirty}
                setDirty={setDirty}
                i={i}
                level={level}
                onClick={onFolderClick}
              />,
            );
          }
        } else {
          tempAccordion.push(
            <CollectionSiteButton
              locationId={tree[i]["locationId"]}
              level={level}
              key={`${tree[i]["key"]}${i}`}
              title={tree[i]["title"]}
              onClick={onClick}
              selectedLocationId={selectedLocationId}
              setDirty={setDirty}
              dirty={dirty}
            />,
          );
        }
        const levelIncrement = ignoreRow ? 0 : 1;
        recurr(tree[i]["content"], level + levelIncrement, tree[i]["expanded"]);
      }
    }
  };

  React.useEffect(() => {
    if (!tree) {
      return;
    }

    recurr(tree, 0, true);

    setAccordion(tempAccordion);
  }, [dirty, tree]);

  return React.useCallback(() => {
    return accordion;
  }, [accordion]);
};
