import ACDropDown from "./ACDropDown";
import ACSite from "./ACSite";
import React from "react";
import { RawShiftData } from "../../Registration/CollectionSites/types";
import { useSelector } from "react-redux";
import { getSelectedLocationId } from "../../Locations/locationSelector";

interface Props {
  areaName: string;
  area: RawShiftData[];
  onClick: (locationId: string) => void;
  index: number;
}

const ACArea = ({ index, areaName, area, onClick }: Props) => {
  const [expanded, setExpanded] = React.useState(index === 0);

  const selectedLocationId = useSelector(getSelectedLocationId);

  if (!area[0]) {
    return null;
  }

  const subRegionText = area[0].subRegion ? `${area[0].subRegion}, ` : "";

  const areaText = `${area[0].area}, ${subRegionText}${area[0].region} `;
  return (
    <>
      <ACDropDown text={areaText} level={1} setExpanded={setExpanded} expanded={expanded} />
      <div>
        {area.map((site, index) => (
          <ACSite
            key={index}
            onClick={onClick}
            selectedLocationId={selectedLocationId}
            locationId={site.id}
            text={site.collectionSite}
            expanded={expanded}
          />
        ))}
      </div>
    </>
  );
};

export default ACArea;
