import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal, ModalStore } from "./modalTypes";

export const initialState: ModalStore = {};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<Modal>) => {
      state.activeModal = action.payload;
    },
    hideModal: (state) => (state.activeModal = undefined),
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
