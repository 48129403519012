import React from "react";
import { orderBy } from "lodash";
import { getLoadingShiftPeople, getShiftsByIds } from "../../Shifts/shiftSelector";
import { useSelector } from "react-redux";
import { AppStore } from "../../../rootReducer";
import { createTimeSlotEndTimeMap } from "./AreaCoordinatorHelpers";
import TimeSlotRow from "./TimeSlotRow";
import TimeSlotRowMobile from "./TimeSlotRowMobile";

interface Props {
  loadingShifts: boolean;
  allocatedShiftsIds: string[];
}

const RosterShifts = ({ allocatedShiftsIds }: Props) => {
  const allocatedShiftsFromStore = useSelector((state: AppStore) => getShiftsByIds(state, allocatedShiftsIds));
  const loadingShiftPeople = useSelector(getLoadingShiftPeople);
  const { uniqueTimeSlots, timeSlotMap } = createTimeSlotEndTimeMap(allocatedShiftsFromStore);

  return (
    <>
      {uniqueTimeSlots.map((timeSlotWithDuration, index) => {
        return (
          <React.Fragment key={index}>
            <TimeSlotRow
              key={index}
              shift={timeSlotMap[timeSlotWithDuration]}
              loadingShiftPeople={loadingShiftPeople}
              allocatedShiftsFromStore={allocatedShiftsFromStore}
            />
          </React.Fragment>
        );
      })}
      {orderBy(allocatedShiftsFromStore, ["timeStamp"])
        .sort((shift) => shift.timeStamp)
        .map((shift, index) => {
          return (
            <React.Fragment key={index}>
              <TimeSlotRowMobile key={`mobile-${index}`} loadingShiftPeople={loadingShiftPeople} shift={shift} />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default RosterShifts;
