import ArrowIcon from "./ArrowIcon";
import { Tree } from "./StepOne";
import classNames from "classnames";
import { useCheckIfLocationBlacklisted } from "../collectionSiteHooks";
import { CMSConsumer } from "domains/Cms/cmsContext";

interface Props {
  tree: Tree[];
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
  i: number;
  level: number;
  onClick?: (currentTree: Tree) => void;
}

const DropDown = ({ tree, setDirty, dirty, i, level, onClick }: Props) => {
  const checkIfLocationBlacklisted = useCheckIfLocationBlacklisted();

  return (
    <button
      className={classNames("py-2 px-2 w-full hover:bg-stone hover:text-coal", {
        "bg-stoneHalf": level === 0,
        "bg-cloud sm:bg-white": level !== 0,
      })}
      onClick={() => {
        if (level === 0) {
          //close all the other branches
          for (const branchIndex in tree) {
            if (parseInt(branchIndex) !== i) {
              const branch = tree[branchIndex];
              branch.expanded = false;
            }
          }
        }
        onClick && onClick(tree[i]);
        const { regionName, subRegionName, areaName } = tree[i];
        if (!checkIfLocationBlacklisted({ regionName, subRegionName, areaName })) {
          tree[i]["expanded"] = !tree[i]["expanded"];
        }
        setDirty(!dirty);
      }}
    >
      <div style={{ paddingLeft: level * 16 }} className="flex items-center">
        <div className="flex">
          <div>{tree[i]["title"]}</div>
        </div>
        <div className="pl-2">
          <ArrowIcon direction={tree[i]["expanded"] ? "up" : "down"} />
        </div>
      </div>
    </button>
  );
};

export default DropDown;
