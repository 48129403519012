import React from "react";
import { Shift } from "../../Shifts/types";
import LoadingSpinner from "../../UtilComponents/LoadingSpinner";
import AddVolunteerModal from "./AddVolunteerModal";
import { useAddPersonToShift } from "./ShiftPeopleHooks";
import { useSelector } from "react-redux";
import { getShiftById } from "../../Shifts/shiftSelector";
import { AppStore } from "../../../rootReducer";
import AddVolunteerButton from "./AddVolunteerButton";
import RenderShiftPerson from "./RenderShiftPerson";
import { useAppSelector } from "hooks";
import { getUserIsAC, isUserAdmin } from "domains/Person/personSelector";
import { ShiftPerson } from "domains/API/type";
import { getLocationById } from "domains/Locations/locationSelector";
import {
  useCheckCollectionSiteCancelled,
  useCheckIfLocationBlacklisted,
} from "domains/Registration/CollectionSites/collectionSiteHooks";
import Checkbox from "utils/Checkbox";
import { MultipleHoldConsumer } from "./multipleHoldContext";
import { isExpired } from "domains/Registration/CollectionSites/utils";
import classNames from "classnames";

interface Props {
  shift: Shift;
  loadingShiftPeople: boolean;
  columnDay: "Friday" | "Saturday";
}

const RenderShiftPeople = ({ shift, loadingShiftPeople, columnDay }: Props) => {
  const { isChecked, multipleHoldChecked, shiftUuidCount, removeSelectedShiftUuid, addSelectedShiftUuid } =
    MultipleHoldConsumer() || {};
  const [modalOpen, setModalOpen] = React.useState(false);
  const { loading, areaCoordinatorAddPersonToShift } = useAddPersonToShift(setModalOpen);
  const userIsAdmin = useAppSelector(isUserAdmin);
  const [modalContent, setModalContent] = React.useState({});

  const userIsAC = useSelector(getUserIsAC);

  const storeShift = useSelector((state: AppStore) => getShiftById(state, shift?.shiftId));

  const location = useSelector((state: AppStore) => getLocationById(state, shift?.locationId));

  const checkCovidRegionalCancellation = useCheckIfLocationBlacklisted();

  const getCheckCollectionSiteCancelled = useCheckCollectionSiteCancelled();

  const cancelOnly = getCheckCollectionSiteCancelled(location);

  const regionCancelled = userIsAdmin ? false : checkCovidRegionalCancellation({ location });

  const handleModalSubmit = (values: any) => {
    const extraValues = {
      locationId: shift.locationId,
      shiftId: shift.shiftId,
      timeSlotDate: shift.timeSlotDate,
      qty: 1,
    };
    setModalContent({});
    areaCoordinatorAddPersonToShift({ ...values, ...extraValues });
  };

  if (!shift || (!userIsAdmin && !storeShift.days.includes(columnDay) && !storeShift.days.includes("Both"))) {
    return (
      <div className="w-full flex justify-items-start items-center justify-center sm:justify-start">
        <div className="bg-cloud p-2">Not Available</div>
      </div>
    );
  }

  const currentShiftPeople = storeShift.people;

  if (loadingShiftPeople && !Object.keys(currentShiftPeople).length) {
    return (
      <div className="w-full flex justify-center">
        <div>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  const handleShiftPersonClick = (shiftPerson: ShiftPerson) => {
    setModalOpen(true);
    setModalContent(shiftPerson);
  };

  if (loadingShiftPeople && !Object.keys(currentShiftPeople).length) {
    return (
      <div className="w-full flex justify-center">
        <div>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  const toggleCheckbox = (index: number, shiftId: string) => {
    if (isChecked && isChecked(shiftId, index)) {
      removeSelectedShiftUuid && removeSelectedShiftUuid(shiftId, index);
    } else {
      addSelectedShiftUuid && addSelectedShiftUuid(shiftId, index);
    }
  };

  const maxPeoplePerShift = 2;

  return (
    <div className="my-2">
      <AddVolunteerModal
        isAreaCoordinator={true}
        description=""
        loading={loading}
        open={modalOpen}
        shiftPerson={modalContent}
        closeModal={() => setModalOpen(false)}
        handleSubmit={handleModalSubmit}
        setModalContent={setModalContent}
      />
      {
        <div className="flex items-center space-x-2">
          {
            <div>
              {Object.keys(currentShiftPeople).map((shiftPersonId, index) => {
                const shiftPerson = currentShiftPeople[shiftPersonId];
                return (
                  <div key={index}>
                    {Array(shiftPerson.personCount)
                      .fill(0)
                      .map((_, index) => (
                        <RenderShiftPerson
                          disabled={userIsAdmin ? false : isExpired(shift.timeStamp, userIsAC)}
                          cancelOnly={cancelOnly}
                          regionCancelled={regionCancelled}
                          key={index}
                          shiftPerson={shiftPerson}
                          handleClick={() => handleShiftPersonClick(shiftPerson)}
                        />
                      ))}
                  </div>
                );
              })}

              <div className="flex flex-col">
                {!regionCancelled &&
                  !loadingShiftPeople &&
                  !cancelOnly &&
                  Array.from(Array(maxPeoplePerShift - Object.keys(currentShiftPeople).length).keys()).map((index) => {
                    const isShiftExpired = userIsAdmin ? false : isExpired(shift.timeStamp, userIsAC);
                    return multipleHoldChecked ? (
                      <Checkbox
                        disabled={isShiftExpired}
                        key={index}
                        checked={isChecked ? isChecked(shift.shiftId, index) : false}
                        onClick={() => toggleCheckbox(index, shift.shiftId)}
                      >
                        <div
                          className={classNames("ml-2  text-sm", {
                            "text-flamingo": !isShiftExpired,
                            "text-cloudDark": isShiftExpired,
                          })}
                        >{`Reserve Shift ${index + 1 + Object.keys(currentShiftPeople).length}`}</div>
                      </Checkbox>
                    ) : (
                      <AddVolunteerButton
                        disabled={isShiftExpired}
                        key={index}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        loading={loading}
                        open={modalOpen}
                        closeModal={() => setModalOpen(false)}
                        handleSubmit={handleModalSubmit}
                      />
                    );
                  })}
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default RenderShiftPeople;
