import React from "react";
import Cookies from "js-cookie";
import { uniq } from "lodash";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { v4 as uuidv4 } from "uuid";
import {
  addSelectedShift,
  addShifts,
  decrementSelectedShift,
  removeACShift,
  removeHeldFromShift,
  removeSelectedShift,
  toggleMultipleHoldChecked,
  updateFriendDetails,
  updateLoadingShiftPeople,
} from "./shiftSlice";
import { Shift } from "./types";
import { getSelectedShifts, getShiftById, getShifts } from "./shiftSelector";
import { Api } from "../API/API";
import { filter } from "lodash";
import { Nullable } from "../../types";
import { useGetLocations } from "../Locations/locationHooks";
import { RawShiftData } from "../Registration/CollectionSites/types";
import moment from "moment";
import { ACShiftsApi, ApiShift, AreaCoordinatorDay, ShiftPerson, Token } from "../API/type";
import {
  addPersonToShift,
  addPeopleToShift,
  updateAreaCoordinatorsShiftIds,
  updateAreaCoordinatorShiftDays,
  incrementSelectedShift,
} from "../Shifts/shiftSlice";
import { addLocations, addNotesToLocations } from "../Locations/locationSlice";
import { toast } from "react-hot-toast";
import { navigate } from "@reach/router";
import { orderBy } from "lodash";
import { sumCounts } from "../Registration/CollectionSites/utils";
import { useGetShiftPeople } from "../SignedIn/AreaCoordinator/ShiftPeopleHooks";
import { getLocations } from "../Locations/locationSelector";
import { isUserAdmin } from "domains/Person/personSelector";
import getErrorMessage from "domains/errors";

export const useGetShiftsForLocation = () => {
  const dispatch = useAppDispatch();
  const [loadingShifts, setLoadingShifts] = React.useState(false);

  const { getShiftPeopleFromApi } = useGetShiftPeople();

  const populateShifts = async (locationId: string) => {
    setLoadingShifts(true);
    const shiftData = await Api.getTimeslots(locationId);
    getShiftPeopleFromApi(shiftData.map((shift) => shift.shiftId));
    dispatch(addShifts(shapeShifts(shiftData)));
    setLoadingShifts(false);
  };

  return {
    loadingShifts,
    getAndSaveShifts: React.useCallback(
      (locationId: string) => {
        populateShifts(locationId);
      },
      [populateShifts],
    ),
  };
};

export const useAddSelectedShift = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (shift) => {
      dispatch(incrementSelectedShift({ ...shift, countTemp: 1, countSynced: 0, uuid: uuidv4(), synced: false }));
    },
    [dispatch],
  );
};

export const useDecrementSelectedShift = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (shift) => {
      dispatch(decrementSelectedShift(shift));
    },
    [dispatch],
  );
};

export const useRemoveSelectedShift = () => {
  const dispatch = useAppDispatch();
  const { getAuthToken } = useGetAccessToken();
  const [loading, setLoading] = React.useState(false);

  const deleteShift = async (shift: Shift, onComplete: () => void) => {
    try {
      setLoading(true);

      if (shift.synced) {
        const token = await getAuthToken();

        if (!token) {
          return;
        }

        if (!shift?.uuid) {
          alert("Please refresh and try again");
          return;
        }

        await Api.deleteShift(token, shift.uuid);
      }
      if (sumCounts([shift]) > 1) {
        dispatch(decrementSelectedShift(shift));
      } else {
        dispatch(removeSelectedShift(shift));
      }
    } catch {
      toast.error("Failed to remove shift/shifts");
    } finally {
      setLoading(false);
      onComplete && onComplete();
    }
  };

  return {
    loading,
    deleteShift: React.useCallback(
      (shift: Shift, onComplete: () => void) => {
        deleteShift(shift, onComplete);
      },
      [dispatch, deleteShift],
    ),
  };
};

export const useRemoveACShift = (
  closeModal?: () => void,
  setModalContent?: (shiftPerson: Partial<ShiftPerson>) => void,
) => {
  const dispatch = useAppDispatch();
  const { getAuthToken } = useGetAccessToken();
  const [loading, setLoading] = React.useState(false);

  const deleteShift = async (hourUuid: string, shiftId: string, synced: boolean, onComplete?: () => void) => {
    try {
      setLoading(true);
      if (synced) {
        const token = await getAuthToken();

        if (!token) {
          return;
        }

        if (!hourUuid) {
          alert("Please refresh and try again");
          return;
        }

        await Api.deleteShift(token, hourUuid);
      }
      dispatch(removeACShift({ hourUuid, shiftId }));
      closeModal && closeModal();
      setModalContent && setModalContent({});
    } catch {
      toast.error("Failed to remove shift/shifts");
    } finally {
      setLoading(false);
      onComplete && onComplete();
    }
  };

  return {
    loading,
    deleteShift: React.useCallback(
      (hourUuid: string, shiftId: string, synced: boolean, onComplete?: () => void) => {
        deleteShift(hourUuid, shiftId, synced, onComplete);
      },
      [dispatch, deleteShift],
    ),
  };
};

export const useConfirmHeldShift = () => {
  const dispatch = useAppDispatch();
  const { getAuthToken } = useGetAccessToken();
  const [loading, setLoading] = React.useState(false);

  const confirmShift = async (hourUuid: string, shiftId: string, onComplete?: () => void) => {
    try {
      setLoading(true);

      const token = await getAuthToken();

      if (!token) {
        return;
      }

      if (!hourUuid) {
        alert("Please refresh and try again");
        return;
      }

      await Api.confirmHeldShift(token, hourUuid);

      dispatch(removeHeldFromShift({ hourUuid, shiftId }));
    } catch {
      toast.error("Failed to confirm shift");
    } finally {
      setLoading(false);
      onComplete && onComplete();
    }
  };

  return {
    loading,
    confirmShift: React.useCallback(
      (hourUuid: string, shiftId: string, onComplete?: () => void) => {
        confirmShift(hourUuid, shiftId, onComplete);
      },
      [dispatch, confirmShift],
    ),
  };
};

export const useGetShiftById = (shiftId: string) => {
  return useAppSelector((state) => getShiftById(state, shiftId));
};

export const useGetSelectedShifts = () => {
  return useAppSelector(getSelectedShifts);
};

export const useGetOrderedSelectedShifts = () => {
  const shifts = useAppSelector(getSelectedShifts);
  return orderBy(Object.values(shifts), "timeStamp");
};

export const useGetSelectedShiftById = () => {
  const selectedShifts = useAppSelector(getSelectedShifts);

  return React.useCallback(
    (shiftId: string) => {
      const shifts = Object.values(selectedShifts).filter((shift) => shift.shiftId === shiftId);
      return shifts;
    },
    [selectedShifts],
  );
};

export const useAddFriendsDetailsToShift = (setModalOpen: (modalOpen: boolean) => void) => {
  const { getAuthToken } = useGetAccessToken();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const addFriendsDetailsToShift = async (shiftPerson: ShiftPerson) => {
    try {
      setLoading(true);
      const token = await getAuthToken();

      if (!token) {
        return;
      }
      await Api.addFriendsDetailsToShift(token, shiftPerson);
      dispatch(updateFriendDetails({ shiftUuid: shiftPerson.uuid, friendDetails: shiftPerson }));
      setModalOpen(false);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    addFriendsDetailsToShift: React.useCallback(
      (shiftPerson: ShiftPerson) => {
        addFriendsDetailsToShift(shiftPerson);
      },
      [setModalOpen, setLoading, loading, dispatch],
    ),
  };
};

export const useGetShiftCountByShiftId = (shiftId?: string) => {
  const getSelectedShift = useGetSelectedShiftById();

  if (!shiftId) {
    return 0;
  }

  const shifts = getSelectedShift(shiftId);

  return sumCounts(shifts);
};

export const useGetShiftsByLocation = () => {
  const shifts = useAppSelector(getShifts);

  return React.useCallback(
    (locationId: Nullable<string>) => {
      if (!locationId) {
        return [];
      }
      return filter(shifts, { locationId: locationId });
    },
    [shifts],
  );
};

const mapApiShiftToShift = (shift: ApiShift): Shift => {
  return {
    timeStamp: shift.timeStamp,
    shiftId: shift.shiftId,
    locationId: shift.locationId,
    timeSlotEnd: shift.timeSlotEnd,
    timeSlotDate: shift.timeSlotDate,
    timeSlotStart: shift.timeSlotStart,
    slotsAvailable: shift.slotsAvailable,
    friendDetails: shift.friendDetails,
    people: {},
    days: [],
    synced: true,
    countSynced: parseInt(shift.count),
    countTemp: 0,
    duration: 0,
    uuid: shift.uuid,
    multipleHoldChecked: false,
  };
};

export const useGetShiftsByUser = () => {
  const [loadingShifts, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const shapedShift = (shift: ApiShift) => {
    const hourRecord = mapApiShiftToShift(shift);
    return { ...hourRecord, timeStamp: generateTimestamp(hourRecord) };
  };

  const shapeShifts = (shifts: ApiShift[]) => {
    return shifts.reduce((accum, shift) => {
      return {
        ...accum,
        [shift.shiftId]: shapedShift(shift),
      };
    }, {});
  };

  const { getAuthToken } = useGetAccessToken();

  const getShiftsByUser = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();

      if (!token) {
        return;
      }

      const shifts = await Api.getShiftsByUser(token);

      dispatch(addShifts(shapeShifts(shifts)));

      for (const shift of shifts) {
        dispatch(addSelectedShift({ ...shapedShift(shift), synced: true, people: {} }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    getShiftsByUser,
    loadingShifts,
  };
};

export const useGetAccessToken = () => {
  const [authLoading, setAuthLoading] = React.useState(false);

  const [token, setToken] = React.useState<Nullable<Token>>(null);

  const getAccessToken = async () => {
    try {
      const authToken = Cookies.get("auth-jwt");
      if (!authToken) {
        //navigate("/");
        return;
      }
      return authToken ? authToken : "";
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
    }
  };

  return {
    authLoading,
    token,
    getAuthToken: React.useCallback(async () => {
      setAuthLoading(true);
      const token = await getAccessToken();
      setAuthLoading(false);
      return token;
    }, []),
  };
};

const shapeAreas = (acLocations: RawShiftData[]) => {
  let shapedAreas: { [id: string]: RawShiftData[] } = {};
  for (const location of acLocations) {
    const existingLocation = shapedAreas[location.area];
    const newArr = [...(shapedAreas[location.area] ? shapedAreas[location.area] : []), location];
    shapedAreas[location.area] = newArr;
  }
  return shapedAreas;
};

const shapeLocations = (locationData: RawShiftData[]) => {
  let shapedLocationData: { [id: string]: RawShiftData } = {};

  for (const location of locationData) {
    if (location.id) {
      shapedLocationData[location.id] = location;
    }
  }
  return shapedLocationData;
};

export const generateTimestamp = (shift: Shift) => {
  if (!shift?.timeSlotDate || !shift.timeSlotStart) {
    return 0;
  }
  return moment(`${shift.timeSlotDate} ${shift.timeSlotStart}`, "DD/MM/yyyy HH:mm").clone().valueOf();
};

const shapeShifts = (shiftData: Shift[]) => {
  const shapedShiftData: { [uuid: string]: Shift } = {};

  for (const shift of shiftData) {
    if (shift.shiftId) {
      shapedShiftData[shift.shiftId] = {
        ...shift,
        people: {},
        timeStamp: generateTimestamp(shift),
        multipleHoldChecked: false,
      };
    }
  }
  return shapedShiftData;
};

const getDaysWorking = (allocatedShift: ACShiftsApi) => {
  type DayCheckOptions = "ac1Days" | "ac2Days" | "ac3Days" | "ac4Days";

  let days: AreaCoordinatorDay[] = [];
  let daysToCheck: DayCheckOptions[] = [];

  if (allocatedShift.ac1 === allocatedShift.areaCoordId) {
    daysToCheck = [...daysToCheck, "ac1Days"];
  }
  if (allocatedShift.ac2 === allocatedShift.areaCoordId) {
    daysToCheck = [...daysToCheck, "ac2Days"];
  }
  if (allocatedShift.ac3 === allocatedShift.areaCoordId) {
    daysToCheck = [...daysToCheck, "ac3Days"];
  }
  if (allocatedShift.ac4 === allocatedShift.areaCoordId) {
    daysToCheck = [...daysToCheck, "ac4Days"];
  }

  for (const dayToCheck of daysToCheck) {
    days = [...days, allocatedShift[dayToCheck]];
  }

  return days;
};

const getAreaCoordinatorShiftDays = (allocatedShifts: ACShiftsApi[]) => {
  const shapedShifts: { [shiftId: string]: AreaCoordinatorDay[] } = {};
  for (const allocatedShift of allocatedShifts) {
    shapedShifts[allocatedShift.shiftId] = getDaysWorking(allocatedShift);
  }
  return shapedShifts;
};

export const useGetACShifts = () => {
  const [loadingAcAreas, setLoadingAcAreas] = React.useState(false);
  const { loadingLocations } = useAppSelector(getLocations);
  const [acAreas, setAcAreas] = React.useState<{ [area: string]: RawShiftData[] }>({});
  const userIsAdmin = useAppSelector(isUserAdmin);

  const { getAuthToken } = useGetAccessToken();

  const dispatch = useAppDispatch();

  const preload = async () => {
    try {
      setLoadingAcAreas(true);
      const token = await getAuthToken();

      if (!token) {
        return;
      }

      const locationData = await Api.getLocationsFromApi();

      dispatch(addLocations(shapeLocations(locationData)));

      const rawAreaCoordinatorsAllocatedShifts = await Api.getACShiftsByUser(token);

      //This is some hacky stuff to save the days that each shift is allocated on
      //Should really be handled in the backend.
      dispatch(updateAreaCoordinatorShiftDays(getAreaCoordinatorShiftDays(rawAreaCoordinatorsAllocatedShifts)));

      const areaCoordinatorLocationIds = uniq(rawAreaCoordinatorsAllocatedShifts.map((shift) => shift.locationId));

      const locationNotes = await Api.getLocationsByIds(token, areaCoordinatorLocationIds);

      dispatch(addNotesToLocations(locationNotes));

      ///we need to save these ids for later
      const areaCoordinatorsShiftIds = rawAreaCoordinatorsAllocatedShifts
        .filter((shift) => shift.locationId)
        .map((shift) => shift.shiftId);

      dispatch(updateAreaCoordinatorsShiftIds(areaCoordinatorsShiftIds));

      const areaCoordinatorShifts = locationData.filter((location) => areaCoordinatorLocationIds.includes(location.id));

      const acAreas = shapeAreas(areaCoordinatorShifts);

      setAcAreas(acAreas);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingAcAreas(false);
    }
  };

  React.useEffect(() => {
    preload();
  }, [userIsAdmin]);

  return {
    loadingLocations,
    loadingAcAreas,
    acAreas,
  };
};

export const useAddPersonToShiftLocally = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (shiftPerson: ShiftPerson) => {
      dispatch(addPersonToShift(shiftPerson));
    },
    [dispatch],
  );
};

export const useAddPeopleToShiftsLocally = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (shiftPeople: ShiftPerson[]) => {
      dispatch(addPeopleToShift(shiftPeople));
    },
    [dispatch],
  );
};

export const useUpdateLoadingShiftPeople = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (loading: boolean) => {
      dispatch(updateLoadingShiftPeople({ loadingShiftPeople: loading }));
    },
    [dispatch],
  );
};

export const useAddVolunteerToShifts = () => {
  const { getAuthToken } = useGetAccessToken();
  const [loading, setLoading] = React.useState(false);
  const selectedShifts = useAppSelector(getSelectedShifts);
  const dispatch = useAppDispatch();

  const filterShifts = (selectedShifts: { [shiftId: string]: Shift }) => {
    return Object.keys(selectedShifts)
      .map((shiftId) => ({ ...selectedShifts[shiftId], count: sumCounts([selectedShifts[shiftId]]) }))
      .filter((shift) => !shift.synced);
  };

  const addShifts = async () => {
    setLoading(true);
    try {
      const filteredShifts = filterShifts(selectedShifts);
      if (!filteredShifts.length) {
        navigate("/my-shifts");
        return;
      }
      const token = await getAuthToken();
      if (!token) {
        return;
      }
      await Api.addVolunteerToShift(token, filteredShifts);

      const totalShiftCount = filteredShifts.reduce((accum, shift) => {
        return accum + shift.count;
      }, 0);

      const dataLayer = window?.dataLayer;

      if (dataLayer) {
        dataLayer.push({
          event: "shift_added",
          shift_num: totalShiftCount,
        });
      }

      for (const shift of filteredShifts) {
        dispatch(addSelectedShift({ ...shift, synced: true, countTemp: 0, countSynced: sumCounts([shift]) }));
      }
      navigate("/my-shifts");
    } catch (e) {
      console.log(e);
      toast.error("Failed to add your shifts");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    addShifts: React.useCallback(() => {
      addShifts();
    }, [selectedShifts]),
  };
};

export const useToggleMultipleHoldChecked = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (shiftUuid: string) => {
      dispatch(toggleMultipleHoldChecked({ shiftUuid }));
    },
    [dispatch],
  );
};

export const useShiftHasFriendAdded = () => {
  const getSelectedShift = useGetSelectedShiftById();

  return React.useCallback(
    (shiftId: string) => {
      const shifts = getSelectedShift(shiftId);

      return shifts.reduce((accum, shift) => {
        return shift.friendDetails ? true : accum;
      }, false);
    },
    [getSelectedShift],
  );
};
