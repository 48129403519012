import React from "react";
import { FormikValues } from "formik";

interface Props {
  onChange: () => any;
  title: string;
  inputRef: React.RefObject<HTMLInputElement>;
  errors: any;
  name: string;
  placeholder?: string;
  values: FormikValues;
  disabled: boolean;
  requiredField?: boolean;
}

const AddressInput = ({
  onChange,
  title,
  inputRef,
  errors,
  name,
  placeholder,
  values,
  disabled = false,
  requiredField = false,
}: Props) => {
  return (
    <div className="h-19">
      <div className="mb-1">
        {title}
        {requiredField && "*"}
      </div>
      <span className="flex items-center bg-white hover:bg-snow border rounded h-8 text-slate border-stone text-sm">
        <input
          style={{ boxShadow: "none" }}
          disabled={disabled}
          value={values[name]}
          onChange={onChange}
          type="text"
          id={name}
          className="bg-white hover:bg-snow focus:outline-none border-0 outline-none align-middle font-thin h-full w-full rounded"
          placeholder={placeholder}
          ref={inputRef}
        />
      </span>
      <div className="text-xs text-warning">{errors[name]}</div>
    </div>
  );
};

export default AddressInput;
