import React from "react";

const CloseIconSmall = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0833 3.73916L10.2608 2.91666L7 6.17749L3.73917 2.91666L2.91667 3.73916L6.1775 6.99999L2.91667 10.2608L3.73917 11.0833L7 7.82249L10.2608 11.0833L11.0833 10.2608L7.8225 6.99999L11.0833 3.73916Z"
        className="text-jumbo group-hover:text-slate fill-current"
      />
    </svg>
  );
};

export default CloseIconSmall;
