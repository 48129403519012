import { CmsState } from "./cmsContext";
import { uniq, countBy } from "lodash";

export const getEntryBySlugId = (state: CmsState, slugId: string) => {
  return state.cmsEntries[slugId];
};

export const getFilteredSlugIds = (state: CmsState, prefixFilter: string) => {
  const slugIds = getSlugIds(state);

  return slugIds.filter((slugId) => {
    const index = slugId.indexOf("/");
    const prefix = slugId.substring(0, index);
    return prefix === prefixFilter;
  });
};

export const getSlugIds = (state: CmsState) => {
  const { slugIds } = state;

  return slugIds.filter((slugId) => {
    const entry = getEntryBySlugId(state, slugId);
    return !!entry.createMenuItem && !entry.hidePage;
  });
};

const getPrefixes = (state: CmsState) => {
  const slugIds = getSlugIds(state);

  return slugIds.reduce<string[]>((accum: any, slugId) => {
    if (slugId.indexOf("/") === -1) {
      return [...accum, slugId];
    }
    return [...accum, slugId.substring(0, slugId.indexOf("/"))];
  }, []);
};

export const getHeaders = (state: CmsState) => {
  return uniq(getPrefixes(state)).sort();
};

export const hasMultiple = (state: CmsState, slugId: string) => {
  const frequencies = countBy(getPrefixes(state));

  return (frequencies[slugId] | 0) > 1;
};

export const isHeader = (state: CmsState, header: string) => {
  const slugIds = getSlugIds(state);
  const allLinks = slugIds.map((slugId) => removePrefix(slugId));

  return allLinks.includes(header);
};

export const removePrefix = (slugId: string) => {
  const index = slugId.indexOf("/") + 1;

  if (slugId.indexOf("/") === -1) {
    return slugId;
  }
  return slugId.substring(index);
};
