import React from "react";
import { Formik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";
import FieldInput from "./FieldInput";
import AddressDefault from "./address/AddressDefault";
import { Auth0User } from "../types";
import CheckField from "./CheckField";
import ButtonWithLoader from "../../UtilComponents/ButtonWithLoader";
import { Nullable } from "types";
import ScrollToFieldError from "domains/Shared/ScrollToError";
import { useIsACRegisterPage } from "domains/Account/accountHooks";
import { useQuery } from "domains/Shared/genericHooks";

interface Props {
  saveClick: (values: any) => void;
  saveButtonText: string;
  backClick: () => void;
  user?: Auth0User;
  loading?: boolean;
  profilePage?: boolean;
  pageError: Nullable<{ [key: string]: string }>;
}

const generatePersonDetailsSchema = (isACRegisterPage: boolean) => {
  return Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    email: Yup.string().email().required("Please enter your email"),
    ...(!isACRegisterPage && { mobile: Yup.string().required("Please enter your mobile") }),
    ...(!isACRegisterPage && { addressLine1: Yup.string().required("Please enter your address") }),
    ...(!isACRegisterPage && { addressLine2: Yup.string().optional() }),
    ...(!isACRegisterPage && { suburb: Yup.string().optional() }),
    ...(!isACRegisterPage && { city: Yup.string().optional() }),
    ...(!isACRegisterPage && { postCode: Yup.string().optional() }),
    password: Yup.string()
      .required("Please enter a password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number",
      ),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same"),
      }),
    termsAndConditions: Yup.boolean().oneOf([true], "Field must be checked"),
  });
};

const PersonalDetailsPage = ({ saveClick, user, loading, profilePage = false, pageError }: Props) => {
  const isACRegisterPage = useIsACRegisterPage();

  const [interestedInAreaCoordinator, setInterestedInAreaCoordinator] = React.useState(false);
  const [hearMoreFromBCFNZ, setHearMoreFromBCFNZ] = React.useState(false);

  return (
    <div className="w-full px-2 flex justify-center items-center">
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          firstName: user?.firstName ? user.firstName : useQuery("firstName") ? useQuery("firstName") : "",
          lastName: user?.lastName ? user.lastName : useQuery("lastName") ? useQuery("lastName") : "",
          email: user?.email ? user.email : useQuery("email") ? useQuery("email") : "",
          ...(!isACRegisterPage && { mobile: user?.mobile ? user.mobile : "" }),
          ...(!isACRegisterPage && { addressLine1: user?.addressLine1 ? user.addressLine1 : "" }),
          ...(!isACRegisterPage && { addressLine2: user?.addressLine2 ? user.addressLine2 : "" }),
          ...(!isACRegisterPage && { suburb: user?.suburb ? user.suburb : "" }),
          ...(!isACRegisterPage && { city: user?.city ? user.city : "" }),
          ...(!isACRegisterPage && { postCode: user?.postCode ? user.postCode : "" }),
          password: "",
          confirmPassword: "",
          termsAndConditions: false,
        }}
        validationSchema={generatePersonDetailsSchema(isACRegisterPage)}
        onSubmit={(values: any, { setSubmitting }) => {
          saveClick({
            ...values,
            ...(!isACRegisterPage && { interestedInAreaCoordinator }),
            hearMoreFromBCFNZ,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            className={classNames("flex justify-center items-center", { "w-140": isACRegisterPage })}
          >
            <ScrollToFieldError />
            <div className="py-4">
              <div className={classNames("flex flex-col lg:flex-row", { "justify-center": isACRegisterPage })}>
                <FieldInput
                  requiredField={true}
                  disabled={profilePage}
                  name={"email"}
                  title={"Email"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                />
                <div className="w-64 sm:mr-8"></div>
              </div>
              <div className={classNames("flex flex-col lg:flex-row", { "justify-center": isACRegisterPage })}>
                <FieldInput
                  requiredField={true}
                  disabled={profilePage}
                  name={"firstName"}
                  title={"First name"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                />
                <FieldInput
                  requiredField={true}
                  disabled={profilePage}
                  name={"lastName"}
                  title={"Last name"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                />
              </div>

              {!isACRegisterPage && (
                <>
                  <FieldInput
                    requiredField={true}
                    disabled={profilePage}
                    name={"mobile"}
                    title={"Mobile"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                  />
                  <AddressDefault
                    setFieldValue={setFieldValue}
                    errors={errors}
                    editable={!profilePage}
                    values={values}
                  />
                </>
              )}

              {!profilePage ? (
                <>
                  <div className={classNames("flex flex-col lg:flex-row", { "justify-center": isACRegisterPage })}>
                    <FieldInput
                      requiredField={true}
                      name={"password"}
                      title={"Password"}
                      type={"password"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      errors={errors}
                      touched={touched}
                      classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                    />
                    <div className="h-4 lg:h-0" />
                    <FieldInput
                      requiredField={true}
                      name={"confirmPassword"}
                      title={"Confirm Password"}
                      type={"password"}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      errors={errors}
                      touched={touched}
                      classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                    />
                  </div>

                  <div className="mb-12 mt-4">
                    <>
                      <div className="text-flamingo text-lg mb-4 font-bold">Terms</div>
                      <CheckField
                        name="termsAndConditions"
                        errors={errors}
                        checked={values["termsAndConditions"]}
                        onClick={() => setFieldValue("termsAndConditions", !values["termsAndConditions"])}
                        text={
                          <div>
                            <span>I agree to the </span>
                            <span>
                              <a className="text-pebble" href={"/terms"} target="_blank">
                                Terms & Conditions
                              </a>
                            </span>
                            <span>
                              , including the Health & Safety Guidelines, and I'm over 18 or collecting with my
                              parent/guardian.
                            </span>
                          </div>
                        }
                      />

                      {!isACRegisterPage && (
                        <CheckField
                          name="interestedInAreaCoordinator"
                          errors={errors}
                          checked={interestedInAreaCoordinator}
                          onClick={() => setInterestedInAreaCoordinator(!interestedInAreaCoordinator)}
                          text={<div>{"I am interested in becoming an Area Coordinator"}</div>}
                        />
                      )}
                      <CheckField
                        name="hearMoreFromBCFNZ"
                        errors={errors}
                        checked={hearMoreFromBCFNZ}
                        onClick={() => setHearMoreFromBCFNZ(!hearMoreFromBCFNZ)}
                        text={<div>{"I want to hear more from Breast Cancer Foundation NZ"}</div>}
                      />
                    </>
                  </div>

                  <ButtonWithLoader loadingText="SAVING" loading={!!loading} text="COMPLETE MY REGISTRATION" />
                  {pageError && pageError["pageError"] && (
                    <div className="flex flex-1 justify-center mt-2">
                      <div className="text-warning">{pageError["pageError"]}</div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalDetailsPage;
