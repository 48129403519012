import React from "react";
import RenderShiftPeople from "./RenderShiftPeople";
import { columnOneDate, columnTwoDate, dateToDay, getShift } from "./AreaCoordinatorHelpers";
import { Shift } from "../../Shifts/types";
import moment from "moment";

interface Props {
  shift: Shift;
  loadingShiftPeople: boolean;
}

const TimeSlotRowMobile = ({ shift, loadingShiftPeople }: Props) => {
  const columnDay: any = moment(shift.timeStamp).format("dddd");
  return (
    <tr className="w-full border-b flex sm:hidden flex-col justify-center py-4">
      <td className="font-bold flex justify-center text-flamingo">{`${moment(shift.timeStamp).format(
        "h:mma",
      )} - ${moment(shift.timeStamp).add(moment.duration(shift.duration, "hours")).format("h:mma")}`}</td>

      <td>
        <div className="font-bold text-center py-2">{moment(shift.timeStamp).format("dddd DD[th] MMMM")}</div>
        <div className="flex items-center justify-center">
          <RenderShiftPeople columnDay={columnDay} shift={shift} loadingShiftPeople={loadingShiftPeople} />
        </div>
      </td>
    </tr>
  );
};

export default TimeSlotRowMobile;
