import React from "react";
import {
  useAddPeopleToShiftsLocally,
  useAddPersonToShiftLocally,
  useGetAccessToken,
  useUpdateLoadingShiftPeople,
} from "../../Shifts/shiftHooks";
import { Api } from "../../API/API";
import { AddShiftPersonApi, GetShiftPersonAPI, ShiftPeople, ShiftPerson } from "../../API/type";
import { toast } from "react-hot-toast";
import { updateLoadingShiftPeople } from "../../Shifts/shiftSlice";
import getErrorMessage from "domains/errors";

const shapeShiftPeople = (shiftPeople: GetShiftPersonAPI[]): ShiftPeople => {
  return shiftPeople.map((shiftPerson) => ({
    personCount: shiftPerson.personcount,
    firstName: shiftPerson.firstname,
    lastName: shiftPerson.lastname,
    email: shiftPerson.email,
    mobile: shiftPerson.mobilephone,
    shiftId: shiftPerson.shiftid,
    createdDate: shiftPerson.createddate,
    uuid: shiftPerson.uuid,
    synced: true,
    addedOnBehalf: shiftPerson.addedOnBehalf,
    held: shiftPerson.held,
    organisationName: shiftPerson.organisationName,
  }));
};

export const useGetShiftPeople = () => {
  const { getAuthToken } = useGetAccessToken();

  const addPeopleToShiftsLocally = useAddPeopleToShiftsLocally();
  const updateLoadingShiftPeople = useUpdateLoadingShiftPeople();

  const getShiftPeopleFromApi = async (shiftIds: string[]) => {
    updateLoadingShiftPeople(true);
    const token = await getAuthToken();
    if (!token) {
      return;
    }
    const shiftPeople = await Api.getShiftPeople(token, shiftIds);
    const shapedShiftPeople = shapeShiftPeople(shiftPeople);
    addPeopleToShiftsLocally(shapedShiftPeople);
    updateLoadingShiftPeople(false);
  };

  return {
    getShiftPeopleFromApi: React.useCallback((shiftIds: string[]) => {
      return getShiftPeopleFromApi(shiftIds);
    }, []),
  };
};

export const useAddPersonToShift = (setModalOpen?: (modalOpen: boolean) => void) => {
  const { authLoading, getAuthToken } = useGetAccessToken();
  const [loading, setLoading] = React.useState(false);
  const addPersonToShiftLocally = useAddPersonToShiftLocally();

  const addPersonToShift = async (person: AddShiftPersonApi) => {
    try {
      setLoading(true);
      const token = await getAuthToken();
      if (!token) {
        return;
      }
      const returnedShiftPeople = await Api.areaCoordinatorAddPersonToShift(token, person);

      returnedShiftPeople.map((returnedShiftPerson) => {
        const shiftPerson: ShiftPerson = {
          personCount: 1,
          firstName: returnedShiftPerson.firstName,
          lastName: returnedShiftPerson.lastName,
          email: returnedShiftPerson.email,
          mobile: returnedShiftPerson.mobile,
          shiftId: person.shiftId,
          uuid: returnedShiftPerson.uuid,
          synced: true,
          createdDate: returnedShiftPerson.createdDate,
          addedOnBehalf: true,
          held: returnedShiftPerson.held,
        };
        addPersonToShiftLocally(shiftPerson);
      });

      setModalOpen && setModalOpen(false);
    } catch (e) {
      console.log(e);
      toast.error(getErrorMessage(e));
      // alert(
      //   "Whoops, something went wrong! Please try again, or contact the Pink Ribbon Street Appeal Team on 0508 150 150 or go to pinkribbon@bcf.org.nz.",
      // );
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    areaCoordinatorAddPersonToShift: React.useCallback(
      (person: AddShiftPersonApi) => {
        addPersonToShift(person);
      },
      [setModalOpen],
    ),
  };
};
