import { AppStore } from "../../rootReducer";
import { Nullable } from "../../types";

export const getLocations = (state: AppStore) => {
  return { locations: state.locationStore.locations, loadingLocations: state.locationStore.loadingLocations };
};

export const getLocationById = (state: AppStore, id: Nullable<string>) => {
  const { locations } = getLocations(state);

  if (!id) {
    return null;
  }

  return locations[id];
};

export const getLocationsByIds = (state: AppStore, locationIds: string[]) => {
  const { locations } = getLocations(state);

  return locationIds.map((locationId) => locations[locationId]);
};

export const getSelectedLocation = (state: AppStore) => {
  const { locations } = getLocations(state);
  const selectedLocationId = state.locationStore.selectedLocationId;

  if (!selectedLocationId) {
    return null;
  }

  return locations[selectedLocationId];
};

export const getSelectedLocationId = (state: AppStore) => {
  return state.locationStore.selectedLocationId;
};

export const getHasCompletedRegistration = (state: AppStore) => {
  return state.locationStore.registrationCompleted;
};
