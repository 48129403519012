import { Api } from "domains/API/API";
import * as Yup from "yup";
import Button from "domains/Shared/Button";
import { Formik } from "formik";
import React from "react";
import FieldInput from "./FieldInput";
import { Nullable } from "types";

interface Props {
  checkEmail: (email: string) => void;
  loading: boolean;
  setEmail: (email: string) => void;
  error: Nullable<string>;
}

const schema = Yup.object().shape({
  email: Yup.string().email().required("Please enter your email"),
});

const CheckEmail: React.FC<Props> = ({ checkEmail, loading, setEmail, error }) => {
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          email: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          checkEmail(values.email);
          setEmail(values.email);
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, touched }) => (
          <div className="p-8">
            <div className="flex justify-center">
              Please let us know your email address so we can confirm your shifts.
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col justify-center">
              <div className="flex flex-col lg:flex-row justify-center px-0 sm:px-8 py-8">
                <FieldInput
                  requiredField={true}
                  name={"email"}
                  title={"Your email address"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-full sm:w-64 mr-0 sm:mr-8"}
                />
              </div>

              <div className="p-4">
                <Button onClick={handleSubmit} text={"NEXT"} isLoading={loading}></Button>
              </div>
              <div className="flex justify-center text-warning">{error}</div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default CheckEmail;
