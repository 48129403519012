import CloseIcon from "assets/Icons/CloseIcon";
import CovidCancellationSection from "domains/Home/CovidCancellationSection";
import { customStyles } from "domains/Modal/config";
import ButtonWithLoader from "domains/UtilComponents/ButtonWithLoader";
import React from "react";
import Modal from "react-modal";

interface Props {
  closeModal: () => void;
  open: boolean;
}

const CovidCancellationModal: React.FC<Props> = ({ open, closeModal }) => {
  return (
    <Modal isOpen={open} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
      <div className="w-84 sm:w-140 p-2">
        <div className="w-full flex flex-col justify-center relative">
          <button
            style={{ zIndex: 10001 }}
            className="absolute top-0 right-0 w-10 h-10 flex justify-center items-center cursor-pointer"
            onClick={closeModal}
          >
            <CloseIcon />
          </button>
          <div className="text-center overflow-auto p-2" style={{ maxHeight: "70vh" }}>
            <CovidCancellationSection />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CovidCancellationModal;
