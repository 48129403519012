import React from "react";
import classNames from "classnames";

interface Props {
  disabled?: boolean;
}

const Minus = ({ disabled = false }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00009 0.615356C3.93856 0.615356 0.615479 3.93843 0.615479 7.99997C0.615479 12.0615 3.93856 15.3846 8.00009 15.3846C12.0616 15.3846 15.3847 12.0615 15.3847 7.99997C15.3847 3.93843 12.0616 0.615356 8.00009 0.615356ZM11.6616 8.52305C11.6309 8.73843 11.4462 8.92305 11.2001 8.92305H4.80009C4.55394 8.92305 4.36932 8.7692 4.33856 8.52305C4.30779 8.15382 4.30779 7.81536 4.33856 7.47689C4.36932 7.26151 4.55394 7.07689 4.80009 7.07689H11.2001C11.4462 7.07689 11.6309 7.26151 11.6616 7.47689C11.6924 7.84613 11.6924 8.18459 11.6616 8.52305Z"
        className={classNames("fill-current", { "text-cloudDark": disabled, "text-jumbo": !disabled })}
      />
    </svg>
  );
};

export default Minus;
