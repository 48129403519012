import React from "react";
import PersonalDetailsPage from "../Registration/PersonalDetails/PersonalDetailsPage";
import { RouteComponentProps } from "@reach/router";
import Loading from "../Registration/CollectionSites/Loading";
import ShiftNavigator from "../Viewer/ShiftNavigator";
import { useAppSelector } from "../../hooks";
import { getSignedInUser } from "../Person/personSelector";
import { startCase, toLower } from "lodash";
import PersonalDetailsOverview from "domains/Registration/PersonalDetails/PersonalDetailsOverview";

const MyDetails: React.FC<RouteComponentProps> = ({ path }) => {
  const user = useAppSelector(getSignedInUser);

  if (!user) {
    return (
      <div className="w-full  pb-24 text-slate justify-center">
        <div className="h-100 flex items-center justify-center">
          <Loading text="Loading your data..." />
        </div>
      </div>
    );
  }

  return (
    <ShiftNavigator title={`Hi ${startCase(toLower(user?.firstName))}`}>
      <div className="w-full  pb-24 text-slate">
        <div className="w-full pb-20">
          <div className="flex justify-center my-10 m-2">
            <PersonalDetailsOverview user={user} />
          </div>
        </div>
        <div className="m-2">
          <p>
            Need to update your details? Please contact the Pink Ribbon Street Appeal team on 0508 105 105 or email
            pinkribbon@bcf.org.nz - we'd be happy to help!
          </p>
        </div>
      </div>
    </ShiftNavigator>
  );
};

export default MyDetails;
