import React from "react";
import { Nullable } from "../../../types";
import { useGetShiftsByLocation } from "../../Shifts/shiftHooks";
import { formatTimeAs12Hour, getTempCount, sumCounts } from "./utils";
import { useSelector } from "react-redux";
import { getSelectedLocation } from "../../Locations/locationSelector";
import { createTimeSlotEndTimeMap } from "../../SignedIn/AreaCoordinator/AreaCoordinatorHelpers";
import VolunteerTimeSlotRow from "./VolunteerTimeSlotRow";
import { collectionDayOne, collectionDayTwo } from "config";

interface Props {
  selectedLocationId: Nullable<string>;
  stepTwoRef: React.RefObject<HTMLInputElement>;
  handleShiftSelection: () => void;
}

const columnOneDate = collectionDayOne;
const columnTwoDate = collectionDayTwo;

const StepTwoMobile = ({ selectedLocationId, stepTwoRef, handleShiftSelection }: Props) => {
  const getShiftsByLocation = useGetShiftsByLocation();
  const selectedLocationShifts = getShiftsByLocation(selectedLocationId);

  const selectedLocation = useSelector(getSelectedLocation);

  if (!selectedLocationId) {
    return null;
  }

  const { uniqueTimeSlots, timeSlotMap } = createTimeSlotEndTimeMap(selectedLocationShifts);

  return (
    <div className="text-sm display sm:hidden">
      <div className="text-flamingo text-lg mb-4 font-bold flex justify-center text-center">
        STEP TWO: Pick a date and time that suits
      </div>

      <div className="px-2">
        <div className="flex">
          <div className="font-bold pr-1 pb-3">Location:</div>
          <div>{selectedLocation?.collectionSite}</div>
        </div>
        <table className="w-full px-2">
          <tbody>
            <tr>
              <th className="w-40">Time</th>
              <th className="px-1">{`Fri 28 October 2022 Shifts`}</th>
            </tr>
            {uniqueTimeSlots.map((timeSlotWithDuration, index) => {
              return (
                <tr key={index} className="h-16">
                  <td className="w-64">
                    <div className="mr-2">{`${formatTimeAs12Hour(
                      timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
                    )} - ${formatTimeAs12Hour(timeSlotMap[timeSlotWithDuration]?.timeSlotEnd)}`}</div>
                  </td>
                  <VolunteerTimeSlotRow
                    date={columnOneDate}
                    timeSlotWithDuration={timeSlotWithDuration}
                    selectedLocationShifts={selectedLocationShifts}
                    timeSlotMap={timeSlotMap}
                    handleShiftSelection={handleShiftSelection}
                  />
                </tr>
              );
            })}
            <tr className="h-10" />
            <tr>
              <th className="w-40">Time</th>
              <th className="px-1">{`Sat 29 October 2022 Shifts`}</th>
            </tr>
            {uniqueTimeSlots.map((timeSlotWithDuration, index) => {
              return (
                <tr key={index} className="h-16">
                  <td className="w-64">
                    <div className="mr-2">{`${formatTimeAs12Hour(
                      timeSlotMap[timeSlotWithDuration]?.timeSlotStart,
                    )} - ${formatTimeAs12Hour(timeSlotMap[timeSlotWithDuration]?.timeSlotEnd)}`}</div>
                  </td>
                  <VolunteerTimeSlotRow
                    date={columnTwoDate}
                    timeSlotWithDuration={timeSlotWithDuration}
                    selectedLocationShifts={selectedLocationShifts}
                    timeSlotMap={timeSlotMap}
                    handleShiftSelection={handleShiftSelection}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StepTwoMobile;
