import CloseIcon from "assets/Icons/CloseIcon";
import { customStyles } from "domains/Modal/config";

import { useShiftHasFriendAdded } from "domains/Shifts/shiftHooks";
import ButtonWithLoader from "domains/UtilComponents/ButtonWithLoader";
import React from "react";
import Modal from "react-modal";

interface Props {
  closeModal: () => void;
  open: boolean;
  handleConfirm: () => void;
  loading: boolean;
  text: string;
  additionalText?: string;
}

const ModifyShiftModal: React.FC<Props> = ({ closeModal, open, handleConfirm, loading, text, additionalText }) => {
  return (
    <Modal isOpen={open} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
      <div className="w-84 sm:w-90 p-2">
        <div className="w-full flex justify-center relative">
          <h2 className="text-flamingo my-2 w-64 text-center">{text}</h2>
          <button
            className="absolute top-0 right-0 w-10 h-10 flex justify-center items-center cursor-pointer group"
            onClick={closeModal}
          >
            <CloseIcon />
          </button>
        </div>
        {additionalText && <div className="my-4 text-center">{additionalText}</div>}
        <div className="mt-6 flex justify-center w-full">
          <div className="w-24 mx-2">
            <ButtonWithLoader
              classes="h-10 w-24"
              loading={loading}
              type="button"
              text="YES"
              highlightBg={true}
              invert={true}
              onClick={handleConfirm}
            />
          </div>
          <div className="w-24 mx-2">
            <ButtonWithLoader classes="h-10 w-24" loading={false} type="button" text="NO" onClick={closeModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyShiftModal;
