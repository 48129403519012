import React from "react";
import { useSelector } from "react-redux";
import { HTMLMotionProps } from "framer-motion";
import { Modal } from "./modalTypes";
import { useHideModal } from "./modalHooks";
import { RegistrationFlowModal } from "domains/Registration/Common/RegistrationFlowModal";
import ModalComponent from "./Modal";
import { getActiveModal } from "./modalSelectors";
import { useDocumentEvent } from "domains/Shared/events";
import { CancelOnlyModal } from "domains/Registration/Common/CancelOnlyModal";

type ModalStyle = {
  style?: React.CSSProperties;
  modalMotionProps?: HTMLMotionProps<"div">;
  overlayMotionProps?: HTMLMotionProps<"div">;
};

const getModalContent = (modal: Modal) => {
  const type = modal?.type;
  switch (type) {
    case "register-details-flow-warning":
      return <RegistrationFlowModal text={"Please select shifts first."} />;
    case "register-shifts-flow-warning":
      return <RegistrationFlowModal text={"Please select shifts and add your details first."} />;
    case "cancel-only":
      return (
        <CancelOnlyModal
          text={`Unfortunately, this site has been cancelled for ${new Date().getFullYear()}. Let us know if we can help with anything!`}
        />
      );
    default:
      return null;
  }
};

const ModalContainer: React.FC = () => {
  const modal = useSelector(getActiveModal);
  const modalStyle = undefined;

  const hideModal = useHideModal();

  useDocumentEvent("keydown", (e) => {
    if (e.key === "Escape") {
      if (!!modal) {
        e.stopImmediatePropagation();
        hideModal();
      }
    }
  });

  return (
    <ModalComponent
      {...modalStyle}
      show={!!modal}
      onHide={() => {
        if (modal) {
          hideModal();
        }
      }}
    >
      {modal && getModalContent(modal)}
    </ModalComponent>
  );
};

export default ModalContainer;
