import { collectionDayOne, collectionDayTwo } from "config";
import { uniq } from "lodash";
import { Shift } from "../../Shifts/types";

export const createTimeSlotEndTimeMap = (selectedLocationShifts: Shift[]) => {
  const timeslots = selectedLocationShifts.map((selectedLocationShift) => {
    return `${selectedLocationShift.timeSlotStart}${selectedLocationShift.duration}`;
  });

  const uniqueTimeSlots = uniq(timeslots).sort();

  const timeSlotMap: {
    [timeSlotStartWithDuration: string]: Shift;
  } = uniqueTimeSlots.reduce((accum, timeSlotStartTimeWithDuration) => {
    const timeSlot = selectedLocationShifts.find(
      (shift) => `${shift.timeSlotStart}${shift.duration}` === timeSlotStartTimeWithDuration,
    );

    if (!timeSlot) {
      return accum;
    }
    return {
      ...accum,
      [`${timeSlot.timeSlotStart}${timeSlot.duration}`]: timeSlot,
    };
  }, {});
  return {
    uniqueTimeSlots,
    timeSlotMap,
  };
};

export const columnOneDate = collectionDayOne;
export const columnTwoDate = collectionDayTwo;

export const dateToDay: { [date: string]: "Friday" | "Saturday" } = {
  [collectionDayOne]: "Friday",
  [collectionDayTwo]: "Saturday",
};

export const getShift = (searchDate: string, searchTime: string, allocatedShifts: Shift[], searchDuration?: number) => {
  const todaysShifts = allocatedShifts.filter((shift) => shift.timeSlotDate === searchDate);

  return todaysShifts.filter((shift) => shift.timeSlotStart === searchTime && shift.duration === searchDuration)[0];
};
