import classNames from "classnames";
import ArrowIcon from "../../Registration/CollectionSites/StepOne/ArrowIcon";
import React from "react";

interface Props {
  text: string;
  level: number;
  setExpanded: (expanded: boolean) => void;
  expanded: boolean;
}

const ACDropDown = ({ text, level, setExpanded, expanded }: Props) => (
  <button
    className={classNames("py-2 px-2 w-full hover:bg-stone hover:text-coal", {
      "bg-stoneHalf": false,
      "bg-cloud sm:bg-white": false,
    })}
    onClick={() => setExpanded(!expanded)}
  >
    <div style={{ paddingLeft: level * 16 }} className="flex items-center">
      <div className="flex">
        <div>{text}</div>
      </div>
      <div className="pl-2">
        <ArrowIcon direction={expanded ? "up" : "down"} />
      </div>
    </div>
  </button>
);

export default ACDropDown;
