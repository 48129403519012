import React from "react";
import { FormikValues } from "formik";
import classNames from "classnames";

interface Props {
  title: string;
  values: FormikValues;
  errors: any;
  name: string;
  disabled: boolean;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
  theme?: "primary" | "secondary";
  tabIndex?: number;
}

const VolunteerModalInput = ({
  values,
  handleChange,
  title,
  errors,
  name,
  disabled,
  theme = "primary",
  tabIndex,
}: Props) => {
  return (
    <div className="w-full my-3">
      <div
        className={classNames("text-xs", { "text-white": theme === "secondary", "text-slate": theme === "primary" })}
      >
        {title}
      </div>
      <div className="w-full relative">
        <input
          tabIndex={tabIndex}
          disabled={disabled}
          name={name}
          value={values[name]}
          onChange={handleChange}
          className="h-10 sm:h-8 w-full border my-1 pl-1 text-sm h-8 bg-white hover:bg-snow border-stone text-slate focus:outline-none border-1 outline-none align-middle font-thin rounded"
        />
        <div
          className={classNames("text-xs  w-full sm:w-64 absolute bottom-0 -mb-3", {
            "text-warning": theme === "primary",
            "text-white": theme === "secondary",
          })}
        >
          {errors[name]}
        </div>
      </div>
    </div>
  );
};

export default VolunteerModalInput;
